import { baseForPdf } from '../apiClients';
import { fetchAndCacheWithoutPage } from '../../components/shared/CacheStroage/CacheWithoutPage';
import { Endpoints } from '../../network';

export const getBookLandingPageData = async (slug: string) => {
  const apiUrl = `${Endpoints.getLandingPageData}/${slug}`;

  const apiCall = async () => {
    const response = await baseForPdf.get(apiUrl);
    return response?.data;
  };

  return fetchAndCacheWithoutPage(apiCall, apiUrl);
};

export const getBookLandingPageDataSSR = async (slug: string) => {
  const urlWithQuery = `${Endpoints.getLandingPageData}/${slug}`;
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_READER_URL}${urlWithQuery}`,
      { cache: 'force-cache' },
    );

    if (!response.ok) {
      throw new Error(
        `Error fetching book landing page details: ${response.statusText} `,
      );
    }

    const data = await response.json();
    return data?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};
