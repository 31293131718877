import moment from 'moment';
import React from 'react';

import PaginatedBookSlider from '../../BookComp/PaginatedBookSlider';
import PaginatedCoursesSlider from '../../Courses/PaginatedCoursesSlider';
import { ShouldRender } from '../../shared/basic/ShouldRender';

interface Props {
  category:
    | {
        name: string;
        _id: string;
      }
    | undefined;
  token: string;
}

const RecommendedBooksSection: React.FC<Props> = ({ category, token }) => {
  return (
    <div className='flex flex-col gap-y-8'>
      <ShouldRender check={category}>
        <PaginatedBookSlider
          categoryId={category?._id as string}
          categoryName='Recommended Books for You'
          order='asc'
          includeCategories={category?._id}
          notBookLandingPage={false}
        />
        <PaginatedCoursesSlider
          token={token}
          categoryId={category?._id.toString() as string}
          categoryName={category?.name as string}
          name='Recommended Courses for You'
          notBookLandingPage={false}
        />
      </ShouldRender>

      <PaginatedBookSlider
        categoryId={null}
        categoryName='ZebraLearn Bestseller'
        isHomePage={true}
        order='asc'
        categoryIdFilter={
          process.env.NEXT_PUBLIC_APP_ENV === 'staging'
            ? '66d95bde2da439ae0a02b8b0'
            : '66c6db9e104ce84c7e2a8956'
        }
        notBookLandingPage={false}
      />
      <PaginatedBookSlider
        categoryId={null}
        categoryName='Recently Launched Books'
        isHomePage={true}
        order='asc'
        categoryIdFilter={
          process.env.NEXT_PUBLIC_APP_ENV === 'staging'
            ? '66d95bde2da439ae0a02b8b0'
            : '66c6db9e104ce84c7e2a8956'
        }
        notBookLandingPage={false}
        endDateFilter={moment().subtract(6, 'months').format('YYYY-MM-DD')}
      />

      <PaginatedBookSlider
        categoryId={null}
        categoryName='Recently Viewed Books'
        isHomePage={true}
        order='asc'
        isRecentlyViewed={true}
        notBookLandingPage={false}
      />
    </div>
  );
};

export default RecommendedBooksSection;
