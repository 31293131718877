import React from 'react';

interface Props {
  width?: string;
}

const InfoCircleIcon: React.FC<Props> = ({ width }) => (
  <svg
    width={width ?? '24'}
    height={width ?? '24'}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='transition-all'
  >
    <rect
      x='2'
      y='2'
      width='20'
      height='20'
      rx='10'
      stroke='currentColor'
      strokeWidth='1.5'
    />
    <path
      d='M12.5 17L12.5 11'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M10.5 11L12.5 11'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M12.5 8L12.5 7'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);

export default InfoCircleIcon;
