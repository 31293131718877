import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Layout, Spin } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { AppwriteException } from 'appwrite';
import clsx from 'clsx';
import { motion, useAnimation } from 'framer-motion';
import moment, { Moment } from 'moment';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UAParser } from 'ua-parser-js';

import AiChatDrawer from '../../PdfReader/AiChatDrawer';
import FailedToLoadPDF from '../../PdfReader/FailedToLoadPDF';
import PdfReaderHeader from '../../PdfReader/PdfReaderHeader';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { LoadingV2 } from '../../shared/LoadingV2';
import { FailedToLoadPdf, NetworkError } from '../../../../assets';
import Star from '../../../../assets/Icons/Star';
import { iframeEmail } from '../../../atoms/iframeEmail';
import { user } from '../../../atoms/user';
import appwriteService from '../../../config/appwrite';
import { EventDetails, logEvent } from '../../../hooks/useClickEventTracking';
import { Endpoints } from '../../../network';
import {
  getBookByIdApiIFrame,
  tempUserIFrame,
} from '../../../services/IFrame/apis';
import {
  getAllChaptersApi,
  getBookByIdApi,
} from '../../../services/Library/Books';
import { MyBookByIDInterface } from '../../../utils/interfaces/Library/Books';
import { AllChaptersListInterface } from '../../../utils/interfaces/Library/Pdf';
import { User } from '../../../utils/interfaces/User';

interface RotationsState {
  [key: number]: number;
}

const { Content } = Layout;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  isIFrame?: boolean;
  noAuth?: boolean;
  theme?: string;
  isModal?: boolean;
  bookSlugId?: string;
  handleCancel?: () => void;
}

const PDFReaderIframe: React.FC<Props> = ({
  isIFrame = false,
  noAuth = false,
  theme,
  isModal = false,
  bookSlugId,
  handleCancel,
}) => {
  const router = useRouter();
  const [pageDimensions, setPageDimensions] = useState({ width: 0, height: 0 });
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const [rotations, setRotations] = useState<RotationsState>({});
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [sideBySide, setSideBySide] = useState(true);
  const [isHovered, setIsHovered] = useState(true);
  const [zoomValue, setZoomValue] = useState(100);
  const [samplePdf, setSamplePdf] = useState<string>();
  const [, setVisibleControl] = useRecoilState(iframeEmail);
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0,
  );

  const [hasError, setHasError] = useState(false);
  const [timeStamp, setTimeStamps] = useState({
    startTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    endTime: '',
  });
  const [visible, setVisible] = useState(true);
  const [windowHeight, setWindowHeight] = useState(
    typeof window !== 'undefined' ? window.innerHeight : 0,
  );
  const controls = useAnimation();

  const [chapterFieldId, setChapterFieldId] = useState('');
  const [startPage, setStartPage] = useState<number>(0);

  const [chaptersList, setChaptersList] = useState<AllChaptersListInterface>();
  const [booksDetails, setBooksDetails] = useState<MyBookByIDInterface>();
  const [isInteracted, setIsInteracted] = useState(false);
  const [renderPdf, setRenderPdf] = useState('');
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const userData: User | undefined = useRecoilValue(user);
  const containerRef = useRef<HTMLDivElement>(null);
  const baseUrl = process.env.NEXT_PUBLIC_API_READER_URL;
  const screens = useBreakpoint();
  const [errorType, setErrorType] = useState<
    'server-timeout' | 'default' | null
  >(null);
  const samplePdfValue = 'true';
  const leftButtonRef = useRef<HTMLButtonElement>(null);
  const rightButtonRef = useRef<HTMLButtonElement>(null);

  const {
    chapterPageNumber,
    chapterId,
    book_id,
    email,
    code: afc,
  } = router.query;

  const chapters = chaptersList?.chapters || [];
  const totalNumberOfPages = chapters.reduce(
    (total, chapter) => total + chapter.totalPages,
    0,
  );

  const extractId = (path: string | undefined): string | undefined => {
    if (path) {
      const parts = path.split('-');
      return parts[parts.length - 1];
    } else return undefined;
  };
  const onDocumentLoadSuccess = async ({ numPages }: { numPages: number }) => {
    try {
      if (!samplePdf && !renderPdf) {
        throw new Error('PDF file is null or undefined');
      }

      setNumPages(numPages);

      const pdf = await pdfjs.getDocument(samplePdf ?? renderPdf).promise;

      if (!pdf) {
        throw new Error('Failed to load PDF document');
      }

      const page = await pdf.getPage(1);
      const { width, height } = page.getViewport({ scale: 1 });
      setPageDimensions({ width, height });
    } catch (error) {
      console.error('Failed to load PDF:', error);
    }
  };

  const showDrawer = () => {
    setVisible(!visible);
  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const rotateLeft = (pageNumber: number) => {
    setRotations((prevRotations) => ({
      ...prevRotations,
      [pageNumber]: (prevRotations[pageNumber] || 0) - 90,
    }));
  };

  const rotateRight = (pageNumber: number) => {
    setRotations((prevRotations) => ({
      ...prevRotations,
      [pageNumber]: (prevRotations[pageNumber] || 0) + 90,
    }));
  };

  const getNextPage = async () => {
    if (pageNumber + 1 <= (numPages || 1)) {
      const totalPages = chaptersList?.chapters?.find(
        (item: any) => item._id === chapterFieldId,
      )?.totalPages;

      if (sideBySide) {
        if ((totalPages || 0) < pageNumber + 1 + 2 && !samplePdf) {
          return totalPages;
        } else {
          return pageNumber + 2;
        }
      } else {
        return pageNumber + 1;
      }
    }

    const totalPages = chaptersList?.chapters?.find(
      (item: any) => item._id === chapterFieldId,
    )?.totalPages;

    if (totalPages === pageNumber + 1 || totalPages === pageNumber) {
      const currentIndex = chaptersList?.chapters.findIndex(
        (item: any) => item._id === chapterFieldId,
      );

      if (
        currentIndex !== undefined &&
        currentIndex !== -1 &&
        currentIndex + 1 < (chaptersList?.chapters?.length ?? 0)
      ) {
        const nextChapter = chaptersList?.chapters?.[currentIndex + 1];

        if (nextChapter) {
          setRenderPdf(
            `${baseUrl}/${Endpoints.getChapterFile}/${nextChapter._id}`,
          );
          setScale(1.0);
          setZoomValue(100);

          setChapterFieldId(nextChapter?._id as string);
          setStartPage(nextChapter?.startPage);
          return 1;
        } else {
          console.log('No next chapter available.');
        }
      } else {
        console.log('No next chapter available.');
      }
    }

    return pageNumber;
  };

  const getPreviousPage = async () => {
    if (pageNumber > 1) {
      return sideBySide ? pageNumber - 2 : pageNumber - 1;
    }

    const currentIndex = chaptersList?.chapters.findIndex(
      (item: any) => item._id === chapterFieldId,
    );

    if (1 === pageNumber && currentIndex !== undefined && currentIndex !== -1) {
      if (currentIndex - 1 >= 0) {
        const prevChapter = chaptersList?.chapters?.[currentIndex - 1];

        if (prevChapter) {
          setRenderPdf(
            `${baseUrl}/${Endpoints.getChapterFile}/${prevChapter._id}`,
          );

          setScale(1.0);
          setZoomValue(100);

          setChapterFieldId(prevChapter?._id as string);
          setStartPage(prevChapter?.startPage);

          setPageNumber(prevChapter?.totalPages - 1);
          return prevChapter?.totalPages;
        } else {
          console.log('No previous chapter available.');
        }
      } else {
        console.log('No previous chapter available.');
      }
    } else {
      console.log('No previous chapter available.');
    }

    return pageNumber;
  };

  const handlePageTransition = async (newPageNumber: number) => {
    if (newPageNumber >= 1 && newPageNumber <= (numPages || 1)) {
      setPageNumber(newPageNumber);
    }
  };

  const zoomIn = () => {
    if (scale < 5.0) {
      const newScale = scale + 0.1;
      handleZoomChange(newScale);
    }
  };

  const zoomOut = () => {
    const newScale = scale - 0.1;
    handleZoomChange(newScale);
  };

  const handleGetDefaultBookById = async (chapterIdUrl: string) => {
    try {
      setRenderPdf(
        `${baseUrl}/${Endpoints.getChapterFile}/${chapterIdUrl as string}`,
      );

      setScale(1.0);
      setZoomValue(100);
      setHasError(false);
      setErrorType(null);
    } catch (error: any) {
      setHasError(true);

      if (
        error.code === 'ECONNABORTED' ||
        error.code === 'ECONNRESET' ||
        error.code === 'ERR_NETWORK' ||
        error.message?.toLowerCase().includes('timeout') ||
        error.message?.toLowerCase().includes('network') ||
        error.message?.toLowerCase().includes('fetch')
      ) {
        setErrorType('server-timeout');
      } else {
        setErrorType('default');
      }
    }
  };

  const toggleFullScreen = () => {
    const layout = document.querySelector('.ant-layout') as any;

    if (layout) {
      if (!isFullScreen) {
        const fullscreenFunction =
          layout.requestFullscreen ||
          layout.webkitRequestFullscreen ||
          layout.msRequestFullscreen ||
          layout.mozRequestFullScreen;

        if (fullscreenFunction) {
          fullscreenFunction.call(layout);
        }
      } else {
        const docs = document as any;
        const exitFullscreenFunction =
          docs.exitFullscreen ||
          docs.webkitExitFullscreen ||
          docs.msExitFullscreen ||
          docs.mozCancelFullScreen;

        if (exitFullscreenFunction) {
          exitFullscreenFunction.call(docs);
        }
      }
    }

    setIsFullScreen(!isFullScreen);
  };

  const fullScreenIcon = (
    <div className='group'>
      <button
        onClick={toggleFullScreen}
        className={clsx(
          isFullScreen ? ' text-primary' : 'text-white ',
          'flex items-center  justify-center relative cursor-pointer group-hover:bg-gray-200 group-hover:bg-opacity-15  p-2 w-8 h-8 md:w-10 md:h-10 rounded-full',
        )}
      >
        <div className='w-4 h-4 md:w-5 md:h-5 '>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            className='fill-current'
          >
            <path d='M23 4C23 2.34315 21.6569 1 20 1H16C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3H20C20.5523 3 21 3.44772 21 4V8C21 8.55228 21.4477 9 22 9C22.5523 9 23 8.55228 23 8V4Z' />
            <path d='M23 16C23 15.4477 22.5523 15 22 15C21.4477 15 21 15.4477 21 16V20C21 20.5523 20.5523 21 20 21H16C15.4477 21 15 21.4477 15 22C15 22.5523 15.4477 23 16 23H20C21.6569 23 23 21.6569 23 20V16Z' />
            <path d='M4 21H8C8.55228 21 9 21.4477 9 22C9 22.5523 8.55228 23 8 23H4C2.34315 23 1 21.6569 1 20V16C1 15.4477 1.44772 15 2 15C2.55228 15 3 15.4477 3 16V20C3 20.5523 3.44772 21 4 21Z' />
            <path d='M1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8L3 4C3 3.44772 3.44772 3 4 3H8C8.55228 3 9 2.55228 9 2C9 1.44772 8.55228 1 8 1H4C2.34315 1 1 2.34315 1 4V8Z' />
          </svg>
        </div>
      </button>
    </div>
  );

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      try {
        if (event.key === 'ArrowLeft' && !openDrawer) {
          const previousPage = await getPreviousPage();
          handlePageTransition(previousPage);
          if (leftButtonRef.current) {
            leftButtonRef.current.click();
          }
        } else if (event.key === 'ArrowRight' && !openDrawer) {
          if (!(pageNumber >= 10)) {
            const nextPage = await getNextPage();
            handlePageTransition(nextPage as number);

            // Optional: focus on the right button when arrow right is pressed
            if (rightButtonRef.current) {
              rightButtonRef.current.click();
            }
          }
        }
      } catch (error) {
        console.error('Error while handling keyboard input:', error);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(
        !!document.fullscreenElement ||
          !!(document as any).webkitFullscreenElement ||
          !!(document as any).msFullscreenElement ||
          !!(document as any).mozFullScreenElement,
      );
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('msfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullScreenChange,
      );
      document.removeEventListener(
        'msfullscreenchange',
        handleFullScreenChange,
      );
      document.removeEventListener(
        'mozfullscreenchange',
        handleFullScreenChange,
      );
    };
  }, []);

  const toggleSideBySide = () => {
    setSideBySide(!sideBySide);
  };
  const { bookId } = router.query;

  const parsedBookId = bookId ? (bookId as string).split('-').pop() || '' : '';

  const getAllChaptersListData = async (book_id: string) => {
    try {
      const response = await getAllChaptersApi(book_id);
      setChaptersList(response);

      if (!(chapterPageNumber && chapterId)) {
        setRenderPdf(
          `${baseUrl}/${Endpoints.getChapterFile}/${response?.chapters[0]?._id}`,
        );
        setScale(1.0);
        setZoomValue(100);

        setChapterFieldId(response?.chapters[0]?._id as string);
        setStartPage(response?.chapters[0]?.startPage);
      } else {
        setStartPage(
          response?.chapters.find((chapter: any) => chapter._id === chapterId)
            ?.startPage as number,
        );
        if (chapterPageNumber && response?.chapters) {
          setPageNumber(
            Number(chapterPageNumber) -
              (response?.chapters?.find(
                (chapter: any) => chapter._id === chapterId,
              )?.startPage || 0) +
              1,
          );
        }
      }
    } catch (error) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  const getBookDetailsById = async (bookId: string) => {
    try {
      if (isIFrame) {
        const response = await getBookByIdApiIFrame(bookId);
        setBooksDetails(response);
      } else {
        const response = await getBookByIdApi(bookId);
        setBooksDetails(response);
      }
    } catch (error) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  const handleGetSampleBookById = async (bookId: string) => {
    try {
      setSamplePdf(`${baseUrl}/${Endpoints.getSampleBook}/${bookId}`);
    } catch (error: any) {
      setHasError(true);
      if (
        error.code === 'ECONNABORTED' ||
        error.code === 'ECONNRESET' ||
        error.code === 'ERR_NETWORK' ||
        error.message?.toLowerCase().includes('timeout') ||
        error.message?.toLowerCase().includes('network') ||
        error.message?.toLowerCase().includes('fetch')
      ) {
        setErrorType('server-timeout');
      } else {
        setErrorType('default');
      }
    }
  };

  const handleGetDefaultBookByChapterId = async () => {
    try {
      if (chapterId) {
        handleGetDefaultBookById(chapterId as string);
        setChapterFieldId(chapterId as string);
      }
    } catch (error) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  useEffect(() => {
    if (chapterPageNumber && chapterId) {
      getAllChaptersListData((book_id ?? parsedBookId) as string);
      handleGetDefaultBookByChapterId();

      getBookDetailsById((book_id ?? parsedBookId) as string);
    } else if (samplePdfValue && ((book_id ?? parsedBookId) as string)) {
      handleGetSampleBookById((book_id ?? parsedBookId) as string);
      getBookDetailsById(extractId(bookSlugId) as string);
    } else if (samplePdfValue && extractId(bookSlugId)) {
      handleGetSampleBookById(extractId(bookSlugId) as string);
      getBookDetailsById(extractId(bookSlugId) as string);
    } else if ((book_id ?? parsedBookId) as string) {
      getAllChaptersListData((book_id ?? parsedBookId) as string);
      getBookDetailsById((book_id ?? parsedBookId) as string);
    }
  }, [
    book_id,
    parsedBookId,
    samplePdfValue,
    chapterPageNumber,
    chapterId,
    bookSlugId,
  ]);

  const handleReadingLogs = async () => {
    try {
      const duration = moment().diff(timeStamp.startTime, 'milliseconds');

      const baseEventParams = {
        event_name: 'read',
        source: 'books',
        source_type: router.query['cs']
          ? 'comingSoon'
          : router.query['swp']
            ? 'short'
            : 'long',
        source_id: extractId(bookSlugId) as string,
        sub_source: samplePdf ? 'sample' : 'chapter',
        sub_source_id: samplePdf ? null : chapterFieldId,
        source_name: booksDetails?.title,
        meta: {
          book_page_no: samplePdf ? pageNumber : startPage + pageNumber - 1,
          book_total_pages: samplePdf ? numPages : totalNumberOfPages,
          ...(!samplePdf && { chapter_page_no: pageNumber }),
        },
      };

      const logReadEvent = (
        start: Moment,
        end: Moment,
        meta: Record<string, any>,
      ) => {
        logEvent({
          eventDetails: {
            ...baseEventParams,
            activity_start: start.format('YYYY-MM-DDTHH:mm:ss'),
            activity_end: end.format('YYYY-MM-DDTHH:mm:ss'),
            meta,
          },
          userData,
          pathname: router.pathname,
        });
      };

      if (sideBySide) {
        const halfDuration = duration / 2;
        const firstEventEndTime = moment(timeStamp.startTime)
          .clone()
          .add(halfDuration, 'milliseconds');
        const firstMeta = {
          book_page_no: samplePdf ? pageNumber - 2 : startPage + pageNumber - 3,
          ...(!samplePdf && { chapter_page_no: pageNumber - 2 }),
        };

        const secondMeta = {
          book_page_no: samplePdf ? pageNumber - 1 : startPage + pageNumber - 2,
          ...(!samplePdf && { chapter_page_no: pageNumber - 1 }),
        };
        logReadEvent(moment(timeStamp.startTime), firstEventEndTime, firstMeta);
        logReadEvent(firstEventEndTime, moment(), secondMeta);
      } else {
        const meta = {
          book_page_no: samplePdf ? pageNumber - 1 : startPage + pageNumber - 2,
          ...(!samplePdf && { chapter_page_no: pageNumber - 1 }),
        };
        logReadEvent(moment(timeStamp.startTime), moment(), meta);
      }

      setTimeStamps({
        startTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
        endTime: '',
      });
    } catch (error) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  useEffect(() => {
    if (chapterFieldId || samplePdf) {
      handleReadingLogs();
    }
    if (
      (samplePdf ? pageNumber : startPage + pageNumber - 1) ===
        (samplePdf ? numPages : totalNumberOfPages) &&
      (samplePdf ? pageNumber : startPage + pageNumber - 1) > 0
    ) {
      const eventParams: EventDetails = {
        event_name: 'complete',
        source: 'books',
        source_type: router.query['cs']
          ? 'comingSoon'
          : router.query['swp']
            ? 'short'
            : samplePdf
              ? 'short'
              : 'long',
        source_id: extractId(bookSlugId) as string,
        sub_source: samplePdf ? 'sample' : null,
        sub_source_id: samplePdf ? null : chapterFieldId,
        unit: 'boolean',
        source_name: booksDetails?.title,
        value: 1,
        meta: {
          book_page_no: samplePdf ? pageNumber : startPage + pageNumber - 1,
          book_total_pages: samplePdf ? numPages : totalNumberOfPages,
          ...(!samplePdf && { chapter_page_no: pageNumber }),
        },
      };

      logEvent({
        eventDetails: eventParams,
        userData: userData,
        pathname: router.pathname,
      });
    }
  }, [pageNumber]);

  const handleZoomChange = (newScale: any) => {
    setScale(newScale);
    setZoomValue(Math.round(newScale * 100));
  };

  const handleZoom = (event: any) => {
    handleZoomChange(event?.state?.scale);
  };

  useEffect(() => {
    if (!screens.lg) {
      setSideBySide(false);
    } else {
      setSideBySide(true);
    }
  }, [screens]);

  useEffect(() => {
    if (numPages && pageNumber + 1 > (numPages as number) && sideBySide) {
      setSideBySide(false);
    }
  }, [
    pageNumber,
    startPage,
    totalNumberOfPages,
    sideBySide,
    numPages,
    screens,
    samplePdfValue,
    pageDimensions,
  ]);

  useEffect(() => {
    const handleTouchStart = () => {
      setIsInteracted(true);
    };

    const handleTouchMove = () => {
      setIsInteracted(true);
    };

    const handleTouchEnd = () => {
      setIsInteracted(true);
    };

    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsInteracted(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [isHovered, isInteracted]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setWindowHeight(container.clientHeight);
      setWindowWidth(container.clientWidth);
    }
  }, []);

  const isPortraitMode = useMemo(() => {
    return pageDimensions.width > pageDimensions.height;
  }, [pageDimensions]);

  const handleEmailLoginTempIFrame = async (emailValue: string) => {
    try {
      await appwriteService.logoutIfSessionExists();
      const session = await appwriteService.createAnonymousSession();
      if (session && typeof window !== undefined) {
        await tempUserIFrame({
          code: afc as string,
          email: emailValue as string,
          product_id: book_id as string,
          source: window.location.href,
          user_id: session?.$id,
        });
      }
      if (session?.$id && typeof localStorage !== 'undefined') {
        localStorage.setItem('user_id_iframe', session?.$id);
      }
    } catch (error: any) {
      if (error instanceof AppwriteException) {
        if (process.env.NODE_ENV === 'development') {
          console.error('Error initializing messaging:', error);
        }
      }
    }
  };

  useEffect(() => {
    if (email && !noAuth) {
      if (typeof localStorage !== 'undefined') {
        const user_id_iframe = localStorage.getItem('user_id_iframe');
        if (!user_id_iframe) {
          handleEmailLoginTempIFrame(email as string);
        }
      }
    }
  }, [email, router]);

  const openModal = async () => {
    let session = undefined;
    if (typeof localStorage !== 'undefined') {
      session = localStorage.getItem('user_id_iframe');
    }

    if (!email && pageNumber >= 5 && !noAuth && !session) {
      setVisibleControl(true);
    }
  };

  useEffect(() => {
    openModal();
  }, [email, router, pageNumber]);

  const onOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
    if (screens.lg) {
      if (!openDrawer) {
        setSideBySide(false);
      } else {
        setSideBySide(true);
      }
    }
  };

  const isIphone = (): boolean => {
    const parser = new UAParser();
    const device = parser.getDevice();
    return device.model === 'iPhone';
  };

  return (
    <Layout
      className={clsx(
        '  h-max bg-transparent',
        isHovered
          ? isFullScreen
            ? ' min-h-[100vh]'
            : ' min-h-[85vh]'
          : isFullScreen
            ? ' min-h-[100vh]'
            : 'min-h-[90vh]',
      )}
      style={{
        backgroundColor: 'transparent',
      }}
      ref={containerRef}
    >
      <ShouldRender check={isHovered}>
        <PdfReaderHeader
          visible={visible}
          headerClass={clsx(
            !theme && !isModal
              ? 'bg-bgColor bg-opacity-75 backdrop-filter  backdrop-blur-md '
              : 'bg-bgColor ',

            `   z-40 w-full min-h-[40px] transition-all `,
          )}
          isFullScreen={isFullScreen}
          toggleFullScreen={toggleFullScreen}
          samplePdf={samplePdf}
          showDrawer={showDrawer}
          zoomValue={zoomValue}
          zoomOut={zoomOut}
          zoomIn={zoomIn}
          sideBySide={sideBySide}
          title={booksDetails?.title || ''}
          rotateLeft={rotateLeft}
          pageNumber={pageNumber}
          rotateRight={rotateRight}
          onOpenDrawer={onOpenDrawer}
          book_id={book_id as string}
          userData={userData as User}
          toggleSideBySide={toggleSideBySide}
          openDrawer={openDrawer}
          isIphone={isIphone}
          fullScreenIcon={fullScreenIcon}
          handleCancel={handleCancel}
          isModal={isModal}
          hideAudioBook={true}
        />
      </ShouldRender>

      <Content>
        <div
          className={clsx(
            'bg-opacity-75 backdrop-filter backdrop-blur-md overflow-auto  relative flex min-h-[85vh]',
            isFullScreen ? 'min-h-[101vh] items-center' : 'min-h-[85vh]',
            theme && !isModal ? 'bg-white' : '',
            isModal && 'bg-transparent backdrop-blur-sm',
          )}
          style={{
            scrollBehavior: 'smooth',
            scrollbarWidth: 'none',
          }}
        >
          <div className='w-full'>
            <div className='flex relative h-full items-center overflow-hidden'>
              <motion.div
                onContextMenu={(e) => e.preventDefault()}
                className='w-full flex justify-center items-center h-full relative '
                animate={{
                  x: screens.lg ? (openDrawer ? '-25vw' : '0') : '',
                }}
                transition={{
                  type: 'spring',
                  stiffness: 100,
                  damping: 20,
                }}
              >
                {hasError && !(samplePdf || renderPdf) ? (
                  errorType === 'server-timeout' ? (
                    <FailedToLoadPDF
                      title='Check Your Network'
                      description='Please try again later.'
                      primaryButtonText='Go Back Home'
                      primaryButtonLink='/'
                      secondaryButtonText='Refresh'
                      secondaryButtonLink='/'
                      contactText='Contact Support'
                      contactLink='/support'
                      imageSrc={NetworkError}
                    />
                  ) : (
                    <FailedToLoadPDF
                      title='Something went wrong!'
                      description='Please try again later.'
                      primaryButtonText='Retry'
                      primaryButtonLink='/retry'
                      secondaryButtonText='Home'
                      secondaryButtonLink='/'
                      contactText='Get Support'
                      contactLink='/support'
                      imageSrc={FailedToLoadPdf}
                    />
                  )
                ) : (
                  <Document
                    className={clsx(
                      'pdf-container hideText w-full h-full overflow-auto z-10',
                    )}
                    renderMode='canvas'
                    file={samplePdf ?? renderPdf}
                    rotate={rotations[pageNumber]}
                    noData={
                      <FailedToLoadPDF
                        title='Something went wrong!'
                        description='Please try again later.'
                        primaryButtonText='Retry'
                        primaryButtonLink='/retry'
                        secondaryButtonText='Home'
                        secondaryButtonLink='/'
                        contactText='Get Support'
                        contactLink='/support'
                        imageSrc={FailedToLoadPdf}
                      />
                    }
                    error={
                      <FailedToLoadPDF
                        title='Something went wrong!'
                        description='Please try again later.'
                        primaryButtonText='Retry'
                        primaryButtonLink='/retry'
                        secondaryButtonText='Home'
                        secondaryButtonLink='/'
                        contactText='Get Support'
                        contactLink='/support'
                        imageSrc={FailedToLoadPdf}
                      />
                    }
                    loading={
                      <LoadingV2
                        loaderColor='white'
                        messages={[
                          'Almost there, thanks for your patience!',
                          'Getting everything set up for you.',
                          'Your content is on its way!',
                          "Just a sec, making sure everything's perfect.",
                          "Hold on, we're almost ready!",
                          "Loading... we'll be with you shortly.",
                          "Thanks for waiting, we're preparing your journey!",
                        ]}
                      />
                    }
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(error) => {
                      console.error('Document load error:', error);
                    }}
                  >
                    <div
                      className={clsx(
                        isFullScreen && 'items-center',
                        'flex justify-center w-full md:items-center overflow-auto h-full',
                      )}
                    >
                      <TransformWrapper
                        panning={{
                          velocityDisabled: false,
                          excluded: [],
                          disabled: false,
                        }}
                        initialScale={scale}
                        wheel={{
                          disabled: true,
                        }}
                        onZoom={handleZoom}
                        doubleClick={{
                          disabled: true,
                        }}
                        centerOnInit
                        centerZoomedOut
                      >
                        <TransformComponent contentClass='overflow-auto flex items-center justify-center'>
                          {sideBySide ? (
                            <motion.div
                              className='w-full flex justify-center items-center h-full'
                              initial={{ x: 0 }}
                              animate={controls}
                            >
                              <div
                                className='flex justify-center items-center  relative'
                                onClick={() => setIsHovered(!isHovered)}
                              >
                                {[-4, -3, -2, -1, 0, 1, 2, 3, 4].map(
                                  (offset) => {
                                    const isHidden =
                                      offset !== 0 && offset !== 1;
                                    return (
                                      <motion.div
                                        key={pageNumber + offset}
                                        className={`w-full flex relative z-10 ${offset === 0 ? 'justify-end' : 'justify-start'} z-10 ${
                                          isHidden ? 'hidden' : ''
                                        } page`}
                                      >
                                        <Page
                                          pageNumber={pageNumber + offset}
                                          scale={scale}
                                          className='bg-cartBgColor'
                                          renderAnnotationLayer={false}
                                          renderTextLayer={false}
                                          height={windowHeight as number}
                                          loading={
                                            <div
                                              style={{
                                                width: Math.floor(
                                                  windowWidth *
                                                    (screens.xl ? 0.4 : 0.5),
                                                ),
                                                minHeight: windowHeight,
                                              }}
                                              className=' flex justify-center items-center h-full '
                                            >
                                              <Spin />
                                            </div>
                                          }
                                          width={Math.floor(
                                            windowWidth *
                                              (screens.xl ? 0.4 : 0.5),
                                          )}
                                        />

                                        <div
                                          style={{
                                            background:
                                              'linear-gradient(to right, transparent 0%,rgba(0,0,0,0.2) 46%,rgba(0,0,0,0.5) 49%,rgba(0,0,0,0.6) 50%,rgba(0,0,0,0.5) 51%,rgba(0,0,0,0.2) 52%,transparent 100%)',
                                          }}
                                          className={clsx(
                                            offset === 0
                                              ? ' -right-10'
                                              : ' -left-10',
                                            'absolute h-full w-20  z-40',
                                          )}
                                        ></div>
                                      </motion.div>
                                    );
                                  },
                                )}
                              </div>
                            </motion.div>
                          ) : (
                            <motion.div
                              className='overflow-auto w-full h-full'
                              initial={{ x: 0, y: 0 }}
                              animate={controls}
                              style={{
                                overflow: 'auto',
                                height: '100%',
                                width: '100%',
                              }}
                            >
                              <div
                                onClick={() => setIsHovered(!isHovered)}
                                className='flex items-center'
                              >
                                {[-2, -1, 0, 1, 2].map((offset) => {
                                  const isHidden = offset !== 0;
                                  return (
                                    <Page
                                      key={pageNumber + offset}
                                      pageNumber={pageNumber + offset}
                                      scale={scale}
                                      height={
                                        screens.lg
                                          ? windowHeight
                                          : isPortraitMode
                                            ? windowHeight
                                            : undefined
                                      }
                                      width={
                                        screens.lg
                                          ? isPortraitMode
                                            ? Math.floor(windowWidth * 0.7)
                                            : Math.floor(windowWidth * 0.35)
                                          : !isPortraitMode
                                            ? windowWidth
                                            : windowWidth
                                      }
                                      className={`bg-cartBgColor ${isHidden ? 'hidden' : ''}`}
                                    />
                                  );
                                })}
                              </div>
                            </motion.div>
                          )}
                        </TransformComponent>
                      </TransformWrapper>
                    </div>
                  </Document>
                )}
              </motion.div>
            </div>
          </div>

          <div className='flex items-center'>
            {' '}
            <AiChatDrawer
              onCancel={onCloseDrawer}
              visible={openDrawer}
              isFullScreen={isFullScreen}
              bookData={{
                name: booksDetails?.title as string,
                image: `${baseUrl}/${Endpoints.getCoverImagesForBooks}/${book_id ?? parsedBookId}`,
              }}
            />
          </div>
        </div>
        <ShouldRender
          check={
            isHovered &&
            (!openDrawer || screens.lg) &&
            (samplePdf ? numPages || 0 : numPages || 0) > 1
          }
        >
          <motion.div
            className='fixed bottom-[5%] w-auto  font-lato font-semibold text-white inline-flex items-center '
            animate={{
              x: screens.lg ? (openDrawer ? '-25vw' : '0') : '0',
            }}
            initial={{ x: '0%' }}
            style={{
              left: '0',
              right: '0',
              margin: '0 auto',
              transform: 'none',
            }}
            transition={{
              type: 'spring',
              stiffness: 100,
              damping: 20,
            }}
          >
            <div className='flex w-full justify-center'>
              <div className='flex w-max justify-center  z-20 gap-x-2 items-center  p-2 md:p-3 cursor-pointer opacity-85 bg-bgColor rounded-full'>
                <ShouldRender
                  check={
                    (samplePdf ? pageNumber : startPage + pageNumber - 1) !== 1
                  }
                >
                  <button
                    ref={leftButtonRef}
                    onClick={async () => {
                      const previousPage = await getPreviousPage();
                      handlePageTransition(previousPage);
                    }}
                    tabIndex={0}
                    className='text-lg flex justify-center items-center w-5 h-5 md:w-6 md:h-6 md:bg-[#1C1C1C] rounded-full md:border border-[#FFFFFF0D] transition-all duration-300 hover:scale-105'
                    aria-label='Previous Page'
                  >
                    <ChevronLeftIcon
                      className='w-5 h-5 text-white'
                      fill='currentColor'
                    />
                  </button>
                </ShouldRender>
                <span className='inline-flex text-xs md:text-sm text-[#FFFFFF99] gap-x-2'>
                  <p className='underline'>{pageNumber}</p> / {numPages}
                </span>
                <ShouldRender check={pageNumber <= numPages}>
                  <button
                    ref={rightButtonRef}
                    onClick={async () => {
                      const nextPage = await getNextPage();
                      handlePageTransition(nextPage as number);
                    }}
                    tabIndex={0}
                    className='text-lg flex justify-center items-center w-5 h-5 md:w-6 md:h-6 md:bg-[#1C1C1C] rounded-full md:border border-[#FFFFFF0D]  hover:scale-105 transition-all duration-300'
                    aria-label='Next Page'
                  >
                    <ChevronRightIcon
                      className='w-5 h-5 text-white'
                      fill='currentColor'
                    />
                  </button>
                </ShouldRender>
              </div>
            </div>
            <ShouldRender check={!screens.md}>
              <div className='cursor-pointer absolute right-4 w-fit '>
                <div
                  onClick={() => {
                    onOpenDrawer();
                    const eventParams: EventDetails = {
                      event_name: 'screen_view',
                      source: 'ask_ai',
                      source_type: 'book',
                      source_id: book_id as string,
                      sub_source: null,
                      sub_source_id: null,
                      unit: 'boolean',
                      value: 1,
                      meta: {
                        click_source: 'books_reading_screen',
                      },
                    };

                    logEvent({
                      eventDetails: eventParams,
                      userData: userData,
                      pathname: router.pathname,
                    });
                  }}
                  className='relative rounded-[8px] h-min flex cursor-pointer overflow-hidden'
                >
                  {/* Shimmer Effect */}
                  <motion.div
                    className='absolute top-0 left-0 h-full w-full'
                    style={{
                      background:
                        'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 70%, rgba(255, 255, 255, 0) 100%)',
                      transform: 'skewX(-10deg)',
                    }}
                    animate={{ translateX: ['-50%', '100%'] }}
                    transition={{
                      duration: 3,
                      ease: [0.42, 0, 0.58, 1],
                      repeat: Infinity,
                    }}
                  />
                  {/* Button Content */}
                  <div
                    style={{
                      background:
                        'linear-gradient(270deg, #7E0D9D 0.3%, #7328F2 50%, #51A0F7 99.7%)',

                      cursor: 'pointer',
                      color:
                        'linear-gradient(270deg, #CCB3FF 0%, #FFB0DA 100%)',
                    }}
                    className=' w-min z-10 px-4   cursor-pointer h-8 flex justify-center items-center'
                  >
                    <span className='flex gap-x-2 whitespace-nowrap'>
                      <span className='translate-y-[2px]'>
                        <Star />
                      </span>
                      <span className='text-xs  font-semibold'>Ask AI</span>
                    </span>
                  </div>
                </div>
              </div>
            </ShouldRender>
          </motion.div>
        </ShouldRender>
      </Content>
    </Layout>
  );
};

export default PDFReaderIframe;
