/* eslint-disable @next/next/no-img-element */
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';

interface Props {
  loaderColor: 'black' | 'white';
  messages: string[];
  width?: string;
  height?: string;
}

export const LoadingV2: FC<Props> = ({
  loaderColor,
  messages,
  width,
  height,
}) => {
  const [messageIndex, setMessageIndex] = useState<number>(0);

  useEffect(() => {
    const messageInterval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 4000);

    return () => clearInterval(messageInterval);
  }, [messages.length]);

  return (
    <div
      className={clsx(
        'fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center z-50 overflow-hidden',
        'bg-transparent backdrop-blur-xl',
        width || 'w-full',
        height,
      )}
      style={{
        overflow: 'hidden',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      }} // Hides scrollbar
    >
      {loaderColor === 'black' ? (
        <img
          src='/Book_2_Black_Loader.gif'
          className='max-w-[80px] md:max-w-[170px] py-24'
          alt='Loading'
        />
      ) : (
        <img
          src='/Book_2_White_Loader.gif'
          className='max-w-[100px] md:max-w-[120px]'
          alt='Loading'
        />
      )}
      <div className='text-center text-base md:text-lg px-10 md:px-0 text-gray-300'>
        {messages[messageIndex]}
      </div>
    </div>
  );
};
