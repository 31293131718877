import { Divider, Input, Progress } from 'antd';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import AuthLayout from '../AuthLayout';
import ButtonDefault, { ButtonVariants } from '../../shared/basic/button';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { AppleLogo, GoogleIcon, ZebraLearnLogo } from '../../../../assets';
import EmailIcon from '../../../../assets/Icons/Mail';
import PasswordIcon from '../../../../assets/Icons/password';
import CustomIcon from '../../../../assets/Icons/User';
import appwriteService from '../../../config/appwrite';
import { useAuth } from '../../../hooks/useAuth';

interface Props {
  isModal?: boolean;
  onCancel?: () => void;
}

export const calculatePasswordStrengthV2 = (password: string) => {
  const missingCriteria = [
    !/[A-Z]/.test(password) ? 'Uppercase' : '',
    !/[a-z]/.test(password) ? 'Lowercase' : '',
    !/\d/.test(password) ? 'Number' : '',
    !/[\W_]/.test(password) ? 'Symbol' : '',
  ].filter(Boolean) as string[];

  const strength = password.length > 8 ? 4 : 2;

  return { strength, missingCriteria };
};

export const calculatePasswordStrengthV3 = (password: string) => {
  const missingCriteria = [
    password.length < 8 ? 'Min 8 chars' : '',
    !/[A-Z]/.test(password) ? 'Uppercase' : '',
    !/[a-z]/.test(password) ? 'Lowercase' : '',
    !/\d/.test(password) ? 'Number' : '',
    !/[\W_]/.test(password) ? 'Symbol' : '',
  ].filter(Boolean) as string[];

  let strength = password.length >= 8 ? 4 - missingCriteria.length : 0;
  if (strength === 4 && password.length >= 12) strength++;

  return { strength, missingCriteria };
};

export const calculatePasswordStrength = (password: string) => {
  let strength = 0;

  if (password.length >= 8) {
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    if (/\d/.test(password)) strength += 1;
    if (/[\W_]/.test(password)) strength += 1;
  } else {
    strength = 2;
  }

  return strength;
};
const SignUp: React.FC<Props> = ({ isModal = false, onCancel }) => {
  const [googleLoading, setGoogleLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);

  const [missingCriteria, setMissingCriteria] = useState<string[]>([]);
  const showAppleLogin =
    process.env.NEXT_PUBLIC_SHOW_APPLE_LOGIN === 'showLogin';
  const { loading, onSignUp } = useAuth();
  const router = useRouter();

  const [formData, setFormData] = useState({
    fullname: '',
    username: '',
    email: '',
    phone: '',
    password: '',
    retryPassword: '',
  });

  const [errors, setErrors] = useState({
    fullname: '',
    username: '',
    email: '',
    password: '',
    retryPassword: '',
  });
  const [passwordStrength, setPasswordStrength] = useState<number>(0);

  const getStrengthMessage = () => {
    if (missingCriteria.length === 0) return 'Your password is strong!';
    return `Weak password: ${missingCriteria.join(', ')}`;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'password') {
      const { strength, missingCriteria } = calculatePasswordStrengthV3(value);
      setPasswordStrength(strength);
      setMissingCriteria(missingCriteria);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = {
      fullname: '',
      username: '',
      email: '',
      password: '',
      retryPassword: '',
    };
    if (!formData.email) {
      validationErrors.email = 'Email is required';
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = 'Invalid email';
    }
    if (!formData.fullname) {
      validationErrors.fullname = 'Full Name is required';
    }

    if (!formData.password) {
      validationErrors.password = 'Password is required';
    } else if (formData.password.length < 8) {
      validationErrors.password = 'Password must be at least 8 characters long';
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      onSignUp({
        email: formData.email,
        password: formData.password,
        name: formData.fullname,
        phone: formData.phone,
        isModal: isModal,
        onCancel: onCancel,
      });
    }
  };
  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleGoogleLogin = async () => {
    setGoogleLoading(true);
    try {
      await appwriteService.loginWithGoogle();
      router.push('/');
    } catch (error) {
      console.error('Error logging in with Google:', error);
    } finally {
      setGoogleLoading(false);
    }
  };

  const handleAppleLogin = async () => {
    setAppleLoading(true);
    try {
      await appwriteService.loginWithApple();
    } catch (error) {
      console.error('Error logging in with Google:', error);
    } finally {
      setAppleLoading(false);
    }
  };

  return (
    <AuthLayout isModal={isModal}>
      <div className='z-30 flex justify-center py-10 items-center min-h-screen'>
        <div className='bg-bgColor bg-opacity-45 pb-5 max-w-[550px]   relative rounded-2xl md:border-2 border-borderDarkGray backdrop-filter backdrop-blur-sm'>
          <div className='flex items-center gap-y-10 flex-col px-10 justify-center'>
            <div className={` z-30  flex flex-col gap-y-1 w-full`}>
              <div className='relative pt-6'>
                <Link href='/'>
                  <span className=' flex justify-center w-full cursor-pointer'>
                    <Image
                      src={ZebraLearnLogo}
                      alt='Logo'
                      priority
                      className='object-contain h-4 w-full'
                    />
                  </span>
                </Link>
              </div>
              <div className='border-b-2 border-backgroundGray py-1.5' />
              <div className={clsx('py-2 flex flex-col ')}>
                <h1 className='text-xl flex justify-center gap-x-2 font-medium lg:font-semibold  mt-1 leading-10 text-fadedWhite text-center w-full'>
                  Create account for{' '}
                  <p className='text-primary font-medium lg:font-semibold'>
                    Free
                  </p>
                </h1>
                <span className='text-sm text-textDarkGrayColor text-center flex justify-center'>
                  No cost what-so-ever to create account.
                </span>
              </div>

              <form
                onSubmit={handleSubmit}
                className='flex flex-col mt-2 gap-y-2'
              >
                <div className='col-span-1 flex-col flex'>
                  <Input
                    name='fullname'
                    value={formData.fullname}
                    placeholder='Full Name'
                    prefix={
                      <span className='pr-2'>
                        <CustomIcon filled={true} />
                      </span>
                    }
                    onChange={handleChange}
                    style={{
                      backgroundColor: '#000000',
                    }}
                    size='large'
                    className='border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-2  text-white bg-bgColor customInputBlack makeBgBlack'
                  />
                  {errors.fullname && (
                    <p className='text-red-500'>{errors.fullname}</p>
                  )}
                </div>
                <div className='col-span-1 flex-col flex gap-y-.5'>
                  <Input
                    type='email'
                    name='email'
                    autoComplete='current-email'
                    autoFocus={false}
                    value={formData.email}
                    style={{
                      backgroundColor: '#000000',
                    }}
                    placeholder='Email'
                    prefix={
                      <span className='pr-2'>
                        <EmailIcon filled={true} />
                      </span>
                    }
                    onChange={handleChange}
                    size='large'
                    className='border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-2  text-white bg-bgColor customInputBlack makeBgBlack'
                  />
                  {errors.email && (
                    <p className='text-red-500'>{errors.email}</p>
                  )}
                </div>
                <div className='col-span-1 flex-col flex '>
                  <Input.Password
                    name='password'
                    type='password'
                    placeholder='Password'
                    autoComplete='current-password'
                    value={formData.password}
                    style={{
                      backgroundColor: '#000000',
                    }}
                    prefix={
                      <span className='pr-2'>
                        <PasswordIcon />
                      </span>
                    }
                    onChange={handleChange}
                    size='large'
                    className='border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-2  text-white bg-bgColor customInputBlack makeBgBlack'
                  />
                  {errors.password && (
                    <p className='text-red-500'>{errors.password}</p>
                  )}
                  <ShouldRender check={formData.password}>
                    {passwordStrength !== null && (
                      <span className='relative'>
                        <Progress
                          percent={(passwordStrength / 5) * 100}
                          showInfo={false}
                          status={
                            passwordStrength >= 3 ? 'success' : 'exception'
                          }
                        />
                        <p
                          className={`mt-2 text-${
                            passwordStrength >= 3 ? 'green' : 'red'
                          }-500 absolute text-xs -bottom-3 font-medium`}
                        >
                          {getStrengthMessage()}
                        </p>
                      </span>
                    )}
                  </ShouldRender>

                  {/* <span
                onClick={() => router.push('/auth/forgot-password')}
                className='font-medium text-primary mt-1 cursor-pointer hover:text-darkPrimary'
              >
                Forgot password?
              </span> */}
                </div>
                {/* <div className='col-span-1 flex-col flex'>
                  <Input
                    name='phone'
                    value={formData.phone}
                    inputMode='numeric'
                    type='tel'
                    placeholder='Phone Number'
                    style={{
                      backgroundColor: '#000000',
                    }}
                    prefix={
                      <span className='pr-2'>
                        <CustomIcon filled={true} />
                      </span>
                    }
                    onChange={handlePhoneInput}
                    size='large'
                    className='border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack'
                  />
                  {errors.phone && (
                    <p className='text-red-500'>{errors.phone}</p>
                  )}
                </div> */}
                <span className='flex justify-center mt-2'>
                  <ButtonDefault
                    size={4}
                    variant={ButtonVariants.WHITE}
                    className='p-1 w-full'
                    disabled={loading}
                    loading={loading}
                    rounded={true}
                    enableScaling={false}
                  >
                    <span className='flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap'>
                      Sign up
                    </span>
                  </ButtonDefault>
                </span>
                <span className='text-center font-medium flex text-white justify-center text-sm pt-2'>
                  Already have an account?
                  <p
                    onClick={() => {
                      router.push('/auth/login/');
                    }}
                    className='text-primary  px-1 hover:font-bold hover:underline cursor-pointer transition-all duration-300 ease-in-out'
                  >
                    Log in
                  </p>
                </span>
                <span className='flex justify-center'>
                  <span className='text-center text-base text-fadedWhite w-1/3'>
                    <Divider
                      style={{
                        margin: '8px 0',
                        color: '#1C1C1C',
                      }}
                      className='text-fadedWhite text-sm'
                    >
                      OR
                    </Divider>
                  </span>
                </span>
                <span className='flex justify-center '>
                  <ButtonDefault
                    size={4}
                    variant={ButtonVariants.UNSTYLED}
                    className='disabled:bg-bgDarkGray hover:bg-[#181818] disabled:text-gray-300 text-fadedWhite bg-black w-full  border border-bgDarkGray shadow-sm text-base'
                    rounded={true}
                    loading={googleLoading}
                    spinColor='gray'
                    disabled={googleLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      handleGoogleLogin();
                    }}
                  >
                    <span className='flex relative w-full justify-center text-white   items-center font-medium gap-x-3 px-8  whitespace-nowrap'>
                      <span className='w-5 h-5 absolute left-0'>
                        <Image
                          src={GoogleIcon}
                          alt='GoogleLogo'
                          className='object-contain'
                        />
                      </span>{' '}
                      Continue with Google
                    </span>
                  </ButtonDefault>
                </span>
                <ShouldRender check={showAppleLogin}>
                  <span className='flex justify-center '>
                    <ButtonDefault
                      size={4}
                      variant={ButtonVariants.UNSTYLED}
                      loading={appleLoading}
                      spinColor='gray'
                      rounded={true}
                      disabled={appleLoading}
                      className='disabled:bg-bgDarkGray hover:bg-[#181818] disabled:text-gray-300 text-fadedWhite bg-black w-full  border border-bgDarkGray shadow-sm text-base'
                      onClick={(e) => {
                        e.preventDefault();
                        handleAppleLogin();
                      }}
                    >
                      <span className='flex relative w-full items-end justify-center text-white   font-medium gap-x-3 px-8  whitespace-nowrap'>
                        <span className='w-5  absolute left-0'>
                          <Image
                            src={AppleLogo}
                            alt='GoogleLogo'
                            className='object-contain'
                          />
                        </span>{' '}
                        Continue with Apple
                      </span>
                    </ButtonDefault>
                  </span>
                </ShouldRender>

                <div className='flex justify-center'>
                  <p className='text-center text-fadedWhite text-sm  md:w-2/3 '>
                    By using our service, you are agreeing to our{' '}
                    <a
                      href='/Privacy_Policy_Reader.html'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-primary font-medium cursor-pointer hover:text-darkPrimary'
                    >
                      Terms
                    </a>{' '}
                    and{' '}
                    <a
                      href='/Privacy_Policy_Reader.html'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-primary font-medium cursor-pointer hover:text-darkPrimary'
                    >
                      conditions
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
export default SignUp;
