export const register = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(() => {
        return null;
      })
      .catch(() => {
        return null;
      });
  }
};
