import { useState } from 'react';

import { notify } from '../components/shared/basic/notify';
import { formateErrorObject } from '../components/shared/formateErrorObject';
import { Endpoints } from '../network';
import { apiClient } from '../services/apiClients';
import { getPayload } from '../services/Cart';
import {
  Address,
  AddressType,
  CountryType,
} from '../utils/interfaces/Manage-Addresses';

export const useAddresses = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [fieldLoading, setFieldLoading] = useState({
    country: false,
    state: false,
    city: false,
  });
  const [addresses, setAddresses] = useState<Address[]>();
  const [countries, setCountries] = useState<
    {
      label: string;
      value: number;
    }[]
  >();
  const [states, setStates] = useState<
    {
      label: string;
      value: number;
    }[]
  >();
  const [cities, setCities] = useState<
    {
      label: string;
      value: number;
    }[]
  >();

  const createAddress = async (data: {
    set_default: number;
    user_id: number;
    address: string;
    name?: string;
    country_id: string;
    city_id: string;
    state_id: string;
    postal_code: string;
    phone: string;
  }) => {
    try {
      setCreateLoading(true);
      await apiClient.post(Endpoints.createAddress, {
        ...data,
        set_default: data.set_default ? 1 : 0,
      });
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setCreateLoading(false);
    }
  };

  const getAllAddresses = async () => {
    try {
      setLoading(true);
      const data = await apiClient.get(Endpoints.getAllAddresses);

      const formattedAddresses = data?.data?.data?.map(
        (address: AddressType) => ({
          id: address.id,
          fullname: address?.name,
          email: 'address.email',
          postalcode: address.postal_code,
          country: address.country_name,
          set_default: address.set_default,
          phone: address?.phone,
          city: address.city_name,
          state: address.state_name,
          address: address.address,
          makeAsDefault: false,
          edit: { state: false, type: '' },
        }),
      );
      setAddresses(formattedAddresses);
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const getAllCountries = async () => {
    try {
      setFieldLoading((prev) => ({ ...prev, country: true }));
      const data = await apiClient.get(Endpoints.getAllCountries);
      const formattedArray = data?.data?.data?.map((country: CountryType) => ({
        label: country.name,
        value: country.id,
      }));
      setCountries(formattedArray);
      return formattedArray;
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setFieldLoading((prev) => ({ ...prev, country: false }));
    }
  };

  const getStates = async (id: string) => {
    try {
      setFieldLoading((prev) => ({ ...prev, state: true }));
      const data = await apiClient.get(`${Endpoints.getStates}/${id}`);
      const formattedArray = data?.data?.data?.map((state: CountryType) => ({
        label: state.name,
        value: state.id,
      }));
      if (formattedArray) {
        setStates(formattedArray);
        return formattedArray;
      }
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setFieldLoading((prev) => ({ ...prev, state: false }));
    }
  };

  const updateAddressOnBlur = async (params: {
    cart_id: number;
    fieldName: string;
    fieldValue: any;
    zl_uid?: string;
  }) => {
    try {
      const { cart_id, fieldName, fieldValue, zl_uid } = params;

      const payload = {
        cart_id,
        [fieldName]: fieldValue,
        ...(zl_uid && { zl_uid }),
      };

      const updatedPayload = await getPayload(payload);

      await apiClient.post(`${Endpoints.updateAddressOnBlur}`, updatedPayload);
    } catch (error: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  const updateAddressOnBlurParent = async (params: {
    cart_id: number;
    fieldName: string;
    fieldValue: any;
    zl_uid?: string;
  }) => {
    try {
      const { cart_id, fieldName, fieldValue, zl_uid } = params;

      const payload = {
        cart_id,
        others: JSON.stringify({
          [fieldName]: fieldValue,
        }),
        ...(zl_uid && { zl_uid }),
      };

      const updatedPayload = await getPayload(payload);

      await apiClient.post(`${Endpoints.updateAddressOnBlur}`, updatedPayload);
    } catch (error: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  const getCartPrefilledData = async (cart_id: number) => {
    try {
      const data = await apiClient.get(
        `${Endpoints.updateAddressOnBlur}/${cart_id}`,
      );
      return data?.data?.data;
    } catch (error: any) {
      return;
    }
  };

  const getCities = async (id: string | number) => {
    try {
      setFieldLoading((prev) => ({ ...prev, city: true }));
      const data = await apiClient.get(`${Endpoints.getCities}/${id}`);
      const formattedArray = data?.data?.data?.map((state: CountryType) => ({
        label: state.name,
        value: state.id,
      }));
      setCities(formattedArray);
      return formattedArray;
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setFieldLoading((prev) => ({ ...prev, city: false }));
    }
  };

  return {
    loading,
    getAllAddresses,
    getAllCountries,
    countries,
    fieldLoading,
    getStates,
    states,
    getCities,
    cities,
    createAddress,
    createLoading,
    address: addresses,
    updateAddressOnBlur,
    getCartPrefilledData,
    updateAddressOnBlurParent,
  };
};
