import React from 'react';

interface CustomIconProps {
  filled?: boolean;
  color?: string;
  width?: string;
}

const HeadphoneIcon: React.FC<CustomIconProps> = ({ width }) => (
  <svg
    width={width ?? '20'}
    height={width ?? '20'}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.33341 10.0013H5.83341C6.27544 10.0013 6.69937 10.1769 7.01193 10.4895C7.32449 10.802 7.50008 11.2259 7.50008 11.668V15.8346C7.50008 16.2767 7.32449 16.7006 7.01193 17.0131C6.69937 17.3257 6.27544 17.5013 5.83341 17.5013H3.33341C2.89139 17.5013 2.46746 17.3257 2.1549 17.0131C1.84234 16.7006 1.66675 16.2767 1.66675 15.8346V10.0013C1.66675 5.3988 5.39758 1.66797 10.0001 1.66797C14.6026 1.66797 18.3334 5.3988 18.3334 10.0013V15.8346C18.3334 16.2767 18.1578 16.7006 17.8453 17.0131C17.5327 17.3257 17.1088 17.5013 16.6667 17.5013H14.1667C13.7247 17.5013 13.3008 17.3257 12.9882 17.0131C12.6757 16.7006 12.5001 16.2767 12.5001 15.8346V11.668C12.5001 11.2259 12.6757 10.802 12.9882 10.4895C13.3008 10.1769 13.7247 10.0013 14.1667 10.0013H16.6667C16.6667 8.23319 15.9644 6.5375 14.7141 5.28726C13.4639 4.03701 11.7682 3.33464 10.0001 3.33464C8.23197 3.33464 6.53628 4.03701 5.28604 5.28726C4.03579 6.5375 3.33341 8.23319 3.33341 10.0013Z'
      fill='white'
    />
  </svg>
);

export default HeadphoneIcon;
