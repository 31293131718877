import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import React from 'react';

export enum ButtonVariants {
  WHITE = 'WHITE',
  PRIMARY = 'PRIMARY',
  UNSTYLED = 'UNSTYLED',
  GRAY = 'GRAY',
}

type ButtonSize = 0 | 1 | 2 | 3 | 4 | 5;
type ButtonTheme = 'apprentice' | 'corporate';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size: ButtonSize;
  variant: ButtonVariants;
  theme?: ButtonTheme;
  loading?: boolean;
  rounded?: boolean;
  roundedMd?: boolean;
  roundedSm?: boolean;
  spinColor?: string;
  enableScaling?: boolean;
  shimmer?: boolean; // Optional prop for shimmer
  id?: string;
  icon?: any;
  is3D?: boolean;
  isWidthFull?: boolean;
}

export const Spinner = ({ color = 'currentColor' }: { color?: string }) => {
  const screens = useBreakpoint();
  const size = screens.md ? 'h-5 w-5' : 'h-4 w-4';

  return (
    <svg className={`animate-spin ${size} mr-2 md:mr-3`} viewBox='0 0 24 24'>
      <circle
        className='opacity-25'
        cx='12'
        cy='12'
        r='10'
        stroke={color}
        strokeWidth='4'
      ></circle>
      <path
        className='opacity-75'
        fill={color}
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      ></path>
    </svg>
  );
};

const ButtonDefault: React.FC<ButtonProps> = ({
  children,
  variant,
  size,
  loading = false,
  rounded = false,
  roundedMd = false,
  roundedSm = false,
  spinColor = 'currentColor',
  enableScaling = true,
  shimmer = false,
  is3D = false, // New prop
  className,
  id,
  icon,
  isWidthFull = false,
  ...props
}) => {
  const commonStyles = {
    [ButtonVariants.WHITE]:
      'text-gray-700 font-medium bg-white border border-gray-300 hover:bg-gray-50 focus:ring-offset-2 focus:ring-2 disabled:text-gray-400 disabled:bg-gray-300 disabled:cursor-not-allowed',
    [ButtonVariants.GRAY]:
      'text-fadeWhite font-medium bg-[#282829] border border-bgDarkGray hover:bg-dividerColor focus:ring-offset-2 focus:ring-2 disabled:text-gray-400 disabled:bg-[#585858] disabled:cursor-not-allowed',
    [ButtonVariants.PRIMARY]:
      'text-white bg-primary border-primary hover:bg-darkPrimary hover:ring-primary font-medium focus:ring-offset-2 focus:ring-2 disabled:text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed',
    [ButtonVariants.UNSTYLED]: 'font-inter',
  };

  const commonButtonSizes = {
    '0': 'px-[4px] py-[2px] text-xs',
    '1': 'px-[11px] py-[7px] text-xs',
    '2': 'px-[13px] py-[9px] text-sm',
    '3': 'px-[17px] py-[9px] text-sm',
    '4': 'px-[17px] py-[9px] text-base',
    '5': 'px-[25px] py-[13px] text-base',
  };

  const buttonClasses = clsx(
    commonButtonSizes[size],
    commonStyles[variant],
    className,
    rounded && 'rounded-xl',
    rounded && roundedMd && 'lg:rounded-2xl',
    roundedSm && 'lg:rounded-lg',
    !rounded && 'rounded-lg',
    enableScaling &&
      'transition duration-300 ease-in-out transform hover:scale-[1.03]',
    'flex justify-center items-center relative overflow-hidden',
    enableScaling &&
      'focus:ring-2 focus:ring-offset-2 focus:ring-white focus:ring-opacity-50',
    is3D &&
      'shadow-[0px_-2px_4px_0px_#00000040_inset,0px_3px_2px_0px_#FFFFFF17_inset]', // Apply 3D styles
    is3D &&
      variant === ButtonVariants.PRIMARY &&
      'bg-[radial-gradient(50%_150%_at_46.7%_0%,_#FF7837_0%,_#F03906_100%)]', // Primary-specific styles
  );

  const shimmerAnimation = shimmer
    ? {
        animate: {
          backgroundPosition: ['200% 0%', '100% 0%', '-200% 0%'], // Intermediate step for pause
        },
        transition: {
          repeat: Infinity,
          duration: 4,
          ease: 'linear',
        },
      }
    : {};

  return (
    <button id={id || undefined} className={buttonClasses} {...props}>
      <div
        className={clsx(
          'relative z-10 flex items-center justify-center ',
          isWidthFull ? 'w-full' : '',
        )}
      >
        {loading && <Spinner color={spinColor} />}
        {icon && icon}

        {shimmer ? (
          <motion.span
            className='inline-block font-semibold md:px-3 py-1'
            style={{
              display: 'inline-block',
              backgroundImage:
                'linear-gradient(90deg, #000000 0%, #92BDFF 38.5%, #64B5FF 55%, #000000 100%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
              backgroundSize: '200% auto',
            }}
            {...shimmerAnimation}
          >
            {children}
          </motion.span>
        ) : (
          children
        )}
      </div>
    </button>
  );
};

export default ButtonDefault;
