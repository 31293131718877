import { Collapse, Input } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { customExpandIcon } from '../../CartPage';
import ButtonDefault, { ButtonVariants } from '../../shared/basic/button';
import EmailIcon from '../../../../assets/Icons/Mail';
import PasswordIcon from '../../../../assets/Icons/password';
import { authModalControl } from '../../../atoms/authModalControl';
import { loginCollapseAtom } from '../../../atoms/loginCollapse';
import { useAuth } from '../../../hooks/useAuth';

const isValidEmailOrPhone = (value: string) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const phoneRegex = /^\d{10}$/;
  return emailRegex.test(value) || phoneRegex.test(value);
};

const LoginCollapse = () => {
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [, setVisible] = useRecoilState(authModalControl);
  const loginCollapseTrigger = useRecoilValue(loginCollapseAtom);

  const screens = useBreakpoint();
  const router = useRouter();
  const { onLogin, loading: loginLoading } = useAuth();

  const isBuyNow = router.asPath.includes('/checkout');

  const onChange = useCallback((key: string | string[]) => {
    setActiveKeys(Array.isArray(key) ? key : [key]);
  }, []);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      const { email, password } = formData;
      const newErrors = { email: '', password: '' };

      if (!email) newErrors.email = 'Email or phone number is required';
      else if (!isValidEmailOrPhone(email))
        newErrors.email = 'Invalid email or phone number';
      if (!password) newErrors.password = 'Password is required';

      setErrors(newErrors);

      if (!newErrors.email && !newErrors.password) {
        const login_by = email.includes('@') ? 'email' : 'phone';
        await onLogin({ email, password, login_by, noRedirection: true });
      }
    },
    [formData, onLogin],
  );
  useEffect(() => {
    if (loginCollapseTrigger) {
      setActiveKeys(['1']);
    }
  }, [loginCollapseTrigger]);
  return (
    <div
      className={clsx(
        isBuyNow ? '' : 'md:pr-5 lg:pr-14 md:mt-4 lg:mt-0',
        '  mt-8 md:mt-12  pb-2',
      )}
    >
      <Collapse
        expandIconPosition='end'
        activeKey={activeKeys}
        expandIcon={customExpandIcon}
        accordion
        onChange={onChange}
        className='alreadyLoginCollapse rounded-2xl'
      >
        <Collapse.Panel
          className='bg-readerLightGrayBg '
          header={
            <span className='flex flex-wrap text-xs md:text-base md:px-3 gap-x-2 font-semibold pt-2 no-select md:items-center text-fadedWhite'>
              Returning customer?{' '}
              <p className='text-primary underline'>Click here to login</p>
            </span>
          }
          key='1'
        >
          <div className='text-fadedWhite px-1.5 md:px-3'>
            <span>
              If you have shopped with us before, please enter your details
              below. If you are a new customer, please proceed to the Billing
              section.
            </span>
            <form onSubmit={handleSubmit} className='flex flex-col mt-6 gap-3'>
              <div className='flex flex-col md:flex-row gap-3'>
                <Input
                  type='email'
                  name='email'
                  value={formData.email}
                  autoComplete='current-email'
                  placeholder='Email address *'
                  prefix={<EmailIcon filled={true} />}
                  onChange={handleChange}
                  size='large'
                  className='border text-base placeholder:text-textDarkGrayColor rounded-lg bg-bgColor customInputBlack'
                />
                {errors.email && <p className='text-red-500'>{errors.email}</p>}

                <Input.Password
                  name='password'
                  value={formData.password}
                  autoComplete='current-password'
                  placeholder='Password *'
                  prefix={<PasswordIcon />}
                  onChange={handleChange}
                  size='large'
                  className='border text-base placeholder:text-textDarkGrayColor rounded-lg bg-bgColor customInputBlack'
                />
                {errors.password && (
                  <p className='text-red-500'>{errors.password}</p>
                )}
              </div>

              <div className='flex flex-col gap-y-2 xs:flex-row xs:justify-between mt-2'>
                <ButtonDefault
                  size={screens.md ? 4 : 1}
                  variant={ButtonVariants.WHITE}
                  className='p-1 w-full xs:w-min'
                  disabled={loginLoading}
                  loading={loginLoading}
                  rounded
                >
                  <span className='flex text-sm font-bold gap-x-2 px-4 whitespace-nowrap'>
                    Log In
                  </span>
                </ButtonDefault>

                <span
                  onClick={() =>
                    setVisible((prev) => ({
                      ...prev,
                      login: false,
                      signUp: false,
                      forgotPassword: true,
                    }))
                  }
                  className='font-medium text-primary mt-1 cursor-pointer hover:text-darkPrimary'
                >
                  Forgot password?
                </span>
              </div>
            </form>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default LoginCollapse;
