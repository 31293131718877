import {
  IAuthorDetails,
  IBookDescription,
  IBookLandingPage,
  ICurriculumLandingPageData,
  IHeroSectionLandingPage,
  IIframeLandingPage,
  IRecommendedAndOverViewSection,
  ISlidBook,
  ISneakPeek,
  IValueAdditionSection,
} from '../../../utils/interfaces/landingPage';

export const extractHeroSectionData = (
  bookLandingDetails: IBookLandingPage,
): IHeroSectionLandingPage => {
  return {
    productHighlightText: bookLandingDetails.productHighlightText,
    productTitle:
      bookLandingDetails.bookName || bookLandingDetails.productTitle,
    authorName: bookLandingDetails.authorName,
    authorImage: bookLandingDetails.authorImage,
    authorDescription: bookLandingDetails.authorDescription,
    authorDesignation: bookLandingDetails.authorDesignation,
    companyIcon: bookLandingDetails.companyIcon,
    bookImage: bookLandingDetails.bookImage,
    bookBackImage: bookLandingDetails.bookBackImage,
    language: bookLandingDetails.language,
    isbn: bookLandingDetails.isbn,
    rating: bookLandingDetails.rating,
    numberOfPages: bookLandingDetails.numberOfPages,
    bookDescription: bookLandingDetails.bookDescription,
    shortDescription: bookLandingDetails.shortDescription,
    keyPoints: bookLandingDetails.keyPoints,
    authorZlUid: bookLandingDetails.authorZlUid,
    authUser_name: bookLandingDetails.authUser_name,
  };
};

export const extractSlidBookContainerData = (
  bookLandingDetails: IBookLandingPage,
): ISlidBook => {
  return {
    bookImage: bookLandingDetails?.bookImage,
    productHighlightText: bookLandingDetails?.productHighlightText,
    bookBackImage: bookLandingDetails?.bookBackImage,
    hardCopyPrice: {
      mrpPrice: bookLandingDetails?.hardCopyPrice?.mrpPrice,
      discountedPrice: bookLandingDetails?.hardCopyPrice?.discountedPrice,
    },
    softCopyPrice: {
      mrpPrice: bookLandingDetails?.softCopyPrice?.mrpPrice,
      discountedPrice: bookLandingDetails?.softCopyPrice?.discountedPrice,
    },
    digitalProductId: bookLandingDetails?.digitalProductId,
    hardCoverProductId: bookLandingDetails?.hardCoverProductId,
    productTitle: bookLandingDetails?.productTitle,
    bookSideImage: bookLandingDetails?.bookSideImage,
    bookName: bookLandingDetails?.bookName,
    hardCoverAvailable: bookLandingDetails?.hardCoverAvailable,
    readersProductId: bookLandingDetails?.readersProductId,
  };
};

export const extractIBookDescriptionData = (
  bookLandingDetails: IBookLandingPage,
): IBookDescription => {
  return {
    bookDescription: bookLandingDetails?.bookDescription,
    whatYouWillLearn: bookLandingDetails?.whatYouWillLearn,
  };
};

export const extractRecommendedAndOverViewSectionData = (
  bookLandingDetails: IBookLandingPage,
): IRecommendedAndOverViewSection => {
  return {
    reviewerPhoto: bookLandingDetails?.reviewerPhoto,
    reviewerName: bookLandingDetails?.reviewerName,
    reviewerDescription: bookLandingDetails?.reviewerDescription,
    overview: bookLandingDetails?.overview,
    bookIncludes: bookLandingDetails?.bookIncludes,
  };
};

export const extractCurriculumLandingPageData = (
  bookLandingDetails: IBookLandingPage,
): ICurriculumLandingPageData => {
  return {
    chapters: bookLandingDetails?.chapters,
  };
};

export const extractValueAdditionData = (
  bookLandingDetails: IBookLandingPage,
): IValueAdditionSection => {
  return {
    valueAddition: bookLandingDetails?.valueAddition,
  };
};

export const extractSneakPeekData = (
  bookLandingDetails: IBookLandingPage,
): ISneakPeek => {
  return {
    sneakPeek: bookLandingDetails?.sneakPeek,
  };
};

export const extractIframeData = (
  bookLandingDetails: IBookLandingPage,
): IIframeLandingPage => {
  return {
    iframeUrl: bookLandingDetails?.iframeUrl,
    thumbnailUrl: bookLandingDetails?.thumbnailUrl,
  };
};

export const extractAuthorDetailsData = (
  bookLandingDetails: IBookLandingPage,
): IAuthorDetails => {
  return {
    authorName: bookLandingDetails?.authorName,
    authorImage: bookLandingDetails?.authorImage,
    authorDescription: bookLandingDetails?.authorDescription,
    authorDesignation: bookLandingDetails?.authorDesignation,
    companyIcon: bookLandingDetails?.companyIcon,
  };
};
