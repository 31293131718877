import {
  RotateLeftOutlined,
  RotateRightOutlined,
  UnorderedListOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { Affix, Button, Layout, Tooltip } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { ShouldRender } from '../shared/basic/ShouldRender';
import { isBannerVisible } from '../../atoms/isBannerVisible';
import { EventDetails, logEvent } from '../../hooks/useClickEventTracking';
import { User } from '../../utils/interfaces/User';
import DoublePageIcon from '../../../assets/Icons/DoublePageIcon';
import HeadphoneIcon from '../../../assets/Icons/headphoneIcon';
import SinglePageIcon from '../../../assets/Icons/SinglePageIcon';
import Star from '../../../assets/Icons/Star';

const { Header } = Layout;

interface Props {
  isFullScreen: boolean;
  toggleFullScreen: () => void;
  samplePdf?: string;
  showDrawer: () => void;
  visible: boolean;
  zoomValue: number;
  zoomOut: () => void;
  zoomIn: () => void;
  sideBySide: boolean;
  title: string;
  rotateLeft: (pageNumber: number) => void;
  pageNumber: number;
  rotateRight: (pageNumber: number) => void;
  onOpenDrawer: () => void;
  book_id: string;
  userData: User;
  openDrawer: boolean;
  toggleSideBySide: () => void;
  isIphone: () => boolean;
  fullScreenIcon: any;
  playAudio?: () => void;
  showAudio?: boolean;
  isModal?: boolean;
  headerClass?: string;
  handleCancel?: () => void;
  hideAudioBook?: boolean;
}

const PdfReaderHeader: React.FC<Props> = ({
  visible,
  isFullScreen,
  toggleFullScreen,
  samplePdf,
  showDrawer,
  zoomValue,
  zoomOut,
  zoomIn,
  sideBySide,
  title,
  rotateLeft,
  pageNumber,
  rotateRight,
  onOpenDrawer,
  book_id,
  userData,
  toggleSideBySide,
  openDrawer,
  isIphone,
  fullScreenIcon,
  playAudio,
  showAudio,
  headerClass,
  isModal = false,
  handleCancel,
  hideAudioBook = false,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const router = useRouter();
  const screens = useBreakpoint();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleScroll = () => setIsScrolled(window.scrollY > 56);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isBannerVisibleValue = useRecoilValue<boolean | null>(isBannerVisible);

  return (
    <>
      <Affix offsetTop={0}>
        <Header
          className={clsx(
            headerClass ??
              `${
                isFullScreen && 'fixed'
              } bg-bgColor bg-opacity-75 px-5  lg:px-10  backdrop-filter flex border-bgColor backdrop-blur-md border-t z-40 w-full transition-all max-h-12`,
            !isScrolled
              ? 'mt-0 -translate-y-0'
              : isBannerVisibleValue && !screens.md
                ? `mt-14 -translate-y-2`
                : 'mt-0 -translate-y-0',
          )}
        >
          <div className='flex text-fadedWhite justify-between items-center h-full w-full '>
            <div className='flex items-center md:w-full h-full gap-x-1 md:gap-x-4 '>
              <ShouldRender check={!isModal}>
                <div
                  onClick={() => {
                    isFullScreen && toggleFullScreen();
                    router.push('/books');
                  }}
                  className='group flex md:gap-x-3 cursor-pointer items-center'
                >
                  <div className='flex md:bg-cartBgColor items-center justify-center relative cursor-pointer group-hover:bg-gray-200 group-hover:bg-opacity-15  md:p-1 w-7 h-7 md:w-6 md:h-6 rounded-full'>
                    <ChevronLeftIcon className='text-xl w-5 md:w-4' />
                  </div>
                  <ShouldRender check={screens.md}>
                    <span className='truncate max-w-20 lg:max-w-full'>
                      {title}
                    </span>
                    <span className='border h-1/3 border-backGroundReaderTabBg' />
                  </ShouldRender>
                </div>
              </ShouldRender>

              <ShouldRender check={isModal}>
                <Button
                  onClick={() => {
                    handleCancel && handleCancel();
                  }}
                >
                  <div className='flex justify-center items-center gap-x-2 '>
                    <span>
                      <XIcon className='w-4' />
                    </span>
                    Close
                  </div>
                </Button>
              </ShouldRender>
            </div>

            <div className='flex w-full  justify-start items-center pl-3 mt-1 md:justify-center  '>
              <div className='flex gap-x-4 justify-center items-center'>
                <span>
                  <Tooltip title={`Zoom Out ${zoomValue}%`} placement='bottom'>
                    <div className='group'>
                      <button onClick={zoomOut}>
                        <div className=' text-white '>
                          <ZoomOutOutlined style={{ fontSize: '20px' }} />
                        </div>
                      </button>
                    </div>
                  </Tooltip>
                </span>
                <span className=''>
                  <Tooltip title={`Zoom In ${zoomValue}%`} placement='bottom'>
                    <div className='group '>
                      <button onClick={zoomIn}>
                        <div className=' text-white '>
                          <ZoomInOutlined style={{ fontSize: '20px' }} />
                        </div>
                      </button>
                    </div>
                  </Tooltip>
                </span>
                <span className='border h-4 border-backGroundReaderTabBg' />
                <ShouldRender check={screens.md && !openDrawer}>
                  <div
                    className={clsx(
                      'relative flex items-center bg-[#1C1C1C] w-16 h-8 rounded-full cursor-pointer px-1 transition-all duration-500 ease-in-out',
                    )}
                    onClick={() => toggleSideBySide()}
                    role='switch'
                    aria-checked={sideBySide}
                  >
                    {/* Single Page Icon */}
                    <motion.div
                      className={clsx(
                        'absolute left-[12px] z-20 transition-colors duration-500 ease-in-out',
                        sideBySide ? 'text-[#FFFFFF99]' : 'text-[#1C1C1C]',
                      )}
                    >
                      <SinglePageIcon />
                    </motion.div>

                    {/* Double Page Icon */}
                    <motion.div
                      className={clsx(
                        'absolute right-1.5 z-20 transition-colors duration-500 ease-in-out',
                        sideBySide ? 'text-[#1C1C1C]' : 'text-[#FFFFFF99]',
                      )}
                    >
                      <DoublePageIcon />
                    </motion.div>

                    {/* Toggle Knob */}
                    <motion.div
                      className='w-7 h-7 bg-white z-10 rounded-full flex items-center justify-center shadow-md transition-all'
                      initial={false}
                      animate={{
                        x: sideBySide ? 32 : 0,
                        scale: sideBySide ? 1.05 : 1,
                        backgroundColor: sideBySide ? '#FFF' : '#F0F0F0',
                        boxShadow: sideBySide
                          ? '0px 0px 12px rgba(0, 102, 255, 0.5)'
                          : '0px 0px 6px rgba(0, 0, 0, 0.1)',
                      }}
                      transition={{ duration: 0.4, ease: 'easeInOut' }}
                    />
                  </div>
                </ShouldRender>
              </div>
            </div>

            <div className='flex w-full justify-end  gap-x-4 items-center'>
              <ShouldRender check={!sideBySide && screens.md}>
                <Tooltip title='Rotate Anti-Clockwise' placement='bottom'>
                  <div className='group'>
                    <button
                      onClick={() => rotateLeft(pageNumber)}
                      className='flex items-center  justify-center relative cursor-pointer group-hover:bg-gray-200 group-hover:bg-opacity-15  p-2  w-10 h-10 rounded-full'
                    >
                      <div className=' text-white '>
                        <RotateLeftOutlined style={{ fontSize: '20px' }} />
                      </div>
                    </button>
                  </div>
                </Tooltip>
                <Tooltip title='Rotate Clockwise' placement='bottom'>
                  <div className='group'>
                    <button
                      onClick={() => rotateRight(pageNumber)}
                      className='flex items-center  justify-center relative cursor-pointer group-hover:bg-gray-200 group-hover:bg-opacity-15  p-2  w-10 h-10 rounded-full'
                    >
                      <div className=' text-white '>
                        <RotateRightOutlined style={{ fontSize: '20px' }} />
                      </div>
                    </button>
                  </div>
                </Tooltip>
              </ShouldRender>
              <ShouldRender check={screens.md}>
                <div className='cursor-pointer'>
                  <div
                    onClick={() => {
                      onOpenDrawer();
                      const eventParams: EventDetails = {
                        event_name: 'screen_view',
                        source: 'ask_ai',
                        source_type: 'book',
                        source_id: book_id as string,
                        sub_source: null,
                        sub_source_id: null,
                        unit: 'boolean',
                        value: 1,
                        meta: {
                          click_source: 'books_reading_screen',
                        },
                      };

                      logEvent({
                        eventDetails: eventParams,
                        userData: userData,
                        pathname: router.pathname,
                      });
                    }}
                    className='relative rounded-[8px] h-min flex cursor-pointer overflow-hidden'
                  >
                    {/* Shimmer Effect */}
                    <motion.div
                      className='absolute top-0 left-0 h-full w-full'
                      style={{
                        background:
                          'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 70%, rgba(255, 255, 255, 0) 100%)',
                        transform: 'skewX(-10deg)',
                      }}
                      animate={{ translateX: ['-50%', '100%'] }}
                      transition={{
                        duration: 3,
                        ease: [0.42, 0, 0.58, 1],
                        repeat: Infinity,
                      }}
                    />
                    {/* Button Content */}
                    <div
                      style={{
                        background:
                          'linear-gradient(270deg, #7E0D9D 0.3%, #7328F2 50%, #51A0F7 99.7%)',

                        cursor: 'pointer',
                        color:
                          'linear-gradient(270deg, #CCB3FF 0%, #FFB0DA 100%)',
                      }}
                      className=' w-min z-10 px-4   cursor-pointer h-7 md:h-8 flex justify-center items-center'
                    >
                      <span className='flex gap-x-2 whitespace-nowrap'>
                        <span className='translate-y-[2px]'>
                          <Star />
                        </span>
                        <span className='text-xs  font-semibold'>Ask AI</span>
                      </span>
                    </div>
                  </div>
                </div>
              </ShouldRender>
              <ShouldRender check={!hideAudioBook}>
                <Button
                  icon={
                    !showAudio ? (
                      <HeadphoneIcon width={!screens.md ? '16' : undefined} />
                    ) : (
                      <XIcon className='w-4 h-4  text-white cursor-pointer' />
                    )
                  }
                  onClick={playAudio}
                  className={clsx(
                    !screens.md && 'border-none',
                    'md:border-white  rounded-[8px] text-xs bg-cartBgColor md:bg-bgColor font-semibold text-white shadow-md hover:shadow-lg transition-all',
                  )}
                >
                  <ShouldRender check={screens.md}>
                    {showAudio ? 'Stop Listening' : 'Listen Book'}
                  </ShouldRender>
                  <ShouldRender check={!screens.md}>
                    {showAudio ? 'Stop Listening' : 'Listen'}
                  </ShouldRender>
                </Button>
              </ShouldRender>

              <ShouldRender check={!samplePdf}>
                <div className='flex items-center'>
                  <button
                    onClick={showDrawer}
                    className='flex justify-center cursor-pointer '
                  >
                    <UnorderedListOutlined
                      className={clsx(
                        visible ? 'text-primary' : 'text-white  ',
                      )}
                      style={{ fontSize: screens.md ? '24px' : '18px' }}
                    />
                  </button>
                </div>
              </ShouldRender>

              <ShouldRender check={!isIphone()}>
                <ShouldRender check={screens.lg}>
                  <Tooltip title='Full screen' placement='bottom'>
                    {fullScreenIcon}
                  </Tooltip>
                </ShouldRender>
                <ShouldRender check={!screens.lg}>
                  {fullScreenIcon}
                </ShouldRender>
              </ShouldRender>
            </div>
          </div>
        </Header>
      </Affix>
    </>
  );
};

export default PdfReaderHeader;
