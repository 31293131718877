import React from 'react';

interface CustomIconProps {
  width?: string;
  height?: string;
  filled?: boolean;
}

const ProfileIcons: React.FC<CustomIconProps> = ({
  width,
  height,
  filled = true,
}) => (
  <svg
    width={width ?? '31'}
    height={height ?? '40'}
    viewBox='0 0 31 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    {filled ? (
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.7466 11.0333C25.7466 16.6276 21.2611 21.1134 15.6628 21.1134C10.0664 21.1134 5.57902 16.6276 5.57902 11.0333C5.57902 5.43893 10.0664 0.955078 15.6628 0.955078C21.2611 0.955078 25.7466 5.43893 25.7466 11.0333ZM15.6628 39.052C7.40063 39.052 0.424683 37.7091 0.424683 32.5281C0.424683 27.3452 7.44446 26.0499 15.6628 26.0499C23.9268 26.0499 30.9009 27.3928 30.9009 32.5738C30.9009 37.7567 23.8811 39.052 15.6628 39.052Z'
        fill='#F9F9FA'
      />
    ) : (
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.6628 2.95508C10.4805 2.95508 6.57902 6.8588 6.57902 11.0333C6.57902 15.2089 10.4805 19.1134 15.6628 19.1134C20.8474 19.1134 24.7466 15.2089 24.7466 11.0333C24.7466 6.8588 20.8474 2.95508 15.6628 2.95508ZM15.6628 21.1134C9.67117 21.1134 4.57902 16.0216 4.57902 11.0333C4.57902 6.04662 9.67117 0.955078 15.6628 0.955078C21.6575 0.955078 26.7466 6.04662 26.7466 11.0333C26.7466 16.0216 21.6575 21.1134 15.6628 21.1134ZM15.6628 28.0499C8.97889 28.0499 2.42468 29.3452 2.42468 32.5281C2.42468 35.7091 8.92682 37.052 15.6628 37.052C22.3977 37.052 28.9009 35.7091 28.9009 32.5281C28.9009 29.3452 22.3467 28.0499 15.6628 28.0499ZM15.6628 39.052C8.21461 39.052 0.424683 37.7091 0.424683 32.5281C0.424683 27.3452 8.15153 26.0499 15.6628 26.0499C23.1766 26.0499 30.9009 27.3452 30.9009 32.5281C30.9009 37.7091 23.1109 39.052 15.6628 39.052Z'
        fill='#F9F9FA'
      />
    )}
  </svg>
);

export default ProfileIcons;
