const capitalizeWords = (str: string) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const generateBreadcrumbSchema = (asPath: string) => {
  const environment = process.env.NEXT_PUBLIC_APP_ENV;
  const baseUrl =
    environment !== 'production'
      ? 'https://staging.zebralearn.com'
      : 'https://zebralearn.com';

  const [pathWithoutHash] = asPath.split('#');

  const segments = pathWithoutHash?.split('/').filter(Boolean);

  // Build breadcrumbs
  const breadcrumbs = segments?.map((segment, index) => {
    const url = `${baseUrl}/${segments.slice(0, index + 1).join('/')}`;
    const name = capitalizeWords(
      decodeURIComponent(segment).replace(/-/g, ' '),
    );

    return {
      '@type': 'ListItem',
      position: index + 2,
      name,
      item: url,
    };
  });

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: baseUrl,
      },
      ...(breadcrumbs || []),
    ],
  };
};
