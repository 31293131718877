import React from 'react';

interface CustomIconProps {
  filled?: boolean;
  color?: string;
}

const SinglePageIcon: React.FC<CustomIconProps> = () => (
  <svg
    width='11'
    height='12'
    viewBox='0 0 11 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.78572 0C10.013 0 10.2311 0.0903061 10.3918 0.251051C10.5526 0.411797 10.6429 0.629814 10.6429 0.857143V11.1429C10.6429 11.3702 10.5526 11.5882 10.3918 11.7489C10.2311 11.9097 10.013 12 9.78572 12H1.21429C0.986962 12 0.768945 11.9097 0.6082 11.7489C0.447453 11.5882 0.357148 11.3702 0.357148 11.1429L0.357147 0.857143C0.357147 0.629814 0.447453 0.411797 0.608199 0.251051C0.768944 0.0903061 0.986961 0 1.21429 0H9.78572ZM2.07143 10.2857H8.92858V1.71429H2.07143L2.07143 10.2857Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.2'
    />
  </svg>
);

export default SinglePageIcon;
