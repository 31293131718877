import React from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';

type ScrollableFeedProps = {
  children?: React.ReactNode;
  className?: string;
  loading?: boolean;
};

const ScrollableFeed: React.FC<ScrollableFeedProps> = ({
  children,
  className,
}) => {
  return (
    <ScrollToBottom
      className={`scrollable-feed ${className}`}
      scrollViewClassName='no-scrollbar'
    >
      {children as any}
    </ScrollToBottom>
  );
};

export default ScrollableFeed;
