import Cookies from 'js-cookie';

import { apiClient, baseForPdf } from '../apiClients';
import {
  getLocalUserId,
  getLoginToken,
  getUserId,
  removeMarketingData,
} from '../../components/shared/getToken';
import { Endpoints } from '../../network';
import { AddToCartProps, UpdateCartProps } from '../../utils/interfaces/cart';

interface ICartCount {
  id?: string;
  localId?: string;
  cartId?: number;
}

type CartCountParams =
  | { temp_user_id: string | undefined; user_id?: undefined; cart_id?: number }
  | { user_id: string | undefined; temp_user_id?: undefined; cart_id?: number };

type CartSummaryParams = {
  cart_id?: number;
  user_id?: string;
  temp_user_id?: string;
};

export const getPayload = async (data: any) => {
  try {
    const [token, userId, localUserId] = await Promise.all([
      getLoginToken(),
      getUserId(),
      getLocalUserId(),
    ]);

    return !token
      ? { ...data, temp_user_id: localUserId }
      : { ...data, user_id: userId };
  } catch (error) {
    console.error('Error getting token or user ID:', error);
  }
};

export const getCartDetails = async (id: string, cartId?: number) => {
  let payload = await getPayload({ temp_user_id: id });
  if (cartId) payload = { ...payload, cart_id: cartId };
  const response = await apiClient.post(`${Endpoints.getCartDetails}`, payload);
  return response?.data;
};

export const deleteCartItem = async (id: string, cartId?: number) => {
  const localUserId = await getLocalUserId();
  const payload = await getPayload({ temp_user_id: localUserId });
  const response = await apiClient.delete(`${Endpoints.getCartDetails}/${id}`, {
    ...payload,
    ...(cartId && { cart_id: cartId }),
  });
  return response?.data;
};

export const mergeAllCarts = async () => {
  const localUserId = await getLocalUserId();
  const payload = await getPayload({ temp_user_id: localUserId });
  const response = await apiClient.post(`${Endpoints.mergeAllCarts}`, {
    ...payload,
  });
  return response?.data;
};

export const getCartSummary = async (cartId?: number) => {
  const [token, userId, localUserId] = await Promise.all([
    getLoginToken(),
    getUserId(),
    getLocalUserId(),
  ]);
  let params: CartSummaryParams;
  params = !token ? { temp_user_id: localUserId } : { user_id: userId };
  if (cartId) {
    params = { ...params, cart_id: cartId };
  }

  // Use the params in the API call and return the typed response
  const response = await apiClient.get(`${Endpoints.cartSummary}`, { params });
  return response?.data;
};

export const updateCartAddress = async (
  address_id: number,
  cartId?: number,
) => {
  const payload = await getPayload({ address_id });
  if (cartId) payload.cart_id = cartId;
  if (address_id) {
    const response = await apiClient.post(
      `${Endpoints.updateCartAddress}`,
      payload,
    );
    return response?.data;
  }
};

export const addCouponCode = async (coupon_code: string, cartId?: number) => {
  const payload = await getPayload({ coupon_code });
  const response = await apiClient.post(`${Endpoints.applyCoupon}`, {
    ...payload,
    ...(cartId && { cart_id: cartId }),
  });
  return response?.data;
};

export const addToRecentlyViewed = async (
  productId: string | number,
  zl_uid?: string,
) => {
  try {
    const localUserId = await getLocalUserId();

    const response = await baseForPdf.post(`${Endpoints.recentlyViewed}`, {
      productId,
      tempUserId: localUserId,
      ...(zl_uid && { zl_uid }),
    });

    return response?.data;
  } catch {
    return null;
  }
};

export const appendMarketingData = (payload: any) => {
  if (Cookies.get('marketing_data')) {
    payload.marketing_data = JSON.parse(
      Cookies.get('marketing_data') as string,
    );
  }
  return payload;
};

export const addToCart = async (data: AddToCartProps, isBuyNow?: boolean) => {
  let payload = await getPayload(data);

  if (isBuyNow) {
    payload = { ...payload, buy_now: true };
  }

  const response = await apiClient.post(
    `${Endpoints.addToCart}`,
    appendMarketingData(payload),
  );

  return response?.data;
};

export const updateCartProductQuantity = async (data: UpdateCartProps) => {
  const payload = await getPayload(data);
  const response = await apiClient.post(`${Endpoints.updateQuantity}`, payload);
  return response?.data;
};

export const getCartCount = async ({ id, localId, cartId }: ICartCount) => {
  const [token, userId, localUserId] = await Promise.all([
    getLoginToken(),
    getUserId(),
    getLocalUserId(),
  ]);

  const params: CartCountParams = localId
    ? { temp_user_id: localId }
    : id
      ? { user_id: id }
      : !token
        ? { temp_user_id: localUserId }
        : { user_id: userId };

  if (cartId) params.cart_id = cartId;

  const response = await apiClient.get(`${Endpoints.getCartCount}`, { params });
  return response?.data;
};

export const placeOrder = async (payment_type: string, cartId?: number) => {
  const payload: {
    payment_type: string;
    cart_id?: number;
  } = {
    payment_type,
  };

  if (cartId) payload.cart_id = cartId;

  const response = await apiClient.post(
    `${Endpoints.placeOrder}`,
    appendMarketingData(payload),
  );

  if (Cookies.get('marketing_data')) {
    await removeMarketingData();
  }

  return response?.data;
};

export const createRazorpayPayment = async (
  data: {
    order_id: string;
    razorpay_order_id: string;
    razorpay_payment_id: string;
    razorpay_signature: string;
  },
  cartId?: number,
) => {
  const response = await apiClient.post(`${Endpoints.razorpayPayment}`, {
    ...data,
    ...(cartId && { cart_id: cartId }),
  });

  return response?.data;
};

export const handlePaymentFailed = async (
  data: {
    order_id: string;
    razorpay_order_id: string;
  },
  cartId?: number,
) => {
  const response = await apiClient.post(`${Endpoints.paymentFailed}`, {
    ...data,
    ...(cartId && { cart_id: cartId }),
  });
  return response?.data;
};

export const handleConvertToCod = async (
  data: { order_id: string },
  cartId?: number,
) => {
  const response = await apiClient.post(`${Endpoints.convertToCod}`, {
    ...data,
    ...(cartId && { cart_id: cartId }),
  });
  return response?.data;
};

export const checkEmailExist = async (email: string) => {
  const response = await apiClient.post(`${Endpoints.emailExist}`, { email });
  return response?.data;
};

export const getOrderDetails = async (orderId: string) => {
  const response = await apiClient.get(`${Endpoints.orderDetails}/${orderId}`);

  return response?.data?.data;
};

export const handleRemoveCoupon = async (
  coupon_code: string,
  cartId?: number,
) => {
  const payload = await getPayload({ coupon_code });
  const response = await apiClient.post(`${Endpoints.removeCoupon}`, {
    ...payload,
    ...(cartId && { cart_id: cartId }),
  });
  return response?.data;
};

export const handleCheckCoupon = async (cartId?: number) => {
  const response = await apiClient.post(`${Endpoints.checkCoupon}`, {
    ...(cartId && { cart_id: cartId }),
  });
  return response?.data;
};
