import React from 'react';

interface CustomIconProps {
  filled?: boolean;
  color?: string;
}

const DoublePageIcon: React.FC<CustomIconProps> = () => (
  <svg
    width='15'
    height='12'
    viewBox='0 0 15 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.07142 10.2857H0.642853V12H4.92857C5.15589 12 5.37391 11.9097 5.53466 11.7489C5.6954 11.5882 5.78571 11.3702 5.78571 11.1429L5.78571 0.857143C5.78571 0.629814 5.6954 0.411797 5.53466 0.251051C5.37391 0.090306 5.1559 0 4.92857 0H0.642853V1.71429L4.07142 1.71429L4.07142 10.2857ZM14.3571 1.71429L10.9286 1.71429V10.2857H14.3571L14.3571 12H10.0714C9.8441 12 9.62608 11.9097 9.46533 11.7489C9.30459 11.5882 9.21428 11.3702 9.21428 11.1429V0.857143C9.21428 0.629814 9.30459 0.411797 9.46533 0.251051C9.62608 0.0903058 9.8441 0 10.0714 0H14.3571V1.71429Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.2'
    />
  </svg>
);

export default DoublePageIcon;
