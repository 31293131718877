import { UserOutlined } from '@ant-design/icons';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { Avatar, Drawer, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import moment from 'moment';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { getUserInitials, Message } from './Message';
import { ShouldRender } from '../shared/basic/ShouldRender';
import CustomLoader from '../shared/Loading';
import { user } from '../../atoms/user';
import { EventDetails, logEvent } from '../../hooks/useClickEventTracking';
import { getChatThreads, getSingleChatThreads } from '../../services/Chat';
import { User } from '../../utils/interfaces/User';
import { EmptyScreen } from '../../../assets';
import BackIcon from '../../../assets/Icons/BackIcon';
import EmptyIconWrite from '../../../assets/Icons/EmptyIconWrite';

export interface IUserInfoInterface {
  id: number;
  name: string;
  email: string;
  email_verified_at: Date;
  created_at: Date;
  updated_at: Date;
}

interface Contact {
  id: number;
  name: string;
  follower_count: number;
}

interface IMessage {
  id: number;
  sender: IUserInfoInterface;
  createdAt: string;
  messages: any;
}

interface GroupedMessages {
  date: string;
  messages: IMessage[];
}

export const formatFollowerCount = (number: number): string => {
  const suffixes = ['k', 'M', 'B'];
  let suffixIndex = -1;

  while (number >= 1000 && suffixIndex < suffixes.length - 1) {
    number /= 1000;
    suffixIndex++;
  }

  const formatted = number.toFixed(1);
  return suffixIndex === -1
    ? number.toString()
    : formatted.endsWith('.0')
      ? `${parseInt(formatted)}${suffixes[suffixIndex]}`
      : `${formatted}${suffixes[suffixIndex]}`;
};

const formatDataMessages = (data: any): { messages: IMessage[] } => {
  return {
    messages: data?.messages?.map((item: any) => {
      return {
        id: data?.id,
        createdAt: item?.created_at,
        sender: {
          id: item?.sender_id,
          name:
            item?.sender_id === data?.user_id
              ? data?.user_name
              : data?.author_name,
        },
        messages: JSON.parse(item?.message),
      };
    }),
  };
};

const formatDateForGrouping = (date: string): string => {
  const momentDate = moment(date);
  if (momentDate.isSame(moment(), 'day')) {
    return 'Today';
  } else if (momentDate.isSame(moment().subtract(1, 'days'), 'day')) {
    return 'Yesterday';
  } else {
    return momentDate.format('MMMM DD, YYYY');
  }
};

const formatDateWithTime = (date: string): string => {
  const momentDate = moment(date);
  if (momentDate.isSame(moment(), 'day')) {
    return `Today, ${momentDate.format('hh:mm A')}`;
  } else if (momentDate.isSame(moment().subtract(1, 'days'), 'day')) {
    return `Yesterday, ${momentDate.format('hh:mm A')}`;
  } else {
    return momentDate.format('MMMM DD, YYYY, hh:mm A');
  }
};

const groupMessagesByDate = (messagesList: IMessage[]): GroupedMessages[] => {
  const groupedMessages: { [key: string]: IMessage[] } = {};

  messagesList?.forEach((message) => {
    const formattedDateForGrouping = formatDateForGrouping(message.createdAt);
    const formattedDateWithTime = formatDateWithTime(message.createdAt);
    message.createdAt = formattedDateWithTime;

    if (!groupedMessages[formattedDateForGrouping]) {
      groupedMessages[formattedDateForGrouping] = [];
    }
    groupedMessages[formattedDateForGrouping]?.push(message);
  });

  return Object.entries(groupedMessages).map(([date, messages]) => ({
    date,
    messages,
  }));
};

const ChatBox: React.FC = () => {
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const userData = useRecoilValue<User | undefined>(user);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [messagesList, setMessagesList] = useState<IMessage[]>();
  const [usersList, setUsersList] = useState<
    {
      id: number;
      name: string;
      updated_at: string;
      avatar: string;
      last_message_info: string;
      follower_count: number;
      author_id?: number;
      un_read_count: number;
    }[]
  >();
  const [loadingThread, setLoadingThread] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [previousContactId, setPreviousContactId] = useState<number | null>(
    null,
  );
  const [previousAuthorId, setPreviousAuthorId] = useState<number | null>(null);

  const router = useRouter();
  const { id: threadId } = router.query;

  const screens = useBreakpoint();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollToBottom();
  }, [messagesList, selectedContact]);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight + 50;
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const removeHTMLTags = (str: string) => str.replace(/<\/?[^>]+(>|$)/g, '');

  const formatDataUserList = (data: any) => {
    return {
      id: data.id,
      name: data.author_name,
      updated_at: data?.last_update,
      author_id: data?.author_id,
      avatar: data?.image,
      un_read_count: data?.un_read_count,
      follower_count: data?.author?.follower_count,
      last_message_info: removeHTMLTags(
        JSON.parse(data?.last_message_info?.message)
          .map((item: any) => item?.value)
          .join(' '),
      ),
    };
  };

  const handleGetChatThreads = async () => {
    try {
      setLoading(true);
      const response = await getChatThreads();

      const formattedData = (response?.data ?? response)?.map(
        formatDataUserList,
      );
      setUsersList(formattedData);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };

  const groupedMessages = useMemo(
    () => groupMessagesByDate(messagesList as IMessage[]),
    [messagesList],
  );

  const handleSingleThread = async (id: string) => {
    try {
      setLoadingThread(true);
      const response = await getSingleChatThreads(id);

      const formattedData = formatDataMessages(response?.data);

      setMessagesList(formattedData?.messages);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoadingThread(false);
    }
  };

  useEffect(() => {
    handleGetChatThreads();
  }, []);

  useEffect(() => {
    setOpen(!screens?.md);
  }, [screens]);

  useEffect(() => {
    if (threadId) {
      handleSingleThread(
        (threadId as string) ?? selectedContact?.id?.toString(),
      );

      if (usersList) {
        const filteredContacts = usersList.filter(
          (item) => item?.id?.toString() === threadId,
        );
        const selectedContact =
          filteredContacts.length > 0 ? filteredContacts[0] : null;
        setSelectedContact(selectedContact ?? null);
      }
    }
  }, [selectedContact, threadId, usersList]);

  const truncateTextByCharacter = (text: string, charLimit = 30): string => {
    if (text.length <= charLimit) return text;
    return text.slice(0, charLimit) + '...';
  };

  const triggerViewEvent = (threadId: number, authorId: number) => {
    const eventParams: EventDetails = {
      event_name: 'screen_view',
      source: 'message',
      source_type: 'threads',
      source_id: threadId.toString(),
      sub_source: 'author',
      sub_source_id: authorId?.toString() as string,
      unit: 'boolean',
      value: 1,
    };

    logEvent({
      eventDetails: eventParams,
      userData: userData,
      pathname: router.pathname,
    });
  };

  const triggerSessionEvent = (
    previousThreadId: number,
    previousAuthorId: number,
    start: Date,
    end: Date,
  ) => {
    const sessionDuration = (end.getTime() - start.getTime()) / 1000;

    const eventParams: EventDetails = {
      event_name: 'screen_session',
      source: 'message',
      source_type: 'threads',
      source_id: previousThreadId?.toString(),
      sub_source: 'author',
      sub_source_id: previousAuthorId?.toString() as string,
      unit: 'seconds',
      value: sessionDuration,
      activity_start: moment(start).format('YYYY-MM-DD HH:mm:ss'),
      activity_end: moment(end).format('YYYY-MM-DD HH:mm:ss'),
    };

    logEvent({
      eventDetails: eventParams,
      userData: userData,
      pathname: router.pathname,
    });
    setStartTime(null);
  };

  return (
    <>
      <ShouldRender check={loading}>
        <div className='flex justify-center  py-20'>
          <CustomLoader />
        </div>
      </ShouldRender>
      <ShouldRender check={!loading}>
        <div className='flex  w-full'>
          <ShouldRender check={screens?.md}>
            <div className='md:w-2/6 xl:w-1/4 my-2 lg:my-4  border-r-2 border-cartBgColor bg-readerLightGrayBg '>
              <div
                className={clsx(
                  !usersList?.length && 'h-full',
                  'flex flex-col ',
                )}
              >
                <div
                  className={` flex justify-between sticky top-0 border-b border-cartBgColor bg-readerLightGrayBg z-20 hover:text-[#94969E] px-6 py-3.5 lg:py-4 cursor-pointer text-white `}
                  onClick={() => router.back()}
                >
                  <span className='flex items-center gap-x-2  justify-center py-2'>
                    <Typography.Title level={1}>
                      <ChevronLeftIcon className='w-5 h-5' />
                    </Typography.Title>{' '}
                    <p>Back</p>
                  </span>
                </div>
                <ShouldRender check={usersList?.length}>
                  <div className='h-screen overflow-y-auto pb-36'>
                    {usersList?.map((contact) => (
                      <div
                        className={`${
                          selectedContact?.id === contact?.id &&
                          'bg-messageSelectedBg'
                        } flex flex-row gap-y-2 w-full border-dropDownMenuBg border-y justify-between hover:bg-cartBgColor z-10 px-4 lg:px-6 py-4 cursor-pointer text-white`}
                        key={contact.id}
                        onClick={() => {
                          const currentTime = new Date();

                          if (
                            previousContactId &&
                            previousContactId !== contact.id
                          ) {
                            triggerSessionEvent(
                              previousContactId,
                              previousAuthorId as number,
                              startTime as Date,
                              currentTime,
                            );
                          }

                          triggerViewEvent(
                            contact.id,
                            contact.author_id as number,
                          );

                          setPreviousContactId(contact.id);
                          setPreviousAuthorId(contact.author_id as number);
                          setStartTime(currentTime);

                          const updatedUsersList = usersList.map((user) =>
                            user.id === contact.id
                              ? { ...user, un_read_count: 0 }
                              : user,
                          );
                          setUsersList(updatedUsersList);

                          router.push(`?id=${contact.id}`, undefined, {
                            shallow: true,
                          });
                        }}
                      >
                        <span className='flex items-center w-full gap-x-3 justify-between'>
                          <span className='md:w-8 lg:w-10 lg:h-10 xl:w-fit md:h-8 xl:h-fit'>
                            <Avatar
                              size={screens.lg ? 40 : 35}
                              alt='profile-picture'
                              style={{ fontSize: '16px' }}
                              className={`${
                                selectedContact?.id !== contact?.id
                                  ? 'bg-cartBgColor '
                                  : ''
                              }`}
                            >
                              {contact?.name ? (
                                getUserInitials(contact?.name)
                              ) : (
                                <UserOutlined />
                              )}
                            </Avatar>
                          </span>
                          <div className='flex flex-col  w-full gap-1.5'>
                            <ShouldRender check={contact?.follower_count > 200}>
                              <p
                                className={clsx(
                                  selectedContact?.id !== contact?.id
                                    ? 'bg-messageSelectedBg'
                                    : 'bg-[#3C3C3C]',
                                  ' w-fit rounded-full text-xs px-3 inline-flex py-1',
                                )}
                              >
                                {formatFollowerCount(contact?.follower_count)}{' '}
                                Learners
                              </p>
                            </ShouldRender>
                            <div className='flex w-full gap-x-3'>
                              <div className=' flex flex-col w-full  '>
                                <div className='flex gap-x-4 justify-between w-full'>
                                  <Typography.Title
                                    level={2}
                                    className='font-semibold text-sm cursor-pointer capitalize'
                                  >
                                    {contact.name}
                                  </Typography.Title>
                                </div>
                                <Typography.Title
                                  level={3}
                                  className='font-medium  text-xs text-[#9F9EA6] cursor-pointer capitalize break-all'
                                >
                                  {truncateTextByCharacter(
                                    contact.last_message_info,
                                    60,
                                  )}
                                </Typography.Title>
                              </div>

                              <span className='flex gap-y-2 flex-col-reverse justify-start items-start h-full relative'>
                                <span className='flex w-full justify-end'>
                                  <ShouldRender check={contact?.un_read_count}>
                                    <p className='bg-primary rounded-full p-1 text-[10px] h-5 w-5 flex justify-center items-center'>
                                      {contact?.un_read_count}
                                    </p>
                                  </ShouldRender>
                                </span>

                                <span className='text-xs flex  whitespace-nowrap  justify-end text-white'>
                                  {moment
                                    .utc(contact?.updated_at)
                                    .format('Do MMM')}
                                </span>
                              </span>
                            </div>
                          </div>
                        </span>
                      </div>
                    ))}
                  </div>
                </ShouldRender>
                <ShouldRender check={!usersList?.length}>
                  <div className='h-full text-fadedWhite flex-col flex justify-center items-center'>
                    <EmptyIconWrite />
                    <span className='flex justify-center text-sm -translate-x-3'>
                      No Author Yet
                    </span>
                  </div>
                </ShouldRender>
              </div>
            </div>
          </ShouldRender>

          <div className='flex-1 py-2 lg:py-4 md:w-4/6 xl:w-3/4 h-screen no-scrollbar'>
            {selectedContact ? (
              <div className='shadow-md w-full overflow-y-auto h-screen  flex  flex-col'>
                <span className='sticky w-full z-20 top-0 bg-readerLightGrayBg px-2 md:px-8 py-2.5 border-cartBgColor border-b-2 '>
                  <span className='flex justify-between'>
                    <span className='flex text-white gap-x-3 items-center'>
                      <Avatar
                        size={screens.lg ? 55 : 45}
                        alt='profile-picture'
                        className='bg-cartBgColor'
                      >
                        {selectedContact?.name ? (
                          getUserInitials(selectedContact?.name)
                        ) : (
                          <UserOutlined />
                        )}
                      </Avatar>
                      <div className='flex flex-col'>
                        <span className='leading-5 inline-flex gap-x-2  items-center'>
                          <p className='font-medium text-base capitalize'>
                            {truncateTextByCharacter(
                              selectedContact?.name,
                              screens?.xs ? 12 : 30,
                            )}
                          </p>
                          <p className='text-2xl text-[#8f929d]'>•</p>
                          <p className='text-sm flex items-end text-[#94969E] mt-[2px]'>
                            Author
                          </p>
                        </span>
                        <ShouldRender
                          check={selectedContact?.follower_count > 200}
                        >
                          <span className='text-sm text-[#94969E]'>
                            {formatFollowerCount(
                              selectedContact?.follower_count,
                            )}{' '}
                            Learners
                          </span>
                        </ShouldRender>
                      </div>
                    </span>

                    <span className='flex items-center'>
                      {/* <Dropdown
                    overlay={dropdownMenu}
                    placement='bottomRight'
                    trigger={['click']}
                    open={showDropDown}
                    onOpenChange={(visible) => setShowDropDown(visible)}
                  >
                    <div
                      ref={dropdownRef}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowDropDown(!showDropDown);
                      }}
                    >
                      <DotsVerticalIcon
                        width={26}
                        height={26}
                        className='text-gray-200 cursor-pointer'
                      />
                    </div>
                  </Dropdown> */}
                      <ShouldRender check={!screens.md}>
                        <span
                          onClick={() => setOpen(!open)}
                          className='flex items-center w-10 h-10  md:ml-4 hover:cursor-pointer text-fadedWhite'
                        >
                          {open ? (
                            <BackIcon className='cursor-pointer pr-2' />
                          ) : (
                            <BackIcon className='cursor-pointer rotate-180   pr-2' />
                          )}
                        </span>
                      </ShouldRender>
                    </span>
                  </span>
                </span>
                <span
                  ref={containerRef}
                  style={{
                    overflowY: 'scroll',
                    WebkitOverflowScrolling: 'touch',
                  }}
                  className='p-4 z-10 gap-y-4 w-full h-screen overflow-y-auto pb-36 md:pb-20 flex chatBackgroundImage  flex-col   '
                >
                  <ShouldRender check={!loadingThread}>
                    {groupedMessages?.map((group) => (
                      <div className='' key={group.date}>
                        <div className='flex  text-sm py-4 justify-center '>
                          <span className='w-auto text-[#5F5F5F] bg-[#121212] px-3 py-1.5 rounded-md'>
                            {group.date}
                          </span>
                        </div>

                        <div className='flex gap-4 flex-col'>
                          {group?.messages?.map((message, index) => (
                            <Message
                              key={`${message.sender.id}-${index}`}
                              isSender={message.sender.id === userData?.id}
                              showSenderName={true}
                              text={message.messages}
                              user={message.sender}
                              createdAt={message.createdAt}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                  </ShouldRender>

                  <ShouldRender check={loadingThread}>
                    <div className='flex justify-center  py-20'>
                      <div
                        className={clsx(
                          ' w-full h-full flex items-center bg-transparent justify-center z-50 overflow-hidden',
                        )}
                      >
                        <Image
                          src='/Book_2_White_Loader.gif'
                          width={120}
                          height={120}
                          className=' py-24'
                          alt='Loading'
                        />
                      </div>
                    </div>
                  </ShouldRender>
                </span>
              </div>
            ) : (
              <div className='flex flex-col h-screen chatBackgroundImage'>
                <ShouldRender check={!screens.md}>
                  <span className='sticky w-full z-20 top-0 bg-bgColor px-2 md:px-8 py-2.5 border-cartBgColor border-b '>
                    <span className='flex justify-end'>
                      <span className='flex items-center'>
                        {/* <Dropdown
                    overlay={dropdownMenu}
                    placement='bottomRight'
                    trigger={['click']}
                    open={showDropDown}
                    onOpenChange={(visible) => setShowDropDown(visible)}
                  >
                    <div
                      ref={dropdownRef}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowDropDown(!showDropDown);
                      }}
                    >
                      <DotsVerticalIcon
                        width={26}
                        height={26}
                        className='text-gray-200 cursor-pointer'
                      />
                    </div>
                  </Dropdown> */}
                        <ShouldRender check={!screens.md}>
                          <span
                            onClick={() => setOpen(!open)}
                            className='flex items-center w-10 h-10 md:ml-4 hover:cursor-pointer text-fadedWhite'
                          >
                            {open ? (
                              <BackIcon className='cursor-pointer pr-2' />
                            ) : (
                              <BackIcon className='cursor-pointer rotate-180 pr-2' />
                            )}
                          </span>
                        </ShouldRender>
                      </span>
                    </span>
                  </span>
                </ShouldRender>

                <span className='flex h-full mt-5 md:mt-0 md:items-center justify-center p-5 md:p-10'>
                  <div className='flex flex-col md:gap-y-6 pt-12 min-w-[150px] xl:min-w-[550px] text-fadedWhite h-4/6 md:h-5/6 relative rounded-2xl  bg-readerLightGrayBg '>
                    <div className='flex flex-col justify-center gap-y-2 px-5'>
                      <span className='text-lg xs:text-xl md:text-2xl text-center font-medium'>
                        Talk to your favorite Authors{' '}
                      </span>

                      <div className='flex justify-center'>
                        <span className='lg:w-2/3 text-center'>
                          Authors will send you messages, updates and news. you
                          will receive notification.
                        </span>
                      </div>
                    </div>

                    <div className='w-full flex justify-center translate-y-14 xs:translate-y-10 md:translate-y-16 lg:translate-y-14'>
                      <Image
                        width={500}
                        height={320}
                        src={EmptyScreen}
                        alt='EmptyScreen'
                      />
                    </div>
                  </div>
                </span>
              </div>
            )}
          </div>
          <Drawer
            className='drawerPaddingNone drawerPaddingNoScrollBar  no-scrollbar'
            placement='right'
            closeIcon={false}
            onClose={onClose}
            open={open}
            width={450}
          >
            <div
              className={clsx(
                !usersList?.length && 'h-full',
                'flex flex-col no-scrollbar',
              )}
            >
              <div
                className={` flex justify-between sticky no-scrollbar top-0 bg-bgColor z-20 hover:text-[#94969E] px-4 lg:px-6 py-4 cursor-pointer text-white `}
              >
                <span
                  onClick={() => {
                    onClose();
                  }}
                  className='flex items-center gap-x-2  justify-center py-2'
                >
                  <p>
                    <ChevronLeftIcon className='w-5 h-5' />
                  </p>{' '}
                  <p>Back</p>
                </span>
              </div>
              <ShouldRender check={usersList?.length}>
                <div className='no-scrollbar'>
                  {usersList?.map((contact) => (
                    <div
                      className={`${
                        selectedContact?.id === contact?.id &&
                        'bg-messageSelectedBg'
                      } flex flex-row gap-y-2 w-full border-dropDownMenuBg border-y justify-between hover:bg-cartBgColor z-10 px-4 lg:px-6 py-4 cursor-pointer text-white`}
                      key={contact.id}
                      onClick={() => {
                        const currentTime = new Date();

                        if (
                          previousContactId &&
                          previousContactId !== contact.id
                        ) {
                          triggerSessionEvent(
                            previousContactId,
                            previousAuthorId as number,
                            startTime as Date,
                            currentTime,
                          );
                        }

                        triggerViewEvent(
                          contact.id,
                          contact.author_id as number,
                        );

                        setPreviousContactId(contact.id);
                        setPreviousAuthorId(contact.author_id as number);
                        setStartTime(currentTime);

                        const updatedUsersList = usersList.map((user) =>
                          user.id === contact.id
                            ? { ...user, un_read_count: 0 }
                            : user,
                        );
                        setUsersList(updatedUsersList);

                        router.push(`?id=${contact.id}`, undefined, {
                          shallow: true,
                        });
                      }}
                    >
                      <span className='flex items-center w-full gap-x-3 justify-between'>
                        <span className='md:w-8 lg:w-10 lg:h-10 xl:w-fit md:h-8 xl:h-fit'>
                          <Avatar
                            size={screens.lg ? 40 : 35}
                            alt='profile-picture'
                            style={{ fontSize: '16px' }}
                            className={`${
                              selectedContact?.id !== contact?.id
                                ? 'bg-cartBgColor '
                                : ''
                            }`}
                          >
                            {contact?.name ? (
                              getUserInitials(contact?.name)
                            ) : (
                              <UserOutlined />
                            )}
                          </Avatar>
                        </span>
                        <div className='flex flex-col  w-full gap-1.5'>
                          <ShouldRender check={contact?.follower_count > 200}>
                            <p
                              className={clsx(
                                selectedContact?.id !== contact?.id
                                  ? 'bg-messageSelectedBg'
                                  : 'bg-[#3C3C3C]',
                                ' w-fit rounded-full text-xs px-3 inline-flex py-1',
                              )}
                            >
                              {formatFollowerCount(contact?.follower_count)}{' '}
                              Learners
                            </p>
                          </ShouldRender>
                          <div className='flex w-full gap-x-3'>
                            <div className=' flex flex-col w-full  '>
                              <div className='flex gap-x-4 justify-between w-full'>
                                <p className='font-semibold text-sm cursor-pointer capitalize'>
                                  {contact.name}
                                </p>
                              </div>
                              <div className='font-medium  text-xs text-[#9F9EA6] cursor-pointer capitalize break-all'>
                                {truncateTextByCharacter(
                                  contact.last_message_info,
                                  60,
                                )}
                              </div>
                            </div>

                            <span className='flex gap-y-2 flex-col-reverse justify-start items-start h-full relative'>
                              <span className='flex w-full justify-end'>
                                <ShouldRender check={contact?.un_read_count}>
                                  <p className='bg-primary rounded-full p-1 text-[10px] h-5 w-5 flex justify-center items-center'>
                                    {contact?.un_read_count}
                                  </p>
                                </ShouldRender>
                              </span>

                              <span className='text-xs flex  whitespace-nowrap  justify-end text-white'>
                                {moment
                                  .utc(contact?.updated_at)
                                  .format('Do MMM')}
                              </span>
                            </span>
                          </div>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>
              </ShouldRender>

              <ShouldRender check={!usersList?.length}>
                <div className='h-full text-fadedWhite flex-col flex justify-center items-center'>
                  <EmptyIconWrite />
                  <span className='flex justify-center text-sm -translate-x-3'>
                    No Author Yet
                  </span>
                </div>
              </ShouldRender>
            </div>
          </Drawer>
        </div>
      </ShouldRender>
    </>
  );
};

export default ChatBox;
