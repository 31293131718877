import React from 'react';

const PlayIconCourse: React.FC = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.4697 22C17.9926 22 22.4697 17.5228 22.4697 12C22.4697 6.47715 17.9926 2 12.4697 2C6.94688 2 2.46973 6.47715 2.46973 12C2.46973 17.5228 6.94688 22 12.4697 22Z'
        fill='black'
        fillOpacity='0.4'
        stroke='white'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.9252 12.25L10.5658 16.4896C10.5206 16.5196 10.4681 16.5369 10.4139 16.5395C10.3597 16.5421 10.3058 16.5299 10.258 16.5043C10.2102 16.4787 10.1702 16.4406 10.1423 16.394C10.1144 16.3475 10.0996 16.2942 10.0996 16.24V7.76078C10.0996 7.70652 10.1144 7.65328 10.1423 7.60673C10.1702 7.56018 10.2102 7.52207 10.258 7.49646C10.3058 7.47084 10.3597 7.45869 10.4139 7.46128C10.4681 7.46387 10.5206 7.48112 10.5658 7.51118L16.9252 11.7508C16.9663 11.7782 17 11.8153 17.0233 11.8588C17.0466 11.9024 17.0588 11.951 17.0588 12.0004C17.0588 12.0498 17.0466 12.0984 17.0233 12.1419C17 12.1855 16.9663 12.2226 16.9252 12.25Z'
        fill='white'
      />
    </svg>
  );
};

export default PlayIconCourse;
