import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import ButtonDefault, { ButtonVariants } from '../shared/basic/button';
import { ShouldRender } from '../shared/basic/ShouldRender';
import { trackConversion } from '../shared/Gtags';
import CustomLoader from '../shared/Loading';
import { getOrderDetails } from '../../services/Cart';
import {
  FormattedFinalOrder,
  RawOrderData,
} from '../../utils/interfaces/Orders';

export type Category =
  | 'Main'
  | 'email course'
  | 'Recorded Course'
  | 'Book'
  | 'Bundle'
  | 'E-Book'
  | 'Distribution'
  | 'Live Instructor Program'
  | 'Uncategorised'
  | 'Hardcover';
export type Type = 'digital' | 'hardcover';

export const formatItem = (category: Category, type: Type): string => {
  const normalizedCategory = category?.toLowerCase();
  const normalizedType = type?.toLowerCase();

  switch (normalizedCategory) {
    case 'book':
      return normalizedType === 'digital' ? 'E-Book' : 'Book';
    case 'bundle':
      return 'Bundle';
    case 'recorded course':
      return 'Course';
    default:
      return `${category} (${type})`;
  }
};

interface Props {
  amount: {
    subTotal: number;
    discount: string;
    total: number;
    tax: number;
    couponCode: { coupon_code: string }[];
  };
}

const Step_3_orderCard: React.FC<Props> = () => {
  const router = useRouter();
  const screens = useBreakpoint();
  const [order, setOrder] = useState<FormattedFinalOrder>();
  const [loadingOrder, setLoadingOrder] = useState(false);
  const { orderId } = router.query;

  const formatOrderDetails = (
    data: RawOrderData | null,
  ): FormattedFinalOrder | null => {
    if (!data) {
      return null;
    }

    const formattedOrder: FormattedFinalOrder = {
      orderId: data.id.toString(),
      transactionId: data.payment_details?.transaction_id || '',
      grand_total: data.grand_total || 0,
      currency: data?.currency_symbol || '',
      payment_type: data?.payment_details?.method,
      orderDetails: data.order_details.map((item) => ({
        id: item.id.toString(),
        name: item.product.name,
        quantity: item.quantity,
        product_id: item.product_id,
        price: item.product.main_price,
        image: item.product.thumbnail_image,
        currency_symbol: item?.product?.currency_symbol,
        book_type: item?.product?.book_type,
        actualPrice: item?.product?.stroked_price,
      })),
    };

    return formattedOrder;
  };

  const handleFinalCartDetails = async (id: string) => {
    try {
      setLoadingOrder(true);
      if (id) {
        const data = await getOrderDetails(id);

        const formattedData = formatOrderDetails(data);

        setOrder(formattedData as FormattedFinalOrder);
      }
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    } finally {
      setLoadingOrder(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      handleFinalCartDetails(orderId as string);
    }
  }, [orderId]);

  useEffect(() => {
    if (order) {
      trackConversion({
        currency: 'INR',
        send_to: `${process.env.NEXT_PUBLIC_GOOGLE_ID_ADD}/${order?.orderId}`,
        value: order?.grand_total as number,
        transaction_id: order?.orderId || '',
      });
    }
  }, [order]);

  return (
    <>
      <div className='py-6 text-fadedWhite px-4 xl:px-8 w-full  flex flex-col gap-y-2  rounded-md cardShadow mt-4'>
        <div className='flex flex-col gap-y-5'>
          <span className='text-xl font-medium'>ORDER SUMMARY</span>
        </div>
        <div className='flex flex-col gap-y-5 pt-3 pb-10'>
          <div className=' customScrollBar flex flex-col gap-y-2'>
            {order?.orderDetails?.map((item) => {
              return (
                <div key={item.id} className='flex  gap-5'>
                  <div className='w-20 p-1 bg-imageCardBgColorCart rounded-md'>
                    <span>
                      <Image
                        src={item.image}
                        alt='Product'
                        width={200}
                        height={200}
                        className='object-contain w-full h-full'
                      />
                    </span>
                  </div>
                  <div className='flex justify-between gap-x-2 w-full'>
                    <div className='flex flex-col gap-y-1'>
                      <div>
                        <div className='flex  text-textGray font-medium text-xs  gap-2'>
                          <span className=''>Qty. </span>
                          <span className=''>0{item.quantity}</span>
                        </div>
                      </div>
                      <span className='text-sm md:text-base'>{item.name}</span>
                      <span className='font-bold text-xs'>
                        {formatItem(
                          item?.category as Category,
                          item.book_type as Type,
                        )?.toUpperCase()}
                      </span>
                    </div>
                    <div className='font-medium whitespace-nowrap'>
                      {item?.currency_symbol} {item.price}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className='border border-cartBgColor ' />
          <div className='flex justify-between gap-x-4'>
            <div className='whitespace-nowrap'>Order number</div>
            <b>{order?.orderId}</b>
          </div>
          <div className='border border-cartBgColor' />
          <div className='flex justify-between gap-x-4'>
            <div className='whitespace-nowrap'>Order total</div>
            <b>
              {order?.currency} {order?.grand_total}
            </b>
          </div>

          <div
            className={clsx(!screens.lg && 'fixed bottom-0 left-0', 'w-full')}
          >
            <div className='bg-bgColor bg-opacity-75 p-4 lg:p-0  backdrop-blur-sm flex'>
              <ButtonDefault
                size={4}
                rounded={true}
                onClick={() => router.push('/')}
                variant={ButtonVariants.WHITE}
                className='w-full text-center text-sm  p-3'
              >
                <span className='text-bold text-black py-1 w-full  font-bold'>
                  Go to Home
                </span>
              </ButtonDefault>
            </div>
            {/* <div className='absolute bottom-28 right-0 w-max'> */}

            {/* </div> */}
          </div>
        </div>
      </div>
      <ShouldRender check={loadingOrder}>
        <div className='flex justify-center  py-40'>
          <CustomLoader />
        </div>
      </ShouldRender>
    </>
  );
};

export default Step_3_orderCard;
