import { ChevronRightIcon } from '@heroicons/react/outline';
import { ArrowUpIcon } from '@heroicons/react/solid';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { ShouldRender } from '../shared/basic/ShouldRender';
import { FooterProps } from '../../utils/interfaces';
import { LogoWithoutSquare } from '../../../assets';

const Footer: React.FC<FooterProps> = ({ navigation }) => {
  const screens = useBreakpoint();
  const handleScrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };
  return (
    <footer className='bg-black' aria-labelledby='footer-heading'>
      <h2 id='footer-heading' className='sr-only'>
        Footer
      </h2>
      <div className='mx-auto  w-full px-6 pb-8 pt-16 lg:pt-24 lg:px-8 xl:px-12  xl:pt-32'>
        <div className='flex  flex-col-reverse  '>
          <ShouldRender check={!screens.md}>
            <div className='text-9xl bg-wh'>
              <Image
                src={LogoWithoutSquare}
                alt='LogoWithoutSquare'
                layout='responsive'
                width={1334}
                height={134}
              />
            </div>
          </ShouldRender>

          <div className='space-y-8 border-y border-[#3C3B3E] grid grid-cols-2 md:grid-cols-3 items-center md:h-fit xl:h-full my-10 md:mt-20 py-10'>
            {/* <Image
              src={ZebraLearnFooterLogo}
              width={170}
              height={170}
              alt='FooterImg'
            /> */}
            {/* <p className='text-sm leading-6 text-gray-400'>
              Making the world a better place through constructing elegant
              hierarchies.
            </p> */}
            <div className='flex flex-col gap-2 '>
              <ShouldRender check={!screens.md}>
                <span className='text-lg  font-semibold pb-6  leading-6 text-white'>
                  Follow us on
                </span>
              </ShouldRender>

              <div className='flex space-x-6 '>
                {navigation.social.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-white hover:text-gray-200'
                  >
                    <div className='h-5 w-5  text-2xl leading-5 flex items-center'>
                      <item.icon />
                    </div>
                  </a>
                ))}
              </div>
            </div>
            <ShouldRender check={screens.md}>
              <div className='text-xl flex h-max items-center justify-center mx-auto -translate-y-3.5 translate-x-[15%] lg:translate-x-00120  max-w-[240px]'>
                <Image
                  src={LogoWithoutSquare}
                  alt='LogoWithoutSquare'
                  layout='responsive'
                  width={240}
                  height={18}
                />
              </div>
            </ShouldRender>
            <div className='flex justify-end'>
              <div
                className='bg-dropDownMenuBg w-min p-4 -translate-y-2 md:-translate-y-4  rounded-full cursor-pointer'
                onClick={handleScrollToTop}
              >
                <ArrowUpIcon className='w-5 h-5 text-white animate-bounce' />
              </div>
            </div>
          </div>
          <div className='grid grid-col-2 gap-y-5 md:gap-y-0 xl:col-span-2'>
            <div className='grid  grid-cols-2  md:grid-cols-4 gap-6 justify-between md:gap-8'>
              <div>
                <h3 className='text-base whitespace-nowrap font-semibold leading-6 text-white'>
                  For Traders
                </h3>
                <ul role='list' className='mt-2 md:mt-4 space-y-2'>
                  {navigation.for_Traders.map((item, index) => (
                    <li key={`${item.name + index}`}>
                      <a
                        href={item.href}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-sm leading-6 text-gray-400 hover:text-white'
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className='text-base whitespace-nowrap font-semibold leading-6 text-white'>
                  For Investors
                </h3>
                <ul role='list' className='mt-2 md:mt-4  space-y-2'>
                  {navigation.for_Investors.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-sm leading-6 text-gray-400 hover:text-white'
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className='text-base md:whitespace-nowrap font-semibold leading-6 text-white'>
                  For Finance Students
                </h3>
                <ul role='list' className='mt-2 md:mt-4  space-y-2'>
                  {navigation.for_Finance_Students.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-sm leading-6 text-gray-400 hover:text-white'
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className='text-base md:whitespace-nowrap font-semibold leading-6 text-white'>
                  For Entrepreneurs
                </h3>
                <ul role='list' className='mt-2 md:mt-4  space-y-2'>
                  {navigation.for_Entrepreneurs.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-sm leading-6 text-gray-400 hover:text-white'
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className='text-base whitespace-nowrap font-semibold leading-6 text-white'>
                  For Authors
                </h3>
                <ul role='list' className='mt-2 md:mt-4  space-y-2'>
                  {navigation.for_Authors.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-sm leading-6 text-gray-400 hover:text-white'
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className='text-base whitespace-nowrap font-semibold leading-6 text-white'>
                  Business Books
                </h3>
                <ul role='list' className='mt-2 md:mt-4  space-y-2'>
                  {navigation.business_Books.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-sm leading-6 text-gray-400 hover:text-white'
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='md:mt-0'>
                <h3 className='text-base whitespace-nowrap font-semibold leading-6 text-white'>
                  Quick Links
                </h3>
                <ul role='list' className='mt-2 md:mt-4 space-y-2'>
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-sm leading-6 text-gray-400 hover:text-white'
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className='text-base whitespace-nowrap font-semibold leading-6 text-white'>
                  Company
                </h3>
                <ul role='list' className='mt-2 md:mt-4 space-y-2'>
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-sm leading-6 text-gray-400 hover:text-white'
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='md:grid md:grid-cols-2 mt-5 md:mt-0 md:gap-8'>
              <Link href='/product'>
                <div className='flex w-fit justify-center gap-x-2.5 text-white md:-translate-y-20 md:text-lg font-semibold items-center'>
                  Shop Products{' '}
                  <ChevronRightIcon className='w-4 md:w-5 md:h-5 h-4' />
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className='mt-10 md:mt-0  border-t md:border-none border-white/10 pt-8 md:pt-0 sm:mt-20 '>
          <div className='flex justify-between flex-col md:flex-row gap-y-2.5'>
            <p className='text-xs leading-5 text-center tracking-wide text-white'>
              &copy; 2025 Zebra Learn. All rights reserved.
            </p>
            <span className='flex gap-x-5 text-xs md:text-sm justify-center  text-white'>
              <p
                style={{ textUnderlineOffset: '2px' }}
                className='uppercase  underline cursor-pointer'
              >
                Terms & Conditions
              </p>
              <Link
                href='/Privacy_Policy_Reader.html'
                target='_blank'
                rel='noopener noreferrer'
                className='uppercase text-xs md:text-sm  hover:text-white decoration-current underline cursor-pointer'
                style={{ textUnderlineOffset: '2px' }}
              >
                Privacy Policy
              </Link>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
