import { XIcon } from '@heroicons/react/outline';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import { Avatar, Drawer, Input, Skeleton } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import moment from 'moment';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import ScrollableFeed from './ScrollableFeed';
import { AiChatMessage } from '../../ChatBox/AiChatMessage';
import { Message } from '../../ChatBox/Message';
import ParserV2 from '../../shared/basic/Parser/ParserV2';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { formateErrorObject } from '../../shared/formateErrorObject';
import { notify } from '../../shared/notify';
import Logo from '../../../../assets/Icons/Logo';
import { user } from '../../../atoms/user';
import { EventDetails, logEvent } from '../../../hooks/useClickEventTracking';
import { Endpoints } from '../../../network';
import { askQuestion } from '../../../services/AiChat';
import { User } from '../../../utils/interfaces/User';

interface ModalComponentProps {
  visible: boolean;
  onCancel: () => void;
  isClosable?: boolean;
  bookData: {
    name: string;
    image: string;
  };
  isFullScreen: boolean;
}

interface Message {
  sender: { id: number; name?: string };
  messages: string;
  session_id?: string;
  createdAt?: string;
}

const AiChatDrawer: React.FC<ModalComponentProps> = ({
  visible,
  onCancel,
  bookData,
  isFullScreen,
}) => {
  const [height, setHeight] = useState<number>(0);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [prompt, setPrompt] = useState('');
  const [chadProcessing, setChadProcessing] = useState(false);
  const userData: User | undefined = useRecoilValue(user);
  const router = useRouter();
  const baseUrl = process.env.NEXT_PUBLIC_API_READER_URL;
  const { book_id, bookId } = router.query;

  const parsedBookId = bookId ? (bookId as string).split('-').pop() || '' : '';

  const containerRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const screens = useBreakpoint();

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        const adjustedHeight = window.innerHeight - 158;
        setHeight(adjustedHeight);
      }
    };

    const handleScroll = () => {
      if (typeof window !== 'undefined') {
        const scrolledAmount = window.scrollY;
        const adjustedHeight = window.innerHeight - 140;
        setHeight(adjustedHeight);
        setScrolled(scrolledAmount > 0);
      }
    };

    handleResize();

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const wordVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
  };

  const skeletonLines = [
    ['w-20 md:w-32', 'hidden md:w-24', 'w-full'],
    ['w-full', 'w-full', 'w-24'],
    ['w-full', 'w-20', 'w-full'],
    ['w-full', 'w-full', 'w-24'],
    ['md:w-32', 'w-24', 'hidden md:w-full'],
  ];

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, visible]);

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleAskQuestion();
    }
  };

  const handleAskQuestion = async () => {
    try {
      const lastSessionId =
        messages.length > 0 ? messages[messages.length - 1]?.session_id : '';

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: { id: 1 },
          messages: prompt,
          session_id: lastSessionId,
          createdAt: moment()?.toLocaleString(),
        },
      ]);
      const eventParams: EventDetails = {
        event_name: 'ai_query',
        source: 'ask_ai',
        source_type: 'book',
        source_id: (book_id ?? parsedBookId) as string,
        sub_source: null,
        sub_source_id: null,
        unit: 'boolean',
        value: 1,
        meta: {
          click_source: 'ask_ai_screen',
          ai_query: prompt,
        },
      };

      logEvent({
        eventDetails: eventParams,
        userData: userData,
        pathname: router.pathname,
      });
      setPrompt('');
      setChadProcessing(true);
      const data = await askQuestion({
        book_id: (book_id ?? parsedBookId) as string,
        question: prompt,
        ...(messages.length ? { session_id: lastSessionId } : {}),
      });

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: { id: 2, name: 'A' },
          messages: data.result,
          session_id: data.session_id || lastSessionId,
          createdAt: moment
            .utc(data?.created_at, 'ddd, DD MMM YYYY HH:mm:ss [GMT]')
            .local()
            .format('YYYY-MM-DD HH:mm:ss'),
        },
      ]);
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setChadProcessing(false);
    }
  };
  useEffect(() => {
    if (visible && !screens.lg) {
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.position = '';
      document.body.style.width = '';
    }

    return () => {
      document.body.style.position = '';
      document.body.style.width = '';
    };
  }, [visible, screens]);

  useEffect(() => {
    if (visible && textareaRef.current && !chadProcessing) {
      textareaRef.current.focus();
    }
  }, [visible, chadProcessing]);

  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (
        window.visualViewport &&
        window.visualViewport.height < window.innerHeight
      ) {
        setIsKeyboardOpen(true);
      } else {
        setIsKeyboardOpen(false);
      }
    };

    window.visualViewport?.addEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.visualViewport?.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isKeyboardOpen && inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [isKeyboardOpen]);

  const renderContent = (
    <div
      style={{
        transition: 'transform 0.3s ease, opacity 0.3s ease',
      }}
      className={`w-full  h-full lg:h-auto lg:p-3 font-lato font-semibold no-scrollbar text-white inline-flex items-center cursor-pointer  `}
    >
      <div
        style={{
          height: screens.md ? `${height}px` : '100%',
          transition: 'height 0.3s ease-in-out',
        }}
        className={clsx(
          screens.lg ? `lg:h-[${height}]px` : 'h-full',
          `  overflow-hidden`,
          'w-full ',
        )}
      >
        <div className='flex-1 h-full no-scrollbar'>
          <div className='shadow-md w-full overflow-y-auto lg:rounded-b-[20px] h-full flex  flex-col'>
            <span className='sticky w-full z-20 top-0 bg-[#1E1E1F] lg:rounded-t-[20px] px-8 py-3 border-cartBgColor border-b-2 '>
              <span className='flex justify-between'>
                <div className='flex items-centers gap-x-[14px] h-full'>
                  <div className=' translate-y-1'>
                    <Image
                      width={24}
                      height={40}
                      alt='BookImage'
                      src={`${baseUrl}/${Endpoints.getCoverImagesForBooks}/${(book_id ?? parsedBookId) as string}`}
                      className='rounded-sm'
                    />
                  </div>
                  <div className='flex  justify-start flex-col  h-full'>
                    <div className='text-start flex justify-start'>Ask AI</div>

                    <div className='text-start text-xs md:text-sm text-textDarkGrayColor flex justify-start'>
                      <ParserV2
                        isPopOver={false}
                        text={bookData?.name}
                        maxWords={!screens.xl ? 4 : 8}
                      />
                    </div>
                  </div>
                </div>

                <span className='flex items-center'>
                  <span
                    onClick={() => onCancel()}
                    className=' rounded-full p-1.5 cursor-pointer border border-[#2D2D2D]  flex justify-center items-center'
                  >
                    <XIcon className='w-4 h-4  text-[#787878] cursor-pointer' />
                  </span>
                </span>
              </span>
            </span>
            <ShouldRender check={!messages.length}>
              <div className='bg-[#0A0A0A] overflow-hidden relative AiChatBgImage'>
                <div className='flex flex-col h-full items-center  gap-y-1 '>
                  <div className=' flex justify-center w-full translate-y-1 mt-10'>
                    <Image
                      width={100}
                      height={300}
                      alt='BookImage'
                      src={`${baseUrl}/${Endpoints.getCoverImagesForBooks}/${(book_id ?? parsedBookId) as string}`}
                      className='z-50 rounded-sm'
                    />
                  </div>
                  <div className='text-sm mt-4 w-5/6 md:w-full text-center'>
                    {bookData?.name}
                  </div>
                  <div className='text-textDarkGrayColor text-xs text-center w-4/6 md:w-1/2'>
                    All answers generated by this AI come directly from the
                    book, without using any external information.
                  </div>
                </div>
                <div className='absolute w-full h-min px-5 bottom-1'>
                  <div className='flex items-center bg-[#272727] overflow-hidden shadow-lg rounded-full space-x-5 py-2  pl-3 md:pl-5 pr-2'>
                    <Input.TextArea
                      ref={textareaRef}
                      name='prompt'
                      value={prompt}
                      rows={1}
                      autoSize={{ minRows: 1, maxRows: 4 }}
                      onChange={(e) => setPrompt(e.target.value)}
                      className='bg-transparent w-full placeholder:text-[#737373] font-normal text-sm md:text-base disabled:cursor-not-allowed disabled:text-gray-300 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-700 scrollbar-track-rounded-lg'
                      placeholder={
                        screens.xl
                          ? `Ask Anything about ${bookData.name}`
                          : screens.xs
                            ? `Ask about ${bookData.name}`.substring(0, 18) +
                              '...'
                            : `Ask about ${bookData.name}`.substring(0, 25) +
                              '...'
                      }
                      onKeyDown={handleKeyDown}
                      style={{
                        background: 'transparent',
                        border: 'none',
                        boxShadow: 'none',
                        outline: 'none',
                        resize: 'none',
                      }}
                    />

                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleAskQuestion();
                      }}
                      disabled={!prompt || chadProcessing}
                      className={`bg-[#000000] ${
                        chadProcessing &&
                        'disabled:bg-[#11A37F] disabled:text-gray-300 disabled:cursor-not-allowed'
                      } text-white ${
                        prompt &&
                        'bg-[#0C6952] disabled:bg-gray-300 disabled:active:bg-gray-300 dark:disabled:bg-gray-900/10 dark:disabled:active:bg-gray-900/10 disabled:cursor-not-allowed disabled:hover:opacity-100'
                      } self-end font-bold w-10 p-2 flex justify-center items-center rounded-full h-8`}
                    >
                      <PaperAirplaneIcon className='h-4 w-4 rotate-90' />
                    </button>
                  </div>
                  <span className='text-[10px] text-center w-full py-2 flex justify-center text-[#737373]'>
                    While this AI is highly capable, inaccuracies may occur
                  </span>
                </div>
              </div>
            </ShouldRender>
            <ShouldRender check={messages.length}>
              <div
                className='flex flex-col overflow-hidden no-scrollbar bg-[#0A0A0A] AiChatBgImage '
                style={{ height: '100svh' }}
              >
                <span
                  ref={containerRef}
                  className='z-10 gap-y-4 w-full cursor-default no-scrollbar relative overflow-y-auto flex bg-[#0A0A0A]  AiChatBgImage  flex-col '
                >
                  {/* <ShouldRender check={!loadingThread}> */}
                  <ScrollableFeed
                    className='px-4 pb-4 pt-1 no-scrollbar'
                    loading={chadProcessing}
                  >
                    <div className='flex gap-4 flex-col'>
                      {messages?.map((message, index) => {
                        const isLastMessage = index === messages.length;
                        return (
                          <AiChatMessage
                            key={`${message.sender.id}-${index}`}
                            isSender={message.sender.id === 1}
                            showSenderName={true}
                            text={message.messages}
                            user={message?.sender?.name || undefined}
                            loading={isLastMessage && chadProcessing}
                            createdAt={message.createdAt}
                            bookName={bookData?.name}
                          />
                        );
                      })}
                      <ShouldRender check={chadProcessing}>
                        <div className='flex items-end gap-2 max-w-xs overflow-hidden md:mr-12'>
                          <Avatar
                            size={screens.md ? 40 : 30}
                            alt='profile-picture'
                            className='flex-shrink-0 select-none bg-[#393939] flex justify-center items-center'
                            draggable={false}
                            shape='circle'
                          >
                            <Logo />
                          </Avatar>
                          <div className='relative  max-w-full md:max-w-lg'>
                            {/* Ant Design Skeleton Button as background */}
                            <Skeleton.Button
                              active
                              size='large'
                              className='absolute top-0 left-0 w-full h-full'
                            />

                            {/* Custom Skeleton with word-by-word animation */}
                            <motion.div
                              role='status'
                              className='space-y-2 md:space-y-2.5 max-w-full md:max-w-lg relative p-4'
                              initial='hidden'
                              animate='visible'
                              variants={containerVariants}
                            >
                              {skeletonLines.map((lineWidths, index) => (
                                <motion.div
                                  key={index}
                                  className='flex items-center gap-x-2 w-full'
                                  style={{
                                    maxWidth: `${480 - index * 20}px`,
                                  }}
                                  variants={wordVariants}
                                >
                                  {lineWidths.map((width, i) => (
                                    <Skeleton.Button
                                      key={i}
                                      active
                                      className={`h-2.5  ${width}`}
                                    />
                                  ))}
                                </motion.div>
                              ))}
                            </motion.div>
                          </div>
                        </div>
                      </ShouldRender>
                    </div>
                  </ScrollableFeed>
                </span>
                <div className='w-full h-min px-5 '>
                  <div className='flex items-center bg-[#272727] overflow-hidden shadow-lg rounded-full space-x-5 py-2 pl-3 md:pl-5 pr-2'>
                    <Input.TextArea
                      ref={textareaRef}
                      name='prompt'
                      value={prompt}
                      rows={1}
                      autoSize={{ minRows: 1, maxRows: 4 }}
                      onChange={(e) => setPrompt(e.target.value)}
                      className='bg-transparent w-full placeholder:text-[#737373] font-normal text-sm md:text-base disabled:cursor-not-allowed disabled:text-gray-300 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-700 scrollbar-track-rounded-lg'
                      placeholder={
                        screens.xl
                          ? `Ask Anything about ${bookData.name}`
                          : screens.xs
                            ? `Ask about ${bookData.name}`.substring(0, 18) +
                              '...'
                            : `Ask about ${bookData.name}`.substring(0, 25) +
                              '...'
                      }
                      onKeyDown={handleKeyDown}
                      style={{
                        background: 'transparent',
                        border: 'none',
                        boxShadow: 'none',
                        outline: 'none',
                        resize: 'none',
                      }}
                    />

                    <button
                      className={`bg-[#000000] ${
                        chadProcessing &&
                        'disabled:bg-[#11A37F] disabled:cursor-not-allowed'
                      } text-white ${
                        prompt &&
                        'bg-[#0C6952] disabled:bg-gray-300 disabled:active:bg-gray-300 dark:disabled:bg-gray-900/10 dark:disabled:active:bg-gray-900/10 disabled:cursor-not-allowed disabled:hover:opacity-100'
                      } self-end font-bold w-10 p-2 flex justify-center items-center rounded-full h-8`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleAskQuestion();
                      }}
                      disabled={!prompt || chadProcessing}
                    >
                      <PaperAirplaneIcon className='h-4 w-4 rotate-90' />
                    </button>
                  </div>
                  <span className='text-[10px] text-center w-full py-2 flex justify-center text-[#737373]'>
                    While this AI is highly capable, inaccuracies may occur
                  </span>
                </div>
              </div>
            </ShouldRender>
          </div>
        </div>
      </div>
    </div>
  );

  const drawerProps = {
    placement: 'right' as const,
    open: visible,
    width: screens.lg ? '50%' : screens.xl ? '55%' : '100%',
    height: '100%',
    closable: false,
    onClose: onCancel,
    mask: false,
    footer: false,
    style: {
      boxShadow: 'none',
      backdropFilter: 'none',
      transition: 'transform 0.3s ease, opacity 0.3s ease',
      zIndex: -100,
    },
    className: `lg:custom-drawer -z-10 w-full lg:bg-transparent h-full selectable transform bg-blur-none ${
      visible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
    }`,
  };
  return (
    <Drawer
      {...drawerProps}
      {...(!screens.lg && { getContainer: undefined })}
      {...(isFullScreen && { getContainer: false })}
    >
      <div className='w-full h-full no-scrollbar overflow-hidden lg:parent-container'>
        {isFullScreen ? (
          renderContent
        ) : (
          <div
            className={clsx(
              !scrolled ? 'lg:translate-y-32 ' : 'lg:translate-y-16',
              'w-full h-full  overflow-hidden transition-transform duration-300 ease-in-out',
            )}
          >
            {renderContent}
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default AiChatDrawer;
