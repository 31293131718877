import {
  Affix,
  Button,
  Checkbox,
  ConfigProvider,
  Modal,
  Spin,
  ThemeConfig,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import PDFReaderIframe from '../../IFrame/PDFReaderIframe';
import ButtonDefault, { ButtonVariants } from '../../shared/basic/button';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { formateErrorObject } from '../../shared/formateErrorObject';
import { trackAddToCartEvent } from '../../shared/Gtags';
import { notify } from '../../shared/notify';
import SecurePayment from '../../../../assets/Icons/SecurePayment';
import { authModalControl } from '../../../atoms/authModalControl';
import { localUserID, user } from '../../../atoms/user';
import { EventDetails, logEvent } from '../../../hooks/useClickEventTracking';
import { addToCart, addToRecentlyViewed } from '../../../services/Cart';
import { AddToCartProps } from '../../../utils/interfaces/cart';
import { ISlidBook } from '../../../utils/interfaces/landingPage';
import { User } from '../../../utils/interfaces/User';

interface SlidBookContainerProps {
  slidBookContainerData: ISlidBook;
  token?: string;
}

const config: ThemeConfig = {
  token: {
    colorPrimary: 'white',
    fontFamily: 'inherit',
  },
};
export const extractSlugAndProductId = (slugWithId: string): string => {
  const cleanSlug = slugWithId?.split('?')[0]?.replace(/\/$/, '');
  if (!cleanSlug) return '';

  const productId = cleanSlug.slice(cleanSlug.lastIndexOf('-') + 1);

  const isValidObjectId = /^[a-fA-F0-9]{24}$/.test(productId);

  const urlParams = new URLSearchParams(slugWithId);
  const queryProductId = urlParams.get('productId');

  return isValidObjectId ? productId : (queryProductId as string);
};

const SlidBookContainer: React.FC<SlidBookContainerProps> = ({
  slidBookContainerData,
  token,
}) => {
  const [selectedOption, setSelectOption] = useState<{
    type: 'hardCopy' | 'softCopy';
    productId: number;
    price: number;
  }>({
    type: 'hardCopy',
    productId: 0,
    price: 0,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingAddToCart, setLoadingAddToCart] = useState({
    value: false,
    id: 0,
  });
  const [isFlipped, setIsFlipped] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [divHeight, setDivHeight] = useState('100%');
  const [screenHeight, setScreenHeight] = useState(0);
  const [loadedImages, setLoadedImages] = useState({
    front: false,
    back: false,
    side: false,
  });
  const [, setVisible] = useRecoilState(authModalControl);

  const localUserId = useRecoilValue(localUserID);
  const userData: User | undefined = useRecoilValue(user);
  const divRef = useRef<HTMLDivElement>(null);
  const screens = useBreakpoint();
  const router = useRouter();
  const { short } = router.query;

  const allImagesLoaded =
    loadedImages.front && loadedImages.back && loadedImages.side;

  const showModal = () => {
    setIsModalVisible(true);
    const currentTime = new Date();

    triggerViewEvent(currentTime);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    const currentTime = new Date();

    triggerViewEvent(currentTime);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    const currentTime = new Date();
    if (startTime) {
      triggerSessionEvent(startTime, currentTime);
    }
  };

  const handleOptionClick = ({
    type,
    productId,
    price,
  }: {
    type: 'hardCopy' | 'softCopy';
    productId: number;
    price: number;
  }) => {
    setSelectOption({ type, productId, price });
  };

  useEffect(() => {
    if (slidBookContainerData) {
      if (slidBookContainerData?.hardCoverAvailable !== false) {
        setSelectOption({
          type: 'hardCopy',
          productId: slidBookContainerData?.hardCoverProductId,
          price: slidBookContainerData?.hardCopyPrice?.discountedPrice,
        });
      } else {
        setSelectOption({
          type: 'softCopy',
          productId: slidBookContainerData?.digitalProductId,
          price: slidBookContainerData?.softCopyPrice?.discountedPrice,
        });
      }
    }
  }, [slidBookContainerData?.hardCoverAvailable]);

  const handleAddDataToCart = async (payload: AddToCartProps) => {
    try {
      setLoadingAddToCart({ value: true, id: payload.id });

      await addToCart(payload);
      addToRecentlyViewed(payload.id, userData?.zl_uid);

      notify(
        `${slidBookContainerData?.bookName} ${selectedOption.type === 'hardCopy' ? 'Hard Copy' : 'Digital Copy'}  added to cart successfully!`,
        'success',
      );
      router.push('/cart');
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setLoadingAddToCart({ value: false, id: payload.id });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        const screenHeight = window.innerHeight;
        setScreenHeight(window.innerHeight);

        const divHeight = divRef.current.clientHeight;

        if (divHeight > screenHeight) {
          setDivHeight('90vh');
        } else {
          setDivHeight('100%');
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screens]);

  const handleImageLoad = (imageType: 'front' | 'back' | 'side') => {
    setLoadedImages((prevState) => ({
      ...prevState,
      [imageType]: true,
    }));
  };

  const triggerViewEvent = (start: Date) => {
    setStartTime(start);
    const eventParams: EventDetails = {
      event_name: 'learn_view',
      source: 'books',
      source_type: router.query['cs']
        ? 'comingSoon'
        : router.query['swp']
          ? 'short'
          : 'long',
      source_id: extractSlugAndProductId(router.asPath) as string,
      sub_source: 'sample',
      sub_source_id: null,
      unit: 'boolean',
      value: 1,
    };

    logEvent({
      eventDetails: eventParams,
      userData: userData,
      pathname: router.pathname,
    });
  };

  const triggerSessionEvent = (start: Date, end: Date) => {
    const sessionDuration = (end.getTime() - start.getTime()) / 1000;

    const eventParams: EventDetails = {
      event_name: 'learn_session',
      source: 'books',
      source_type: router.query['cs']
        ? 'comingSoon'
        : router.query['swp']
          ? 'short'
          : 'long',
      source_id: extractSlugAndProductId(router.asPath) as string,
      sub_source: 'sample',
      sub_source_id: null,
      unit: 'seconds',
      value: sessionDuration,
    };

    logEvent({
      eventDetails: eventParams,
      userData: userData,
      pathname: router.pathname,
    });
    setStartTime(null);
  };
  const handleMouseClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setIsFlipped(true);
  };

  const handleTouchClick: React.TouchEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setIsFlipped(true);
  };

  const handleMouseBackClick: React.MouseEventHandler<HTMLDivElement> = (
    event,
  ) => {
    event.preventDefault();
    setIsFlipped(false);
  };

  const handleTouchBackClick: React.TouchEventHandler<HTMLDivElement> = (
    event,
  ) => {
    event.preventDefault();
    setIsFlipped(false);
  };
  useEffect(() => {
    const bookWrap = document.querySelector('.book-wrap');
    if (bookWrap) {
      if (isFlipped) {
        bookWrap.classList.add('flip');
      } else {
        bookWrap.classList.remove('flip');
      }
    }
  }, [isFlipped]);

  useEffect(() => {
    if (allImagesLoaded) {
      const bookWrap = document.querySelector('.book-wrap');

      const handleMouseEnter = () => {
        if (bookWrap) {
          bookWrap.classList.add('rotate');
        }
      };

      const handleMouseLeave = () => {
        if (bookWrap) {
          bookWrap.classList.remove('rotate');
        }
      };

      if (window.innerWidth > 600) {
        if (bookWrap) {
          bookWrap.addEventListener(
            'mouseenter',
            handleMouseEnter as EventListener,
          );
          bookWrap.addEventListener(
            'mouseleave',
            handleMouseLeave as EventListener,
          );
        }
      }

      return () => {
        if (bookWrap) {
          bookWrap.removeEventListener(
            'mouseenter',
            handleMouseEnter as EventListener,
          );
          bookWrap.removeEventListener(
            'mouseleave',
            handleMouseLeave as EventListener,
          );
        }
      };
    } else return;
  }, [allImagesLoaded]);

  useEffect(() => {
    if (allImagesLoaded) {
      const bookWrap = document.querySelector('.book-wrap');

      const simulateRotationAndFlip = () => {
        if (!bookWrap) return;

        bookWrap.classList.add('rotate');

        setTimeout(() => {
          bookWrap.classList.remove('rotate');
          bookWrap.classList.add('flip');

          setTimeout(() => {
            bookWrap.classList.remove('flip');
          }, 2000);
        }, 500);
      };

      const simulationTimer = setTimeout(simulateRotationAndFlip, 1000);

      return () => {
        clearTimeout(simulationTimer);
      };
    } else {
      return;
    }
  }, [allImagesLoaded, router.isReady]);

  const paymentButton = (
    <div className={clsx(screenHeight > 800 ? 'mt-4' : 'mt-4', 'w-full ')}>
      <ButtonDefault
        size={4}
        rounded={false}
        loading={
          Number(selectedOption?.productId) === loadingAddToCart.id &&
          loadingAddToCart.value
        }
        roundedSm={true}
        onClick={(e) => {
          e.stopPropagation();
          handleAddDataToCart({
            id: Number(selectedOption?.productId) as number,
            temp_user_id: localUserId,
            quantity: 1,
          });

          trackAddToCartEvent({
            productData: {
              price: Number(selectedOption?.price),
              id: Number(selectedOption?.productId),
              heading: `${slidBookContainerData?.bookName} ${selectedOption.type === 'hardCopy' ? 'Hard Copy' : 'Digital Copy'} `,
              quantity: 1 as number,
            },
            userData,
            pathname: router.pathname,
            productType:
              selectedOption.type === 'hardCopy' ? 'hardcopy' : 'digitalcopy',
          });
        }}
        variant={ButtonVariants.PRIMARY}
        className='flex w-full justify-center px-5 py-3 font-medium text-xs whitespace-nowrap'
      >
        <span className='px-4 text-xs py-1'>BUY NOW</span>
      </ButtonDefault>
      <div className='text-white flex gap-x-2 text-xs justify-center pt-3'>
        <SecurePayment /> Secure Payments
      </div>
    </div>
  );

  return (
    <ConfigProvider theme={config}>
      <div className=' relative w-full flex justify-center items-center lg:h-screen lg:translate-x-7 2xl:translate-x-0 xl:min-h-full'>
        <div
          style={{
            padding: screens.lg ? '1px' : 'null',
            background: screens.lg
              ? !short
                ? 'linear-gradient(to top, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0))'
                : 'linear-gradient(180deg, #000000 0%, #151515 100%)'
              : 'null',
            borderRadius: screens.lg ? '8px' : 'null',
          }}
        >
          <div
            ref={divRef}
            style={{
              position: 'relative',
              height: divHeight,
              overflowY: 'auto',
              background: screens.lg
                ? !short
                  ? 'linear-gradient(to top, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0))'
                  : 'linear-gradient(180deg, #000000 0%, #151515 100%)'
                : 'null',
            }}
            className={clsx(
              screenHeight > 800 ? 'md:py-4 lg:py-8' : 'pb-2 pt-6',
              `px-6 rounded-[8px] h- no-scrollbar lg:overflow-auto h-[${divHeight}] relative `,
            )}
          >
            <ShouldRender check={!screens?.lg && !short}>
              <div
                className={clsx(
                  screenHeight > 800 ? 'pb-4 mt-4' : 'pb-8',
                  'flex justify-center ',
                )}
              >
                <motion.div
                  style={{
                    position: 'relative',
                    display: 'inline-block',
                    borderRadius: '4px',
                    padding: '2px',
                    backgroundColor: '#001330',
                  }}
                >
                  <motion.div
                    initial={{ backgroundPosition: '0% 50%' }}
                    animate={{ backgroundPosition: '200% 50%' }}
                    transition={{
                      ease: 'linear',
                      duration: 3,
                      repeat: Infinity,
                    }}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      borderRadius: '4px',
                      border: '2px solid transparent',
                      background:
                        'linear-gradient(90deg, rgba(35,122,255,0) 25%, rgba(35,122,255,1) 50%, rgba(35,122,255,0) 75%)',
                      backgroundSize: '200% 100%',
                      pointerEvents: 'none',
                    }}
                  />
                  <div
                    className='text-[#237AFF] whitespace-nowrap bg-[#001330] font-semibold py-2 px-3 text-xs flex'
                    style={{
                      borderRadius: '4px',
                      zIndex: 2,
                      position: 'relative',
                    }}
                  >
                    {slidBookContainerData?.productHighlightText}
                  </div>
                </motion.div>
              </div>
            </ShouldRender>
            {/* <ShouldRender check={allImagesLoaded}> */}
            <ShouldRender check={!short}>
              <div
                className={clsx(
                  screenHeight > 800 && 'mt-5 xl:-translate-x-2',
                  'wrap -translate-y-2 transition-transform duration-500',
                  screenHeight > 800 && !isFlipped && ' lg:translate-x-1',
                  !allImagesLoaded && 'hidden',
                )}
              >
                <div className='perspective z-40'>
                  <div className='book-wrap'>
                    {/* Front of the Book */}
                    <div
                      className='book book-1'
                      onClick={handleMouseClick}
                      onTouchStart={handleTouchClick}
                    >
                      <Image
                        src={slidBookContainerData?.bookImage}
                        width={236}
                        height={358}
                        alt='Book Front'
                        priority
                        className='book-image'
                        onLoadingComplete={() => handleImageLoad('front')}
                      />
                    </div>

                    {/* Back of the Book */}
                    <div
                      className='book-back book-1'
                      onClick={handleMouseBackClick}
                      onTouchStart={handleTouchBackClick}
                    >
                      <Image
                        src={slidBookContainerData?.bookBackImage}
                        width={236}
                        height={358}
                        alt='Book Back'
                        priority
                        onLoadingComplete={() => handleImageLoad('back')}
                      />
                    </div>

                    {/* Side of the Book */}
                    <div
                      className={clsx(
                        screenHeight > 800
                          ? 'h-[306px] 2xl:h-[332px]'
                          : 'h-[306px]',
                        'title bg-[#131313]',
                      )}
                    >
                      <div>
                        <Image
                          src={slidBookContainerData?.bookSideImage}
                          layout='fill'
                          alt='Book Side'
                          priority
                          className='-translate-y-[2px]'
                          onLoadingComplete={() => handleImageLoad('side')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ShouldRender>
            <ShouldRender check={short}>
              <div
                className=' flex justify-center items-center pb-8'
                onClick={handleMouseClick}
                onTouchStart={handleTouchClick}
              >
                <Image
                  src={slidBookContainerData?.bookImage}
                  width={screens.xxl ? 236 : 181}
                  height={screens.xxl ? 358 : 280}
                  alt='Book Front'
                  priority
                  onLoadingComplete={() => handleImageLoad('front')}
                />
              </div>
            </ShouldRender>

            <ShouldRender check={!allImagesLoaded && !short}>
              <div
                className=' w-full flex justify-center items-center
                      h-[350px]'
              >
                <Spin size='default' />
              </div>
            </ShouldRender>
            <ShouldRender check={!short}>
              <ShouldRender check={extractSlugAndProductId(router.asPath)}>
                <div
                  className={clsx(
                    screenHeight > 800
                      ? ' mt-4 2xl:mt-14 -translate-x-2 xl:-translate-x-5'
                      : '-translate-x-0',
                    screenHeight > 800 &&
                      isFlipped &&
                      '-translate-x-0 xl:-translate-x-5',
                    'px-8 -translate-x-0 xl:-translate-x-0 flex justify-center -translate-y-6 transition-transform duration-500',
                  )}
                >
                  <div
                    onClick={() => showModal()}
                    className={clsx(
                      screenHeight > 800
                        ? 'max-w-[236px] translate-x-3'
                        : ' md:max-w-[216px]',
                      'relative w-full  cursor-pointer overflow-hidden  rounded-lg',
                    )}
                  >
                    {/* Shimmer Effect */}
                    <motion.div
                      className='absolute top-0 left-0 cursor-pointer h-full w-full'
                      style={{
                        background:
                          'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%)',
                        transform: 'translateX(-100%)',
                      }}
                      animate={{ translateX: ['-100%', '100%'] }}
                      transition={{
                        duration: 2,
                        ease: 'linear',
                        repeat: Infinity,
                      }}
                    />

                    {/* Button */}
                    <Button
                      style={{
                        border: '1px solid #FFFFFF33',
                      }}
                      className='w-full -z-10 bg-[#7A7A7A] text-white rounded-lg'
                    >
                      <div className='relative'>
                        <span className='capitalize text-xs py-1 px-12 lg:px-0 font-medium'>
                          READ SAMPLE
                        </span>
                      </div>
                    </Button>
                  </div>
                </div>
              </ShouldRender>

              <ShouldRender
                check={screens?.lg && extractSlugAndProductId(router.asPath)}
              >
                <div className=''>
                  <div className='shadow-md text-white'>
                    <div className='flex gap-x-2'>
                      <ShouldRender
                        check={
                          slidBookContainerData?.hardCoverAvailable !== false
                        }
                      >
                        <motion.div
                          className={clsx(
                            `rounded-lg cursor-pointer p-3 w-full ${
                              selectedOption.type === 'hardCopy'
                                ? 'bg-white'
                                : 'bg-backgroundGray'
                            }`,
                          )}
                          style={{
                            boxShadow: '0px 4px 4px 0px #0000004D',
                            border: '1px solid #FFFFFF33',
                          }}
                          onClick={() =>
                            handleOptionClick({
                              type: 'hardCopy',
                              productId:
                                slidBookContainerData?.hardCoverProductId,
                              price:
                                slidBookContainerData?.hardCopyPrice
                                  ?.discountedPrice,
                            })
                          }
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          whileHover={{ scale: 1 }}
                          whileTap={{ scale: 0.99 }}
                        >
                          <div
                            className={clsx(
                              'flex items-center justify-between relative w-min h-full',
                            )}
                          >
                            <div
                              className={clsx(
                                selectedOption.type === 'hardCopy'
                                  ? 'text-black'
                                  : 'text-white',
                                `flex flex-col w-full`,
                              )}
                            >
                              <div className=' text-xs font-bold '>
                                HARD COPY
                              </div>
                              <div className='flex gap-x-2 w-full items-baseline pr-6'>
                                <span className='inline-flex font-bold text-lg'>
                                  <p>₹</p>{' '}
                                  {
                                    slidBookContainerData?.hardCopyPrice
                                      ?.discountedPrice
                                  }
                                </span>
                                <span className='line-through flex text-base  text-[#9A9A9A] font-semibold'>
                                  <p>₹</p>{' '}
                                  {
                                    slidBookContainerData?.hardCopyPrice
                                      ?.mrpPrice
                                  }
                                </span>
                              </div>
                              <div className='relative w-full mt-2  overflow-hidden rounded-lg'>
                                <ShouldRender
                                  check={selectedOption.type === 'hardCopy'}
                                >
                                  <motion.div
                                    className='absolute top-0 left-0 h-full w-full'
                                    style={{
                                      background:
                                        'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%)',
                                      transform: 'translateX(-100%)',
                                    }}
                                    animate={{ translateX: ['-100%', '100%'] }}
                                    transition={{
                                      duration: 2,
                                      ease: 'linear',
                                      repeat: Infinity,
                                    }}
                                  />
                                </ShouldRender>

                                <div
                                  className={clsx(
                                    selectedOption.type === 'hardCopy'
                                      ? 'bg-[#ECAD03] border-[1px] border-[#FFFFFF33] text-white '
                                      : 'bg-transparent  border-[1px] border-transparent text-[#9A9A9A] -translate-x-3',
                                    'text-xs   rounded-lg  font-bold whitespace-nowrap py-1 px-3 uppercase w-full ',
                                  )}
                                >
                                  Free digital copy
                                </div>
                              </div>
                            </div>
                            <label className='rounded-full flex items-start absolute -right-[2px] -top-[3px] justify-center cursor-pointer'>
                              <Checkbox
                                className='hidden'
                                checked={selectedOption.type === 'hardCopy'}
                              />
                              <div
                                className={`w-5 h-5 rounded-full flex items-center justify-center ${
                                  selectedOption.type === 'hardCopy'
                                    ? 'bg-black'
                                    : 'bg-transparent border-2 border-[#737373]'
                                }`}
                              >
                                {selectedOption.type === 'hardCopy' && (
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='4'
                                    className='w-3 h-3  text-white'
                                  >
                                    <path
                                      d='M5 12l5 5L20 7'
                                      strokeLinecap='round'
                                    />
                                  </svg>
                                )}
                              </div>
                            </label>
                          </div>
                        </motion.div>
                      </ShouldRender>
                      <ShouldRender
                        check={
                          slidBookContainerData?.hardCoverAvailable === false
                        }
                      >
                        <motion.div
                          className={clsx(`rounded-lg cursor-pointer  w-full `)}
                        >
                          <div className='w-full  h-full'>
                            <Button
                              size='small'
                              type='text'
                              style={{
                                position: 'relative',
                                height: '100%',
                                background: '#141414',
                                width: '100%',
                                border: 'none',
                                borderRadius: '8px',
                                padding: '22px 30px',
                                zIndex: 1,
                                boxShadow: '0px 4px 4px 0px #0000004D',
                              }}
                              className='w-full  h-full  hover:text-white text-xs font-semibold'
                            >
                              <span
                                style={{
                                  position: 'absolute',
                                  inset: 0,
                                  zIndex: -1,
                                  borderRadius: 'inherit',
                                  padding: '1.3px',
                                  background:
                                    'linear-gradient(180deg, #6199FF 0%, #045CFB 100%)',
                                  WebkitMask:
                                    'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',

                                  WebkitMaskComposite: 'xor',
                                  maskComposite: 'exclude',
                                }}
                              />
                              <div className='flex justify-center items-center w-full '>
                                <span className='flex flex-col justify-start gap-y-1.5'>
                                  <p className='w-full text-center font-semibold text-white text-[10px]'>
                                    Hard Copy
                                  </p>
                                  <span
                                    className='flex gap-x-2 font-bold text-xs items-baseline pl-1'
                                    style={{
                                      background:
                                        'linear-gradient(to bottom, #6199FF, #045CFB)',
                                      WebkitBackgroundClip: 'text',
                                      WebkitTextFillColor: 'transparent',
                                    }}
                                  >
                                    Available SOON
                                  </span>
                                </span>
                              </div>
                            </Button>
                          </div>
                        </motion.div>
                      </ShouldRender>
                      <motion.div
                        className={clsx(
                          `rounded-lg cursor-pointer p-3 w-full ${
                            selectedOption.type === 'softCopy'
                              ? 'bg-white'
                              : 'bg-backgroundGray'
                          }`,
                        )}
                        style={{
                          boxShadow: '0px 4px 4px 0px #0000004D',
                          border: '1px solid #FFFFFF33',
                        }}
                        onClick={() =>
                          handleOptionClick({
                            type: 'softCopy',
                            productId: slidBookContainerData?.digitalProductId,
                            price:
                              slidBookContainerData?.softCopyPrice
                                ?.discountedPrice,
                          })
                        }
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        whileHover={{ scale: 1 }}
                        whileTap={{ scale: 0.99 }}
                      >
                        <div
                          className={clsx(
                            'flex items-center justify-between relative w-min h-full',
                          )}
                        >
                          <div
                            className={clsx(
                              selectedOption.type === 'softCopy'
                                ? 'text-black'
                                : 'text-white',
                              `flex flex-col w-full`,
                            )}
                          >
                            <div className=' text-xs font-bold '>
                              DIGITAL COPY
                            </div>
                            <div className='flex gap-x-2 w-full items-baseline '>
                              <span className='inline-flex font-bold text-lg'>
                                <p>₹</p>{' '}
                                {
                                  slidBookContainerData?.softCopyPrice
                                    ?.discountedPrice
                                }
                              </span>
                              <span className='line-through flex text-base  text-[#9A9A9A] font-semibold'>
                                <p>₹</p>{' '}
                                {slidBookContainerData?.softCopyPrice?.mrpPrice}
                              </span>
                            </div>
                            <div className='relative w-full mt-2 max-w-[256px] overflow-hidden rounded-lg'>
                              <ShouldRender
                                check={selectedOption.type === 'softCopy'}
                              >
                                <motion.div
                                  className='absolute top-0 left-0 h-full w-full'
                                  style={{
                                    background:
                                      'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%)',
                                    transform: 'translateX(-100%)',
                                  }}
                                  animate={{ translateX: ['-100%', '100%'] }}
                                  transition={{
                                    duration: 2,
                                    ease: 'linear',
                                    repeat: Infinity,
                                  }}
                                />
                              </ShouldRender>

                              <div
                                className={clsx(
                                  selectedOption.type === 'softCopy'
                                    ? 'bg-[#ECAD03] border-[1px] border-[#FFFFFF33] text-white '
                                    : 'bg-transparent  border-[1px] border-transparent text-[#9A9A9A] -translate-x-3',
                                  'text-xs   rounded-lg  font-bold whitespace-nowrap py-1 px-6 uppercase w-full ',
                                )}
                              >
                                Instant Access
                              </div>
                            </div>
                          </div>
                          <label className='rounded-full flex items-start absolute -right-[2px] -top-[3px] justify-center cursor-pointer'>
                            <Checkbox
                              className='hidden'
                              checked={selectedOption.type === 'softCopy'}
                            />
                            <div
                              className={`w-5 h-5 rounded-full flex items-center justify-center ${
                                selectedOption.type === 'softCopy'
                                  ? 'bg-black'
                                  : 'bg-transparent border-2 border-[#737373]'
                              }`}
                            >
                              {selectedOption.type === 'softCopy' && (
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='currentColor'
                                  strokeWidth='4'
                                  className='w-3 h-3  text-white'
                                >
                                  <path
                                    d='M5 12l5 5L20 7'
                                    strokeLinecap='round'
                                  />
                                </svg>
                              )}
                            </div>
                          </label>
                        </div>
                      </motion.div>
                    </div>
                  </div>
                </div>
              </ShouldRender>

              <ShouldRender check={screens?.lg && !screens.xl}>
                <Affix offsetBottom={screens.lg ? 28 : 0}>
                  {paymentButton}
                </Affix>
              </ShouldRender>
              <ShouldRender check={screens.xl}>{paymentButton}</ShouldRender>
            </ShouldRender>

            <ShouldRender check={screens.lg && short}>
              <div className='flex flex-col gap-y-2 pb-3'>
                <ButtonDefault
                  size={4}
                  rounded={false}
                  roundedSm={true}
                  // onClick={() => {
                  //   router.push(
                  //     `/pdf/${slidBookContainerData?.readersProductId}?samplePdf=true`,
                  //   );
                  // }}
                  onClick={() => {
                    if (token) {
                      router.push(
                        `/pdf/${slidBookContainerData?.readersProductId}?samplePdf=true`,
                      );
                    } else {
                      setVisible((prevState) => ({
                        ...prevState,
                        signUp: true,
                        bookId:
                          slidBookContainerData?.readersProductId as string,
                      }));
                    }
                  }}
                  variant={ButtonVariants.WHITE}
                  className='flex w-full justify-center  px-5 py-3 min-w-[310px] border-none font-medium text-xs whitespace-nowrap'
                >
                  <span className='px-4 text-sm md:text-base font-semibold py-1'>
                    Start Reading
                  </span>
                </ButtonDefault>

                {/* <ButtonDefault
                      size={4}
                      rounded={false}
                      roundedSm={true}
                      variant={ButtonVariants.GRAY}
                      className='flex w-full justify-center  px-5 py-3 border-none font-medium text-xs whitespace-nowrap'
                    >
                      <span className='px-4 text-sm md:text-base font-semibold flex gap-x-2 items-center py-1'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M5.41528 4.83167C5.41528 4.0405 6.29053 3.56266 6.95604 3.99049L18.1068 11.1588C18.7191 11.5525 18.7191 12.4475 18.1068 12.8412L6.95604 20.0095C6.29053 20.4373 5.41528 19.9595 5.41528 19.1683V4.83167Z'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        Listen
                      </span>
                    </ButtonDefault> */}
              </div>
            </ShouldRender>

            <Modal
              open={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
              width='100%'
              className='custom-modal-bg customLoginModal'
              closable={false}
              style={{ top: 0, backgroundColor: 'transparent' }}
              centered={true}
              destroyOnClose={true}
            >
              <div
                className='no-select custom-modal-bg'
                id='trackingModal'
                style={{ height: '100%' }}
              >
                <PDFReaderIframe
                  isModal={true}
                  handleCancel={handleCancel}
                  bookSlugId={router.query.bookId as string}
                />
              </div>
            </Modal>
          </div>
        </div>
        <div className='absolute bg-gradient-to-b from-[rgba(0,0,0,0.60)] via-[rgba(0,0,0,0.88)] backdrop-blur-2xl  inset-0 w-full h-full lg:hidden block  -z-20'></div>

        <div className='absolute  inset-0 lg:hidden top-0 block backdrop-blur-lg -translate-y-[250px] md:-translate-y-[450px] lg:translate-y-[100px] h-full -z-40 overflow-hidden'>
          <Image
            src={slidBookContainerData?.bookImage as string}
            alt='Background Image'
            layout='fill'
            priority
            className='-rotate-90 lg:rotate-90 transform w-auto h-auto absolute top-0 left-0 object-contain'
          />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default SlidBookContainer;
