import { baseForAskAi } from '../apiClients';
import { Endpoints } from '../../network';

export const askQuestion = async (data: {
  question: string;
  session_id?: string;
  book_id: string;
}) => {
  const urlWithQuery = `${Endpoints.askQuestion}`;

  const formData = new FormData();
  formData.append('question', data.question);
  formData.append('book_id', data.book_id);

  if (data.session_id) {
    formData.append('session_id', data.session_id);
  }

  const response = await baseForAskAi.post(urlWithQuery, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response?.data?.data;
};
