import Image from 'next/image';
import React from 'react';

import AuthLayout from '../AuthLayout';
import { ZebraLearnLogo } from '../../../../assets';

interface Props {
  isModal?: boolean;
  onCancel?: () => void;
  visible?: boolean;
}

const ForgotPassword: React.FC<Props> = ({ isModal = false }) => {
  return (
    <AuthLayout isModal={isModal}>
      <div className='flex items-center gap-y-10 flex-col justify-center'>
        <div className={` z-30  flex flex-col gap-y-1 w-full`}>
          <div className='relative pt-2 md:pt-4'>
            <span className=' flex justify-center w-full'>
              <Image
                src={ZebraLearnLogo}
                alt='Logo'
                className='object-contain h-3.5 w-full'
              />
            </span>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
export default ForgotPassword;
