import { Button } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

interface FailedToLoadPDFProps {
  title?: string;
  description?: string;
  primaryButtonText?: string;
  primaryButtonLink?: string;
  secondaryButtonText?: string;
  secondaryButtonLink?: string;
  contactText?: string;
  contactLink?: string;
  imageSrc: string;
}

const FailedToLoadPDF: React.FC<FailedToLoadPDFProps> = ({
  title = 'Our systems are temporarily down due to high traffic.',
  description = 'Please wait 10–20 minutes and try again.',
  primaryButtonText = 'Explore Schools',

  secondaryButtonText = 'Go Back',
  contactText = 'Contact us',
  contactLink = '/contact',
  secondaryButtonLink = '/',
  imageSrc,
}) => {
  const router = useRouter();

  return (
    <div className='flex flex-col-reverse pb-10 lg:flex-row no-scrollbar overflow-hidden items-center justify-between max-h-[80%] h-full text-white w-full'>
      <div className='flex flex-col items-center lg:items-start text-center lg:text-left space-y-4 lg:w-2/5 px-20'>
        <h1 className='text-xl lg:text-4xl font-bold mt-6'>{title}</h1>
        <p
          style={{
            color: 'var(--Grey-500-F, #C7C6CF)',
          }}
          className='lg:text-lg'
        >
          {description}
        </p>
        <div className='flex space-x-4 pt-2'>
          <Button
            type='default'
            className='px-6 py-4 bg-white text-black border-white font-semibold rounded-full'
            onClick={() => {
              router.push(secondaryButtonLink);
            }}
          >
            {secondaryButtonText ?? 'Default Text'}
          </Button>
          <Button
            type='text'
            onClick={() => {
              if (typeof window !== undefined) {
                window.location.reload();
              }
            }}
            className='px-6 py-4 border-white font-semibold rounded-full'
          >
            {primaryButtonText}
          </Button>
        </div>
        <p className='text-xs lg:text-sm text-textGray  lg:w-full pt-5 lg:pt-3'>
          If you still have the issue,{' '}
          <Link
            href={contactLink}
            className='text-white underline text-xs md:text-sm tracking-wide'
          >
            {contactText}
          </Link>
        </p>
      </div>

      <div className='lg:ml-8 lg:w-3/5 flex items-end mt-10 lg:mt-20 justify-end'>
        <div className='h-[220px] lg:h-full'>
          <Image
            src={imageSrc}
            width={740}
            height={446}
            alt='System Down Illustration'
            className='w-full h-full'
            priority
          />
        </div>
      </div>
    </div>
  );
};

export default FailedToLoadPDF;
