import { Modal, Select } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { ShouldRender } from '../basic/ShouldRender';
import { notify } from '../basic/notify';

interface CustomSelectProps {
  value: string | number | undefined;
  onChange: (value: string | number) => void;
  options: { label: string; value: string | number }[] | undefined;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  filterOption?: (
    input: string,
    option?: { label: string; value: string | number },
  ) => boolean;
  name?: 'state' | 'city';
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
  value,
  onChange,
  options,
  placeholder = 'Select an option',
  disabled = false,
  loading = false,
  filterOption,
  name,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean | null>(null);
  const screens = useBreakpoint();

  const getNotificationMessage = () => {
    if (name === 'state') return 'Please select a country first';
    if (name === 'city') return 'Please select a state first';
    return '';
  };

  useEffect(() => {
    if (typeof window === 'undefined' || typeof document === 'undefined')
      return;
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.touchAction = 'none';
    } else if (isModalOpen !== null) {
      document.body.classList.remove('modal-open');

      const target = document.getElementById('scrollable-container');
      if (target) {
        const rect = target.getBoundingClientRect();
        if (rect) {
          const targetPosition = rect.top + window.scrollY - 150;
          window.scrollTo({ top: targetPosition, behavior: 'smooth' });
        }
      }
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.touchAction = '';
    }

    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.touchAction = '';
    };
  }, [isModalOpen]);

  return !screens.lg ? (
    <>
      <Select
        style={{ height: 40, width: '100%' }}
        value={value === '' ? undefined : value}
        placeholder={placeholder}
        disabled={disabled}
        onClick={() => {
          if (disabled) {
            notify(getNotificationMessage(), 'warning');
          } else if (!disabled && !isModalOpen) {
            setIsModalOpen(true);
          }
        }}
        open={false}
        options={options}
        id='scrollable-container'
        className={clsx(
          disabled && 'cursor-not-allowed',
          'antdSelect antdSelectDropdown custom-select-dropdown-scroll z-20',
        )}
      />
      <ShouldRender check={isModalOpen}>
        <Modal
          open={isModalOpen ?? false}
          onCancel={() => setIsModalOpen(false)}
          footer={null}
          className='custom-select-modal'
          closable={false}
          destroyOnClose
          getContainer={false}
        >
          <Select
            style={{ width: '100%' }}
            dropdownStyle={{
              WebkitOverflowScrolling: 'touch',
              overscrollBehavior: 'contain',
            }}
            listHeight={340}
            showSearch
            autoFocus
            value={value === '' ? undefined : value}
            placeholder={placeholder}
            disabled={disabled}
            open={isModalOpen ?? false}
            onChange={(selectedValue) => {
              onChange(selectedValue);
              setIsModalOpen(false);
            }}
            options={options}
            filterOption={filterOption}
            loading={loading}
            className='antdSelect antdSelectDropdown custom-select-dropdown-scroll-mobile custom-select-dropdown-scroll-mobile custom-select-dropdown-scroll z-20'
          />
        </Modal>
      </ShouldRender>
    </>
  ) : (
    <Select
      style={{ height: 44, width: '100%' }}
      dropdownStyle={{
        backgroundColor: '#131313',
        color: '#ffffff',
        maxHeight: '250px',
      }}
      showSearch
      value={value === '' ? undefined : value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      onClick={() => {
        if (disabled) {
          notify(getNotificationMessage(), 'warning');
        }
      }}
      options={options}
      filterOption={filterOption}
      loading={loading}
      className='antdSelect antdSelectDropdown custom-select-dropdown-scroll z-20'
    />
  );
};
