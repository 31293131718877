import { BookOutlined, FileOutlined } from '@ant-design/icons';
import { Button, Modal, Tag } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import ButtonDefault, { ButtonVariants } from '../../shared/basic/button';
import ExpandableText from '../../shared/basic/ExpandableText';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { formateErrorObject } from '../../shared/formateErrorObject';
import { notify } from '../../shared/notify';
import GoogleTranslateIcon from '../../../../assets/Icons/google-translate';
import { authModalControl } from '../../../atoms/authModalControl';
import { subscribeForUpcomingBooks } from '../../../services/Products';
import { BundleDataProps } from '../../../utils/interfaces';
import { IBookDetails } from '../../../utils/interfaces/Products/books';

interface ProductProps {
  productData?: IBookDetails;
  isCourse?: boolean;
  bundleData?: BundleDataProps;
  isBundle?: boolean;
  isShortBook?: boolean;
  token?: string;
}

const Products: React.FC<ProductProps> = ({
  isBundle = false,
  productData,
  isShortBook = false,
  token,
}) => {
  const [, setVisible] = useRecoilState(authModalControl);

  const [loading, setLoading] = useState({ joined: false, value: false });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const router = useRouter();
  const screens = useBreakpoint();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubscribe = async () => {
    try {
      setLoading((prevLoading) => ({
        ...prevLoading,
        value: true,
      }));
      if (productData?._id) {
        const response = await subscribeForUpcomingBooks(productData?._id);

        setLoading((prevLoading) => ({
          ...prevLoading,
          joined: response?.data?.subscribed,
        }));
      }
      handleCancel();
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setLoading((prevLoading) => ({
        ...prevLoading,
        value: false,
      }));
    }
  };

  useEffect(() => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      joined: !!productData?.isSubscribed,
    }));
  }, [productData]);

  return (
    <React.Fragment>
      <div className=' flex-col h-full mt-12 py-10 md:py-12 w-full'>
        <div className='md:flex h-full  '>
          <ShouldRender check={!screens.md}>
            <span className='w-full relative'>
              <div className={clsx('flex  h-full', !isBundle && 'items-end')}>
                <div className='flex justify-center w-full'>
                  <ShouldRender check={isBundle}>
                    <div className='flex items-center '>
                      <div className='md:h-[500px]'>
                        {' '}
                        {/* <Image
                        src={productData?.bookImage as string}
                        height={500}
                        width={500}
                        alt='BookImage'
                        className='object-contain w-full h-full'
                      /> */}
                      </div>
                    </div>
                  </ShouldRender>
                  <ShouldRender check={!isBundle && productData?.image}>
                    <div className='flex justify-center w-full pb-2'>
                      <div className='w-[240px] h-[340px] '>
                        <Image
                          src={productData?.image as string}
                          alt='BookImage'
                          height={340}
                          width={240}
                          className='object-contain w-full h-full'
                        />
                      </div>
                    </div>
                  </ShouldRender>
                </div>
              </div>
            </span>
          </ShouldRender>
          <span className='w-full flex justify-center mt-5  md:mt-0'>
            <span className='flex justify-center items-end px-2 xs:px-5  max-w-[700px] md:px-12 '>
              {/* <ShouldRender check={isBundle}>
                <BundlesDetailsCard productData={data as BundleDataProps} />
              </ShouldRender> */}
              {/* <ShouldRender check={!isBundle}>
                <ProductDetailsCard
                  productData={data as BookData | CourseData}
                  isCourse={isCourse}
                />
              </ShouldRender> */}
              <div className='text-fadedWhite flex flex-col gap-y-2 lg:gap-y-4 '>
                <ShouldRender check={productData?.bookType === 'comingSoon'}>
                  <div className='flex w-min pb-1'>
                    <Tag
                      color='#3E0A6E'
                      className='text-[#EAD3FF] font-semibold py-1.5 px-3 text-xs rounded-[4px]'
                    >
                      Coming Soon
                    </Tag>
                  </div>
                </ShouldRender>

                <div className='flex flex-col gap-y-1'>
                  <span className='text-base lg:text-[32px] leading-9 font-semibold'>
                    {productData?.bookName}
                  </span>
                  <span className='text-xs font-light lg:font-normal lg:text-sm text-white leading-5  lg:mt-2'>
                    BY {productData?.authorName?.toUpperCase()}
                  </span>
                </div>

                <span className='text-base md:text-lg lg:text-xl font-thin'>
                  <ExpandableText text={productData?.desc as string} />
                </span>

                <div className='flex justify-between lg:w-4/5 gap-x-3 lg:gap-x-0 mt-4'>
                  <span className='flex items-center text-sm md:text-base lg:text-xl gap-x-4'>
                    <span className=' text-gray-300'>
                      <GoogleTranslateIcon filled={true} />
                    </span>
                    <span className='font-thin'>{productData?.lang}</span>
                  </span>
                  <span className='border border-l  border-textGray my-1 ' />
                  <span className='flex items-center  gap-x-4'>
                    <span className=' text-gray-300  text-lg md:text-xl lg:text-2xl'>
                      <BookOutlined />
                    </span>
                    <span className=' text-sm md:text-base lg:text-xl font-thin'>
                      {productData?.type}
                    </span>
                  </span>
                  <span className='border border-l  border-textGray my-1 ' />
                  <span className='flex items-center  gap-x-4'>
                    <span className=' text-gray-300  text-lg md:text-xl'>
                      <FileOutlined />
                    </span>
                    <span className='whitespace-nowrap  text-sm md:text-xl font-thin'>
                      {productData?.totalPages} Pages
                    </span>
                  </span>
                </div>
                <span className='flex gap-x-4 mt-5'>
                  <ShouldRender check={isShortBook}>
                    <ButtonDefault
                      size={1}
                      variant={ButtonVariants.WHITE}
                      className='w-min'
                      rounded={true}
                      roundedSm={true}
                    >
                      <span className='flex flex-col text-sm font-bold px-6 py-0.5 whitespace-nowrap'>
                        BUY HARDCOPY FOR <p>{productData?.hardCopyPrice} </p>
                      </span>
                    </ButtonDefault>
                    <ButtonDefault
                      size={1}
                      variant={ButtonVariants.UNSTYLED}
                      className='w-min bg-grayButtonBg '
                      rounded={true}
                      roundedSm={true}
                    >
                      <span className='flex flex-col text-sm font-bold px-6 py-0.5 text-fadedWhite whitespace-nowrap'>
                        BUY HARDCOPY FOR <p>{productData?.hardCopyPrice} </p>
                      </span>
                    </ButtonDefault>
                  </ShouldRender>
                  <ShouldRender check={productData?.bookType === 'short'}>
                    <ButtonDefault
                      size={4}
                      variant={ButtonVariants.WHITE}
                      roundedSm={true}
                      className='w-full rounded-lg'
                      onClick={() => {
                        if (token) {
                          router.push(
                            `/pdf/${productData?.bookLSample}?samplePdf=true`,
                          );
                        } else {
                          setVisible((prevState) => ({
                            ...prevState,
                            signUp: true,
                            bookId: productData?.bookLSample as string,
                          }));
                        }
                      }}
                    >
                      <span className='flex flex-col font-bold px-6 py-1 md:py-0 lg:py-1 text-sm whitespace-nowrap'>
                        Read Now
                      </span>
                    </ButtonDefault>
                  </ShouldRender>
                  <ShouldRender check={productData?.bookType === 'comingSoon'}>
                    <ButtonDefault
                      size={4}
                      variant={
                        loading?.joined
                          ? ButtonVariants.UNSTYLED
                          : ButtonVariants.WHITE
                      }
                      roundedSm={true}
                      loading={loading.value}
                      className={`w-full rounded-lg ${
                        loading?.joined ? 'border border-[#737373]' : ''
                      }`}
                      onClick={() => {
                        if (token) {
                          loading?.joined ? showModal() : handleSubscribe();
                        } else {
                          if (productData?._id) {
                            setVisible((prevState) => ({
                              ...prevState,
                              signUp: true,
                              comingSoon: productData?._id as string,
                              noRedirect: true,
                            }));
                          }
                        }
                      }}
                    >
                      <span className='flex flex-col font-bold px-6 py-1 md:py-0 lg:py-1 text-sm whitespace-nowrap'>
                        {loading?.joined ? 'Joined' : 'Join Waitlist'}
                      </span>
                    </ButtonDefault>
                  </ShouldRender>
                </span>
              </div>
            </span>
          </span>
          <ShouldRender check={screens.md}>
            <span className='w-full relative'>
              <div className={clsx('flex  h-full', !isBundle && 'items-end')}>
                <div className='flex justify-center w-full'>
                  <ShouldRender check={isBundle}>
                    <div className='flex items-center '>
                      <div className='md:h-[500px]'>
                        {' '}
                        {/* <Image
                        src={productData?.bookImage as string}
                        height={500}
                        width={500}
                        alt='BookImage'
                        className='object-contain w-full h-full'
                      /> */}
                      </div>
                    </div>
                  </ShouldRender>
                  <ShouldRender check={!isBundle && productData?.image}>
                    <div className='flex justify-center w-full pb-2'>
                      <div className='w-[250px] lg:w-[380px] lg:h-[500px] rounded-xl '>
                        <Image
                          src={productData?.image as string}
                          alt='BookImage'
                          height={560}
                          width={380}
                          className='object-contain w-full h-full rounded-xl'
                        />
                      </div>
                    </div>
                  </ShouldRender>
                </div>
              </div>
            </span>
          </ShouldRender>
        </div>
      </div>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width='400px'
        className='bg-[#212121] rounded-lg'
        closable={false}
        centered={true}
        destroyOnClose={true}
      >
        <div className='flex flex-col'>
          <div className='py-5'>
            Would you like to remove the Product from the Waitlist?
          </div>
          <div className='flex gap-x-1 justify-end'>
            <span>
              <Button onClick={handleCancel} type='text'>
                No
              </Button>
            </span>
            <span>
              <Button
                onClick={handleSubscribe}
                type='text'
                className='text-blue-500 font-medium'
              >
                Yes
              </Button>
            </span>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default Products;
