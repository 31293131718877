import { Progress } from 'antd';
import clsx from 'clsx';
import Image from 'next/image';

import { ShouldRender } from '../shared/basic/ShouldRender';
import encryptToken from '../shared/crypto/encryptToken';
import { formatDuration } from '../shared/formateTime';
import { CourseDummy } from '../../../assets';
import PlayIconCourse from '../../../assets/Icons/PlayIconCourse';

export interface Course {
  name: string;
  slug: string;
  duration?: number;
  course_image_url?: string;
  completionPercentage?: number;
  id?: string;
  seasonCount?: string;
}

interface Props {
  courses: Course[] | undefined;
  loading?: boolean;
  token: string | null;
  isMyCourse?: boolean;
  notBookLandingPage?: boolean;
}

const CoursesCard: React.FC<Props> = ({
  courses,
  token,
  isMyCourse = false,
  notBookLandingPage = true,
}) => {
  return (
    <span className='flex'>
      {courses?.map((item, index) => (
        <span
          key={index}
          onClick={() => {
            const CRYPTO_KEY = process.env.NEXT_PUBLIC_CRYPTO_KEY || '';
            const encryptedToken = encryptToken(token as string, CRYPTO_KEY);

            const slug = item?.slug;
            let url: string;
            if (isMyCourse) {
              url = `${process.env.NEXT_PUBLIC_COURSE_LIVE}/#/course/${slug}`;
            } else {
              url = `${process.env.NEXT_PUBLIC_COURSE_LIVE}/#/course/info/${slug}`;
            }

            if (slug) {
              const finalUrl = token
                ? `${url}?redirect_url=${encryptedToken}`
                : url;
              window.open(finalUrl, '_blank');
            }
          }}
          className={clsx(
            notBookLandingPage ? ' gap-y-6' : 'gap-y-3 md:gap-y-6',
            'flex flex-col p-1 md:p-1.5 cursor-pointer',
          )}
        >
          <span
            className={clsx(
              notBookLandingPage
                ? 'w-[200px] h-[100px] lg:max-w-[316px] lg:min-w-[300px] lg:max-h-[176px] lg:min-h-[150px]'
                : 'w-[160px] max-w-[160px] lg:w-[176px] lg:max-w-[176px]',
              ' relative',
            )}
          >
            <Image
              src={item?.course_image_url || CourseDummy}
              alt={item.name}
              className='rounded-md transition-transform transform hover:scale-[1.03] duration-500'
              width={320}
              height={170}
              sizes='(max-width: 768px) 100vw, 320px'
            />
            <ShouldRender check={!notBookLandingPage}>
              <div className='absolute bottom-2 md:bottom-3 left-2 md:left-3'>
                <PlayIconCourse />
              </div>
            </ShouldRender>
          </span>
          <div className='flex flex-col '>
            <ShouldRender
              check={
                item?.completionPercentage && item?.completionPercentage !== 0
              }
            >
              <span className='flex gap-x-2 text-sm'>
                <Progress
                  percent={item?.completionPercentage}
                  showInfo={false}
                  className='customProcessBar'
                />{' '}
                {item?.completionPercentage}%
              </span>
            </ShouldRender>
            <ShouldRender check={item.duration && notBookLandingPage}>
              <span className='text-textGrayColor text-xs md:text-sm '>
                {formatDuration(item?.duration as number)}
              </span>
            </ShouldRender>
            <span
              className={clsx(
                notBookLandingPage
                  ? 'w-[200px] lg:max-w-[316px] lg:min-w-[300px]'
                  : 'lg:max-w-[176px] max-w-[150px] min-w-[120px] md:min-w-[166px] truncate',
                'text-white text-sm xl:text-base font-medium  ',
              )}
            >
              {item.name}
            </span>
            <ShouldRender check={item.duration && !notBookLandingPage}>
              <span className='text-textGrayColor text-xs md:text-sm '>
                {formatDuration(item?.duration as number)}
              </span>
            </ShouldRender>
          </div>
        </span>
      ))}
    </span>
  );
};

export default CoursesCard;
