import {
  Analytics,
  getAnalytics,
  isSupported as analyticsIsSupported,
} from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  isSupported as messagingIsSupported,
  Messaging,
} from 'firebase/messaging';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const initializeAnalytics = async (): Promise<Analytics | null> => {
  if (typeof window !== 'undefined' && (await analyticsIsSupported())) {
    const analytics = getAnalytics(app);

    return analytics;
  }
  return null;
};

export const initializeMessaging = async (): Promise<Messaging | null> => {
  if (typeof window !== 'undefined' && (await messagingIsSupported())) {
    const messagingInstance = getMessaging(app);

    try {
      if ('serviceWorker' in navigator) {
        await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        console.log('Service worker registered successfully for messaging.');
      }
    } catch (error) {
      console.error(
        'Failed to register the service worker for messaging:',
        error,
      );
    }

    return messagingInstance;
  }
  return null;
};
