import {
  ArrowRightIcon,
  CheckCircleIcon,
  CheckIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import { LockClosedIcon } from '@heroicons/react/solid';
import { Button, Divider, Input, Skeleton, Tooltip } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import confetti from 'canvas-confetti';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { Montserrat } from 'next/font/google';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { showLoading } from './Step_1_OrderCard';
import { Category, formatItem, Type } from './Step_3_orderCard';
import ButtonDefault, { ButtonVariants, Spinner } from '../shared/basic/button';
import { ShouldRender } from '../shared/basic/ShouldRender';
import { parseFormattedNumber } from '../shared/parseFormattedNumber';
import { cartAtom, checkCouponAtom } from '../../atoms/Cart';
import { disabledState } from '../../atoms/disableState';
import { placeOrderAtom } from '../../atoms/placeOrder';
import { CartItem } from '../../utils/interfaces/cart';

const montserrat = Montserrat({
  subsets: ['latin'],
  fallback: ['Arial', 'sans-serif'],
});

interface Props {
  data: CartItem[];
  total: number;
  amount: {
    subTotal: number;
    discount: string;
    total: number;
    tax: number;
    actualCartTotal: number;
    couponCode: { coupon_code: string }[];
  };
  handleAddCoupon: (value: string) => void;
  setCurrentStep?: (value: number) => void;
  removeCoupon: (value: string) => void;
  removeLoading: boolean;
  couponMessage: { message: string; type: string };
  orderLoading: boolean;
  loading: boolean;
  couponApplyLoading: { value: boolean; isSuccess: boolean };
}

interface CartErrorCoupon {
  coupon_code: string;
  error: string;
}

const Step_2_OrderCard: React.FC<Props> = ({
  data,
  handleAddCoupon,
  amount,
  couponMessage,
  removeCoupon,
  removeLoading,
  orderLoading,
  loading,
  couponApplyLoading,
}) => {
  const cartData = useRecoilValue(cartAtom);
  const [couponData] = useRecoilState(checkCouponAtom);
  const [placeOrder, setPlaceOrder] = useRecoilState(placeOrderAtom);
  const isDisabled = useRecoilValue(disabledState);
  const [showCheckIcon, setShowCheckIcon] = useState(false);

  const router = useRouter();
  const isCheckoutPage = router.asPath?.includes('/checkout');
  const ShippingPrice = 199;
  const DigitalCopyPrice = 990;

  const handleScrollToBottom = () => {
    if (typeof window !== undefined) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      });
    }
  };
  const screens = useBreakpoint();

  const [couponCode, setCouponCode] = useState('');

  useEffect(() => {
    if (couponMessage.type === 'success') {
      setShowCheckIcon(true);
      const timer = setTimeout(() => setShowCheckIcon(false), 2000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [couponMessage.type]);

  const handleClick = () => {
    if (handleAddCoupon) {
      handleAddCoupon(couponCode);
      setCouponCode('');
    }
  };

  useEffect(() => {
    if (couponMessage.type === 'success') {
      setShowCheckIcon(true);
      const confettiOrigin = screens.lg
        ? { x: 0.8, y: 0.5 }
        : { x: 0.5, y: 0.8 };

      confetti({
        particleCount: 30,
        spread: 70,
        origin: confettiOrigin,
        colors: ['#ffffff', '#FFD700', '#FF69B4', '#87CEEB', '#32CD32'],
      });

      const timer = setTimeout(() => {
        setShowCheckIcon(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
    return undefined;
  }, [couponMessage.type, screens.lg]);

  const tooltipContent =
    'Ensure all necessary fields are filled with accurate validations.';

  const parseNumber = (str: string) => parseFloat(str?.replace(/,/g, ''));

  const totalNumber = parseNumber(amount.total?.toString());

  const savings = -(totalNumber - amount.actualCartTotal);
  const savingsPercentage = isFinite((savings / amount.actualCartTotal) * 100)
    ? Math.round((savings / amount.actualCartTotal) * 100)
    : 0;

  const renderButton = () => (
    <div className='bg-bgColor border-t-[1px]  relative  w-full lg:border-none border-[#2A2A2A] md:px-12 lg:px-0 items-center translate-y-1 p-2 xs:p-4 lg:p-0  flex'>
      <ShouldRender check={!screens.lg}>
        <div
          className={clsx(
            loading && 'gap-y-2',
            'w-full flex flex-col  text-white no-select',
          )}
        >
          <ShouldRender check={!loading && savingsPercentage}>
            <span className='bg-[#002618] text-[#00A86B] flex items-end gap-x-1 font-semibold w-fit px-2 rounded-md py-1'>
              <div className='flex items-center -translate-y-[4px]'>
                <motion.div
                  animate={{
                    y: [0, -0.4, 0],
                  }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    repeatType: 'mirror',
                  }}
                >
                  <ArrowRightIcon
                    className='w-2.5 rotate-90'
                    style={{ strokeWidth: '20px' }}
                  />
                </motion.div>
              </div>
              <p className='text-[10px]'>{savingsPercentage}%</p>
              <p className='uppercase text-[9px]'>saved</p>
            </span>
          </ShouldRender>

          {showLoading(
            <>
              <span className='flex items-end gap-x-2'>
                <p className='text-base font-semibold whitespace-nowrap'>
                  ₹ {Math.round(parseFormattedNumber(amount.total?.toString()))}
                </p>
                <ShouldRender check={amount.actualCartTotal}>
                  <p className='line-through text-sm font-thin text-[#737373]'>
                    ₹{amount.actualCartTotal}
                  </p>
                </ShouldRender>
              </span>
            </>,
            loading,
          )}

          <span
            onClick={handleScrollToBottom}
            className='text-[10px] xs:text-xs text-[#0055D6] font-medium hover:underline no-select'
          >
            View Price Details
          </span>
        </div>
      </ShouldRender>

      <div className='w-full xl:px-1 pl-2 xxs:pl-4 lg:pl-0 py-1'>
        <ButtonDefault
          size={screens.md ? 4 : 1}
          rounded={false}
          roundedSm={true}
          disabled={placeOrder || orderLoading || loading}
          id='secure_checkout'
          enableScaling={false}
          loading={placeOrder || orderLoading}
          variant={ButtonVariants.WHITE}
          onClick={() => {
            setPlaceOrder(true);
          }}
          icon={
            <span className='inline-block'>
              <LockClosedIcon
                className={clsx(
                  placeOrder || orderLoading || loading
                    ? 'mr-2 my-1'
                    : 'mr-2 md:mr-0',
                  'w-3.5 md:w-4 md:h-4  text-inherit',
                )}
              />
            </span>
          }
          shimmer={!(placeOrder || orderLoading || loading)}
          className='w-full text-center text-sm bg-fadedWhite disabled:cursor-not-allowed  hover:bg-gray-100 text-black md:p-2 disabled:bg-opacity-50 disabled:text-[#3E3E3E]'
        >
          {' '}
          <div className='flex justify-center items-center gap-x-1'>
            {' '}
            <span className='text-xs md:text-[14px] w-full uppercase tracking-normal lg:tracking-wider  font-bold whitespace-nowrap'>
              secure checkout
            </span>
          </div>
        </ButtonDefault>
      </div>
    </div>
  );

  const renderTooltip = () => (
    <Tooltip title={tooltipContent} placement='bottom' arrow>
      {renderButton()}
    </Tooltip>
  );

  const affixContent = (
    <>
      <div
        className={clsx(
          !screens.lg && 'mb-6',
          'bg-[#101010]  rounded-lg w-full',
        )}
      >
        <div className='flex gap-y-2 flex-col pt-5 md:pt-8 px-4 '>
          <span className='text-lg font-semibold tracking-wider'>
            ORDER SUMMARY
          </span>
          <div className='  flex flex-col gap-y-4 mt-3 md:mt-5'>
            <ShouldRender check={!loading}>
              {(cartData.length ? cartData : data).map((item) => {
                return (
                  <div key={item.id} className='flex items-center gap-5'>
                    <div
                      className={clsx(
                        item?.itemType?.toUpperCase() === 'BOOK'
                          ? 'px-2 pt-[5px] pb-[1px] items-end'
                          : 'p-2 ',
                        'w-20  flex  bg-imageCardBgColorCart rounded-md',
                      )}
                    >
                      <Image
                        src={item.image}
                        alt='Product'
                        width={160}
                        height={160}
                        className='object-contain w-full h-full'
                      />
                    </div>
                    <div className='flex justify-between items-center gap-x-3 md:gap-x-5 w-full'>
                      <div className='flex flex-col gap-y-[2px]'>
                        <div>
                          <div className='flex text-textGrayColor font-medium text-xs  gap-2'>
                            <span className=''>Qty. </span>
                            <span className=''>0{item.quantity}</span>
                          </div>
                        </div>
                        <span className='text-xs md:text-base leading-tight'>
                          {item.name}
                        </span>
                        <span className='font-medium text-[10px] mt-1 text-textGrayColor'>
                          {formatItem(
                            item?.category as Category,
                            item.itemType as Type,
                          )?.toUpperCase()}
                        </span>
                      </div>
                      <div className='flex justify-end flex-col items-end'>
                        <div className='font-light text-[12px] md:text-sm whitespace-nowrap  text-textGrayColor line-through '>
                          {item?.currency} {item?.actualPrice}
                        </div>
                        <div className='font-medium text-[14px] md:text-base text-white whitespace-nowrap'>
                          {item?.currency} {item?.price}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </ShouldRender>

            <ShouldRender check={loading}>
              <div className='flex gap-x-4 overflow-y-auto'>
                {/* Avatar Skeleton */}
                <Skeleton.Button
                  active
                  shape='square'
                  size='small'
                  className='rounded-md w-[52px] h-[52px]'
                />

                {/* Name Skeleton */}
                <div className='w-full flex flex-col'>
                  <span className='w-1/3'>
                    <Skeleton.Button
                      active
                      shape='square'
                      size='small'
                      className='h-3 w-full'
                    />
                  </span>

                  <Skeleton.Input
                    active
                    size='small'
                    style={{ width: '80%' }}
                    className='h-5'
                  />
                </div>
                <div className='text-sm flex w-1/2 items-center text-textDarkGrayColor'>
                  <Skeleton.Button
                    active
                    size='small'
                    className='h-full py-2'
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            </ShouldRender>
          </div>
        </div>
        <div
          className={clsx(
            isCheckoutPage ? 'py-5 md:py-7 lg:py-3 xl:py-7 ' : 'py-5 md:py-7',
            'px-3 ',
          )}
        >
          <Input
            className='md:border-[2px] text-sm placeholder:text-xs placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray customInputBlack py-1 text-textDarkGrayColor bg-bgColor'
            suffix={
              <div
                className={clsx(
                  couponMessage.type === 'error' && 'shaking-animation',
                )}
              >
                <div className='relative'>
                  <Button
                    onClick={handleClick}
                    className={clsx(
                      !couponApplyLoading.value && 'translate-x-[6px]',
                      'text-white tracking-wider font-medium border-none placeholder:text-[#6E6E6E] placeholder:font-medium hover:text-white bg-cartBgColor px-6 md:px-8 text-xs transition-all duration-300 ease-in-out',
                    )}
                  >
                    {couponApplyLoading.value ? (
                      <span className='transition-opacity duration-300 ease-in-out opacity-100 translate-x-1'>
                        <Spinner />
                      </span>
                    ) : (
                      <>
                        {showCheckIcon ? (
                          <CheckIcon className='text-white w-5 transition-transform duration-300 ease-in-out transform scale-100' />
                        ) : (
                          <span className='transition-opacity duration-300 ease-in-out opacity-100'>
                            APPLY
                          </span>
                        )}
                      </>
                    )}
                  </Button>
                </div>
              </div>
            }
            style={{
              background: '#000000',
            }}
            placeholder='Enter your coupon code'
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          <ShouldRender check={couponMessage?.message}>
            <span
              className={`${
                couponMessage.type === 'error'
                  ? 'text-red-500'
                  : 'text-green-600 '
              } pt-2 text-sm flex items-center gap-x-1`}
            >
              <ShouldRender check={couponMessage.type === 'error'}>
                <ExclamationCircleIcon className='w-4 h-4' />
              </ShouldRender>
              <ShouldRender check={couponMessage.type === 'success'}>
                <CheckCircleIcon className='w-4 h-4' />
              </ShouldRender>{' '}
              {couponMessage.message}
            </span>
          </ShouldRender>
        </div>

        <div
          className={clsx(
            isCheckoutPage ? 'gap-y-2 lg:gap-y-5 ' : ' gap-y-5 ',
            'flex flex-col  pb-5',
          )}
        >
          <div className='flex justify-between px-6 text-sm'>
            <span className='text-[#6E6E6E] font-semibold'>Cart Total</span>
            {showLoading(
              <>
                <span className='font-semibold text-base'>
                  ₹ {amount?.actualCartTotal}
                </span>
              </>,
              loading,
            )}
          </div>

          {/* <div className='mx-6  border-b border-dropDownMenuBg border-2 border-dashed'></div> */}
          <ShouldRender check={amount.discount !== '0.00' && !loading}>
            {amount?.couponCode?.map((coupon) => {
              return (
                <span
                  key={coupon.coupon_code}
                  className='flex justify-between px-6'
                >
                  <span className='text-[#6E6E6E] text-sm font-semibold'>
                    {' '}
                    Coupon Applied ({coupon.coupon_code}){' '}
                  </span>

                  <ButtonDefault
                    size={1}
                    variant={ButtonVariants.UNSTYLED}
                    loading={removeLoading}
                    disabled={removeLoading}
                    onClick={() => {
                      if (removeCoupon) {
                        removeCoupon(coupon.coupon_code);
                      }
                    }}
                    className='font-medium text-red-500 hover:bg-bgColor hover:text-red-400'
                  >
                    Remove
                  </ButtonDefault>
                </span>
              );
            })}
            <span className='text-[#6E6E6E] font-medium  px-6 flex justify-between'>
              Coupon Code Discount{' '}
              <p className='text-white font-semibold'>- ₹ {amount.discount}</p>
            </span>
          </ShouldRender>
          {/* <span className='flex justify-between text-primary'>
  Z-coins discount <p className=''> 0</p>
</span> */}
          <ShouldRender
            check={!cartData?.every((item) => item.type === 'digital')}
          >
            <ShouldRender check={router.pathname.includes('checkout')}>
              <span className='text-[#6E6E6E] text-sm  font-medium  pl-6 '>
                Digital Copy
                <span className='text-[#6E6E6E] text-sm mt-2 font-medium  pl-3 pr-6 flex gap-x-2 justify-between'>
                  <span className='flex gap-x-1.5 items-center'>
                    •{' '}
                    <span className='text-xs'>
                      {cartData[0]?.digitalCopyName ?? cartData[0]?.name}
                    </span>
                  </span>{' '}
                  {showLoading(
                    <>
                      <span className='text-white font-semibold whitespace-nowrap flex gap-x-2'>
                        <p className='line-through'>₹ {DigitalCopyPrice}</p>{' '}
                        <p className='text-[#FFC700] uppercase'>Free</p>
                      </span>
                    </>,
                    loading,
                  )}
                </span>
              </span>
            </ShouldRender>

            <span className='text-[#6E6E6E] text-sm  font-medium whitespace-nowrap px-6 flex gap-x-2 justify-between'>
              Shipping Charges{' '}
              {showLoading(
                <>
                  <span className='text-white font-semibold flex gap-x-2'>
                    <p className='line-through'>₹ {ShippingPrice}</p>{' '}
                    <p className='text-[#FFC700] uppercase'>Free</p>
                  </span>
                </>,
                loading,
              )}
            </span>
          </ShouldRender>

          <div className='flex justify-between px-6 text-sm'>
            <span className='text-[#6E6E6E] font-semibold'>Total Discount</span>
            {showLoading(
              <>
                <span className='font-semibold text-green-500 text-base'>
                  - ₹{' '}
                  {amount?.actualCartTotal -
                    parseFormattedNumber(amount?.total?.toString())}
                </span>
              </>,
              loading,
            )}
          </div>
          <ShouldRender check={couponData?.length !== 0 && !loading}>
            {couponData?.map((item: CartErrorCoupon, index: number) => {
              return (
                <span
                  key={index}
                  className='flex gap-x-2 text-yellow-500 items-center'
                >
                  <ExclamationCircleIcon className='w-4 h-4' />
                  {item?.coupon_code} {item.error} , you can try adding again!
                </span>
              );
            })}
          </ShouldRender>
          <Divider
            style={{
              margin: '2px 0',
              color: '#1C1C1C',
              border: '1px solid #1C1C1C',
            }}
            className='text-cartBgColor bg-cartBgColor'
          />
          <div className={montserrat.className}>
            <span className='flex justify-between text-lg font-medium px-6 text-white '>
              To Pay{' '}
              {showLoading(
                <>
                  <p className='text-lg font-semibold'>
                    ₹ {parseFormattedNumber(amount?.total?.toString())}
                  </p>
                </>,
                loading,
              )}
            </span>
          </div>
        </div>
      </div>
      <div
        className={clsx(!screens.lg && 'fixed bottom-0 left-0 z-40', 'w-full ')}
      >
        {!isDisabled ? renderTooltip() : renderButton()}
      </div>
    </>
  );

  return (
    <div
      className={clsx(
        isCheckoutPage ? 'pb-4' : 'md:py-4',
        ' text-fadedWhite px-4 lg:px-0  w-full  flex flex-col gap-y-2  rounded-md cardShadow ',
      )}
    >
      <div
        className={clsx(
          isCheckoutPage ? 'gap-y-2 xl:gap-y-5' : 'gap-y-5',
          'flex flex-col mb-8 md:px-8 lg:px-4 w-full',
        )}
      >
        {affixContent}
      </div>
    </div>
  );
};

export default Step_2_OrderCard;
