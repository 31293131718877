import React from 'react';

interface CustomIconProps {
  filled?: boolean;
}

const ZebralearnSquareIcon: React.FC<CustomIconProps> = () => (
  <svg
    width='21'
    height='20'
    viewBox='0 0 21 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_4099_25979)'>
      <path
        d='M0.533203 0.0707535L2.86221 2.1871L5.92306 2.19947L7.49532 0.0283203L0.533203 0.0707535Z'
        fill='#979797'
      />
      <path
        d='M0.53125 0.0688477V9.30506L2.86026 2.18519L0.53125 0.0688477Z'
        fill='#EFEFEF'
      />
      <path
        d='M2.86026 2.18628L5.92109 2.19865L0.53125 9.30615L2.86026 2.18628Z'
        fill='white'
      />
      <path
        d='M9.53125 2.1873L11.3955 0.0957031H16.7978L12.2502 2.19966L9.53125 2.1873Z'
        fill='#979797'
      />
      <path
        d='M9.53111 2.18628L12.2501 2.19865L3.36137 12.9112L0.580078 12.9448L9.53111 2.18628Z'
        fill='#B2B2B2'
      />
      <path
        d='M0.583005 12.9447L3.36429 12.9111L0.552734 19.8949L0.583005 12.9447Z'
        fill='#EFEFEF'
      />
      <path
        d='M3.36429 12.9122L12.253 2.19966L16.8006 0.0957031L0.552734 19.8959L3.36429 12.9122Z'
        fill='white'
      />
      <path
        d='M4.22852 19.8514L8.89544 17.5266L17.8571 6.87415L20.521 0.0283203L4.22852 19.8514Z'
        fill='#979797'
      />
      <path
        d='M8.89544 17.5251L11.5984 17.6985L9.69315 19.8413L4.22852 19.8501L8.89544 17.5251Z'
        fill='#EFEFEF'
      />
      <path
        d='M20.5193 0.0283203L17.8555 6.87414H20.5602L20.5193 0.0283203Z'
        fill='#EFEFEF'
      />
      <path
        d='M17.8543 6.87402H20.559L11.5955 17.6997L8.89258 17.5265L17.8543 6.87402Z'
        fill='white'
      />
      <path
        d='M15.2285 17.6404H18.1613L20.4849 10.655L15.2285 17.6404Z'
        fill='#979797'
      />
      <path
        d='M15.2279 17.6406H18.1605L20.527 19.8931L13.5488 19.8842L15.2279 17.6406Z'
        fill='#EFEFEF'
      />
      <path
        d='M20.4858 10.6562L20.5286 19.8943L18.1621 17.6416L20.4858 10.6562Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_4099_25979'>
        <rect width='20' height='20' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
);

export default ZebralearnSquareIcon;
