import React from 'react';

const BoltIcon: React.FC = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.4453 5.33203H8.66663L9.20397 2.1087C9.21996 2.01313 9.21492 1.91523 9.18921 1.8218C9.1635 1.72838 9.11773 1.64169 9.0551 1.56776C8.99246 1.49383 8.91447 1.43444 8.82654 1.39373C8.73861 1.35302 8.64286 1.33196 8.54597 1.33203H5.1113C4.95359 1.33208 4.80101 1.38803 4.68066 1.48995C4.56031 1.59187 4.47999 1.73315 4.45397 1.8887L3.34263 8.55536C3.32666 8.65088 3.33168 8.74872 3.35735 8.8421C3.38302 8.93547 3.42873 9.02213 3.49129 9.09605C3.55385 9.16997 3.63176 9.22937 3.71961 9.27012C3.80746 9.31088 3.90313 9.332 3.99997 9.33203H6.66663V14.6654L12.0066 6.35936C12.0714 6.25861 12.1079 6.14226 12.1122 6.02254C12.1165 5.90282 12.0884 5.78416 12.031 5.67904C11.9735 5.57391 11.8888 5.48621 11.7858 5.42514C11.6827 5.36408 11.5651 5.33191 11.4453 5.33203Z'
        fill='url(#paint0_linear_3204_55654)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_3204_55654'
          x1='7.72306'
          y1='1.33203'
          x2='7.72306'
          y2='14.6654'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F9742C' />
          <stop offset='1' stopColor='#F20486' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BoltIcon;
