import React from 'react';

interface CustomIconProps {
  filled?: boolean;
}

const Star: React.FC<CustomIconProps> = () => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.06215 1.5345C5.38431 0.663886 6.61569 0.663888 6.93785 1.53451L7.73106 3.67812C7.83235 3.95184 8.04816 4.16765 8.32188 4.26894L10.4655 5.06215C11.3361 5.38431 11.3361 6.61569 10.4655 6.93785L8.32188 7.73106C8.04816 7.83235 7.83235 8.04816 7.73106 8.32188L6.93785 10.4655C6.61569 11.3361 5.38431 11.3361 5.06215 10.4655L4.26894 8.32188C4.16765 8.04816 3.95184 7.83235 3.67812 7.73106L1.5345 6.93785C0.663886 6.61569 0.663888 5.38431 1.53451 5.06215L3.67812 4.26894C3.95184 4.16765 4.16765 3.95184 4.26894 3.67812L5.06215 1.5345Z'
      fill='white'
    />
  </svg>
);

export default Star;
