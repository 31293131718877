import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { Form, Input, Progress, RadioChangeEvent, Spin } from 'antd';
import { RuleObject } from 'antd/es/form';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { AppwriteException } from 'appwrite';
import clsx from 'clsx';
import disposable from 'disposable-email';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import CloseModal from './CloseModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import LoginModal from './LoginModal';
import LoginModalCheckout from './LoginModalCheckout';
import PaymentInfo from './PaymentInfo';
import { usePayment } from './RazorPay';
import { calculatePasswordStrengthV2 } from '../Auth/SignUp';
import { ShouldRender } from '../shared/basic/ShouldRender';
import { CustomSelect } from '../shared/CustomSelect';
import { formateErrorObject } from '../shared/formateErrorObject';
import { getLoginToken } from '../shared/getToken';
import { notify } from '../shared/notify';
import { cartAtom, orderDetails } from '../../atoms/Cart';
import { disabledState } from '../../atoms/disableState';
import { localCart } from '../../atoms/LocalCart';
import { loginCollapseAtom } from '../../atoms/loginCollapse';
import { placeOrderAtom } from '../../atoms/placeOrder';
import { user } from '../../atoms/user';
import { useAddresses } from '../../hooks/useAddresses';
import { useAuth } from '../../hooks/useAuth';
import { EventDetails, logEvent } from '../../hooks/useClickEventTracking';
import { createAddressService, updateAddress } from '../../services/Address';
import {
  checkEmailExist,
  getCartCount,
  placeOrder,
  updateCartAddress,
} from '../../services/Cart';
import {
  Address,
  AddressPayload,
} from '../../utils/interfaces/Manage-Addresses';
import { User } from '../../utils/interfaces/User';

interface IAmount {
  subTotal: number;
  discount: string;
  total: number;
  tax: number;
  couponCode: { coupon_code: string }[];
}

type FieldType = string;
type FieldValue = string | number;
interface CartContactFormProps {
  setCurrentStep?: (value: number) => void;
  amount: IAmount;
  token: string;
  setOrderLoading: (value: boolean) => void;
  orderLoading?: boolean;
  setProduct: () => void;
}

type SwitchKeyType =
  | 'updateAddress'
  | 'createAddressAndPlaceOrder'
  | 'assignAddressAndPlaceOrder'
  | 'signUpAndCreateAddressAndPlaceOrder';

interface CartContactFormState {
  visibleLogin: boolean;
  visibleForgotPassword: boolean;
  selectedAddress: Address | null;
  isChanged: boolean;
  updateLoading: boolean;
  paymentOption?: 'razorpay' | 'cash_on_delivery' | 'wallet';
  error: string | null;
  loginError: string;
  checkEmailLoading: boolean;
  checkEmailError: string;
}

interface IFormData {
  fullname: string;
  email: string;
  postalcode: string;
  phone: string;
  country: string;
  city: string;
  state: string;
  address: string;
  password: string;
  parentEmail?: string;
  parentName?: string;
  parentPhone?: string;
}

const STAGING_MATHS_PRODUCT_IDS = [
  100815, 100644457, 100815, 10064445, 100815, 1006444, 100688,
];
const LIVE_MATHS_PRODUCT_IDS = [
  104744, 104745, 104739, 104741, 104729, 104730, 104743, 104742,
];

const CartContactForm: React.FC<CartContactFormProps> = ({
  amount,
  token: tokenChangeTrigger,
  setOrderLoading,
  orderLoading,
  setProduct,
}) => {
  const [form] = Form.useForm();

  const [token, setToken] = useState('');
  const [, setLocalCartCount] = useRecoilState(localCart);
  const [visible, setVisible] = useState(false);
  const [cartDataResponse, setCartDataResponse] = useState<any>(null);
  // const [currentLocationLoading, setCurrentLocationLoading] =
  //   useState<boolean>(false);
  const [passwordStrength, setPasswordStrength] = useState<number>(0);

  const [placeOrderTrigger, setPlaceOrderTrigger] =
    useRecoilState(placeOrderAtom);
  const [, setLoginCollapseAtom] = useRecoilState(loginCollapseAtom);
  const [order, setOrderData] = useState<{
    amount: IAmount;
    razorPayId: string;
    orderId: string;
  }>();
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);

  const [, setOrderDetail] = useRecoilState<{
    orderId: string;
    transactionId: string;
  }>(orderDetails);

  const [state, setState] = useState<CartContactFormState>({
    visibleLogin: false,
    visibleForgotPassword: false,
    selectedAddress: null,
    isChanged: false,
    updateLoading: false,
    paymentOption: 'razorpay',
    error: null,
    loginError: '',
    checkEmailLoading: false,
    checkEmailError: '',
  });
  const [openLoginModalCheckout, setLoginModalCheckout] = useState(false);

  const {
    visibleLogin,
    visibleForgotPassword,
    selectedAddress,
    isChanged,
    paymentOption,
  } = state;
  const screens = useBreakpoint();

  const {
    getAllCountries,
    countries,
    getStates,
    states,
    cities,
    getCities,
    fieldLoading,
    getAllAddresses,
    address,
    loading,
    updateAddressOnBlur,
    getCartPrefilledData,
    updateAddressOnBlurParent,
  } = useAddresses();
  const { initializeRazorpay } = usePayment();

  const { onSignUp, syncCart } = useAuth();

  const router = useRouter();
  const userData: User | undefined = useRecoilValue(user);
  const [, setDisabled] = useRecoilState(disabledState);
  const [cartData, setCartData] = useRecoilState(cartAtom);
  const startTimeRef = useRef<Date | null>(null);
  const [hasFetchedCartData, setHasFetchedCartData] = useState(false);
  const [formData, setFormData] = useState<IFormData>({
    fullname: '',
    email: '',
    postalcode: '',
    phone: '',
    country: '',
    city: '',
    state: '',
    address: '',
    password: '',
  });

  const isCheckoutPage = router.asPath.includes('/checkout');
  const environment = process.env.NEXT_PUBLIC_APP_ENV;
  const { cartId } = router.query;

  useEffect(() => {
    getAllCountries();
  }, [selectedAddress]);

  const fetchData = async () => {
    try {
      const token = await getLoginToken();
      setToken(token as string);
    } catch (error) {
      console.error('Error fetching token:', error);
      throw error;
    }
  };

  const handleApiCall = async (
    field: FieldType,
    value: FieldValue,
  ): Promise<void> => {
    await updateAddressOnBlur({
      cart_id: (cartData[0]?.cartId ?? cartId) as number,
      fieldName: field,
      fieldValue: value,
      zl_uid: userData?.zl_uid,
    });
  };

  const handleParentApiCall = async (
    field: FieldType,
    value: FieldValue,
  ): Promise<void> => {
    await updateAddressOnBlurParent({
      cart_id: cartData[0]?.cartId as number,
      fieldName: field,
      fieldValue: value,
      zl_uid: userData?.zl_uid,
    });
  };

  const hasMathsProduct = useMemo(() => {
    const PRODUCT_IDS =
      process.env.NEXT_PUBLIC_APP_ENV === 'production'
        ? LIVE_MATHS_PRODUCT_IDS
        : STAGING_MATHS_PRODUCT_IDS;

    return cartData.some((item) =>
      PRODUCT_IDS.includes(item.product_id as number),
    );
  }, [cartData]);

  useEffect(() => {
    fetchData();
  }, [tokenChangeTrigger]);

  const triggerViewEvent = () => {
    const eventParams: EventDetails = {
      event_name: 'screen_view',
      source: 'global',
      source_type: 'check_out',
      source_id: null,
      sub_source: null,
      sub_source_id: null,
      unit: 'boolean',
      value: 1,
    };

    logEvent({
      eventDetails: eventParams,
      userData: userData,
      pathname: router.pathname,
    });

    if (typeof mixpanel !== 'undefined' && environment === 'production') {
      mixpanel.track(eventParams.event_name as string, eventParams);
    }

    startTimeRef.current = new Date();
  };

  const triggerSessionEvent = (start: Date, end: Date) => {
    const sessionDuration = (end.getTime() - start.getTime()) / 1000;

    const eventParams: EventDetails = {
      event_name: 'screen_session',
      source: 'global',
      source_type: 'check_out',
      source_id: null,
      sub_source: null,
      sub_source_id: null,
      unit: 'seconds',
      value: sessionDuration,
      activity_start: moment(start).format('YYYY-MM-DD HH:mm:ss'),
      activity_end: moment(end).format('YYYY-MM-DD HH:mm:ss'),
    };

    logEvent({
      eventDetails: eventParams,
      userData: userData,
      pathname: router.pathname,
    });
  };

  useEffect(() => {
    triggerViewEvent();

    return () => {
      const currentTime = new Date();
      if (startTimeRef.current) {
        triggerSessionEvent(startTimeRef.current, currentTime);
      }
    };
  }, []);

  const onCancelLoginCheckout = () => {
    setLoginModalCheckout(false);
  };

  useEffect(() => {
    if (formData?.country && countries) {
      const selectedCountry =
        typeof formData?.country === 'string'
          ? String(
              countries?.find((item) => item.label === formData.country)?.value,
            )
          : formData?.country;
      if (selectedCountry && selectedCountry !== 'undefined') {
        getStates(selectedCountry);
      }
    }
  }, [formData?.country, countries, selectedAddress]);

  useEffect(() => {
    if (formData?.state && states) {
      const selectedState =
        typeof formData?.state === 'string'
          ? String(states?.find((item) => item.label === formData.state)?.value)
          : formData?.state;
      if (selectedState && selectedState !== 'undefined') {
        getCities(selectedState);
      }
    }
  }, [formData?.state, states, selectedAddress]);

  const handlePaymentOptionChange = (e: RadioChangeEvent) => {
    setState((prevState) => ({ ...prevState, paymentOption: e.target.value }));
    setDisabled(isDisabled);
  };

  const handlePaymentOptionClick = (
    value: 'razorpay' | 'cash_on_delivery' | 'wallet',
  ) => {
    setState((prevState) => ({ ...prevState, paymentOption: value }));
    setDisabled(isDisabled);
  };

  const onCancelLogin = () => {
    setState((prevState) => ({ ...prevState, visibleLogin: false }));
  };
  const onCancelForgotPassword = () => {
    setState((prevState) => ({ ...prevState, visibleForgotPassword: false }));
  };

  const onCancelCloseModal = () => {
    setVisible(false);
    if (typeof window !== 'undefined') {
      localStorage.setItem('visible', 'false');
    }
  };

  const handleInput = useCallback(
    (fieldName: string, notIndia: boolean) =>
      async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target;
        const cleanedValue = value.replace(/\s/g, '');
        const sanitizedValue = notIndia
          ? cleanedValue.replace(/\D/g, '')
          : cleanedValue;

        setFormData({
          ...formData,
          [fieldName]: sanitizedValue,
        });

        // if (fieldName === 'postalcode' && /^[0-9]{6}$/.test(sanitizedValue)) {
        //   const { state, city } = await fetchStateAndCityByPostalCode({
        //     postalCode: sanitizedValue,
        //     country: formData.country,
        //   });
        //   handleApiCall('state_id', state);
        //   handleApiCall('city_id', city);

        //   setFormData((prev) => ({
        //     ...prev,
        //     state,
        //     city,
        //   }));

        //   form.setFieldsValue({
        //     state,
        //     city,
        //   });
        // }
      },
    [formData],
  );

  const getStrengthMessage = () => {
    if (passwordStrength >= 4) return 'Your password is strong!';
    return `Weak`;
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    form.setFieldsValue({ [name]: value });

    if (name === 'password') {
      const { strength } = calculatePasswordStrengthV2(value);
      setPasswordStrength(strength);
    }

    if (name === 'email' || name === 'parentEmail') {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      const domain = value.split('@')[1] || '';
      const isFakeEmail = domain ? !disposable.validate(domain) : false;
      if (isFakeEmail) {
        setDisabled(isDisabled);
      }

      form.setFields([
        {
          name,
          errors: isValidEmail
            ? isFakeEmail
              ? [
                  `Please enter a valid ${name === 'email' ? 'email' : 'parent email'} address!`,
                ]
              : []
            : [
                `Please enter a valid ${name === 'email' ? 'email' : 'parent email'} address!`,
              ],
        },
      ]);
    }
  };

  // const getValue = (
  //   data: { label: string; value: string | number }[] | undefined,
  //   key: string,
  // ) =>
  //   typeof formData[key as keyof typeof formData] === 'number'
  //     ? formData[key as keyof typeof formData]
  //     : String(
  //         data?.find(
  //           (item) => item.label === formData[key as keyof typeof formData],
  //         )?.value as string,
  //       );

  // const handleCreateCity = async (cityName: string, stateId: number) => {
  //   try {
  //     const cities = await createCity(cityName, stateId);

  //     return cities?.id;
  //   } catch (error: any) {
  //     const errorMessageToShow = formateErrorObject(error);
  //     notify(errorMessageToShow, 'error');
  //   }
  // };

  // const addCityIfNotExists = async (
  //   cityName: string,
  // ): Promise<string | number> => {
  //   const selectedState = await getValue(states, 'state');
  //   const cityId = await handleCreateCity(cityName, selectedState as number);

  //   return cityId;
  // };

  useEffect(() => {
    if (cartId) {
      handleApiCall('checkoutPage', 'true');
    }
  }, [cartId]);
  const getValue = (
    data: { label: string; value: string | number }[] | undefined,
    key: string,
  ) =>
    typeof formData[key as keyof typeof formData] === 'number'
      ? formData[key as keyof typeof formData]
      : String(
          data?.find(
            (item) => item.label === formData[key as keyof typeof formData],
          )?.value as string,
        );

  const handleSwitchCase = async (switchKey: SwitchKeyType) => {
    const selectedState = getValue(states, 'state');
    const selectedCity = getValue(cities, 'city');
    const selectedCountry = getValue(countries, 'country');

    const data = {
      user_id: userData?.id,
      name: formData?.fullname,
      address: formData?.address,
      country_id: selectedCountry as string,
      city_id: selectedCity as string,
      state_id: selectedState as string,
      postal_code: formData?.postalcode,
      phone: formData?.phone,
    };

    const eventParams: EventDetails = {
      event_name: 'begin_checkout',
      source: 'global',
      source_type: 'cart',
      source_id: cartData?.[0]?.cartId?.toString(),
      sub_source: null,
      sub_source_id: null,
      unit: 'INR',
      value: amount?.total || 0,
      meta: {
        payment_mode: paymentOption === 'razorpay' ? 'prepaid' : 'cod',
        coupon: amount.couponCode,
        discount: amount.discount,
      },
    };

    logEvent({
      eventDetails: eventParams,
      userData: userData,
      pathname: router.pathname as string,
    });

    const executeCase = async (
      action: () => Promise<void>,
      errorMessage?: string,
    ) => {
      setState((prevState) => ({
        ...prevState,
        updateLoading: true,
        error: null,
      }));

      try {
        await action();
        await getAllAddresses();
      } catch (error: any) {
        const errorMessageToShow = errorMessage || formateErrorObject(error);
        notify(errorMessageToShow, 'error');
        setState((prevState) => ({ ...prevState, error: errorMessageToShow }));
      } finally {
        setState((prevState) => ({
          ...prevState,
          updateLoading: false,
          error: null,
        }));
      }
    };

    switch (switchKey) {
      case 'updateAddress':
        if (address?.length && address?.[0]?.id && isChanged) {
          await executeCase(async () => {
            await updateAddress(
              {
                ...data,
                id: Number(address?.[0]?.id),
                set_default: 0,
              } as AddressPayload,
              Number(cartId),
            );
            await syncCart((data?.user_id || '')?.toString(), Number(cartId));
            await new Promise((resolve) => setTimeout(resolve, 2000));
            await handleUpdateCartAddress(Number(address?.[0]?.id), true);
            await handlePlaceOrder({
              paymentOption: paymentOption as string,
              cartId: Number(cartId),
            });
          }, 'Failed to update address.');
        }
        break;
      case 'createAddressAndPlaceOrder':
        if (token && !selectedAddress) {
          await executeCase(async () => {
            const resp = await createAddressService({
              ...data,
              user_id: data.user_id || 0,
              set_default: 0,
            });
            await syncCart((data?.user_id || '')?.toString(), Number(cartId));
            await handleUpdateCartAddress(Number(resp?.data?.id));
            await handlePlaceOrder({
              paymentOption: paymentOption as string,
              cartId: Number(cartId),
            });
          }, 'Failed to create address and place order.');
        }
        break;

      case 'assignAddressAndPlaceOrder':
        if (token && address?.length) {
          await executeCase(async () => {
            await syncCart((data?.user_id || '')?.toString(), Number(cartId));
            await handleUpdateCartAddress(Number(address[0]?.id));
            await handlePlaceOrder({
              paymentOption: paymentOption as string,
              cartId: Number(cartId),
            });
            fetchData();
          }, 'Failed to assign and place order.');
        }
        break;

      case 'signUpAndCreateAddressAndPlaceOrder':
        if (!token) {
          try {
            await onSignUp({
              email: formData.email,
              password: formData.password,
              name: formData.fullname,
              phone: formData.phone,
              isModal: true,
            });
            await new Promise((resolve) => setTimeout(resolve, 2000));
          } catch (error: any) {
            if (error instanceof AppwriteException) {
              notify(error.message, 'error');
              if (error.code === 409 && error.type === 'user_already_exists') {
                if (typeof window !== 'undefined') {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  setLoginCollapseAtom(true);
                }
              }
            }

            return;
          }

          await executeCase(async () => {
            const resp = await createAddressService({
              ...data,
              user_id: data.user_id || 0,
              set_default: 0,
            });
            await handleUpdateCartAddress(Number(resp?.data?.id));

            await handlePlaceOrder({
              paymentOption: paymentOption as string,
              name: formData.fullname,
              email: formData.email,
              cartId: Number(cartId),
            });
            fetchData();
          }, 'Failed to sign up, create address, and place order.');
        }
        break;

      default:
        break;
    }
  };

  const onFinish = async (switchKey?: SwitchKeyType) => {
    setOrderLoading(true);

    try {
      const validationErrors = {};
      await form.validateFields();
      if (form.getFieldError('email').length) {
        return;
      }
      if (state.checkEmailError === 'Your Email already exist' && !token) {
        if (typeof window !== 'undefined') {
          window.scrollTo({ top: 0, behavior: 'smooth' });

          setLoginCollapseAtom(true);
        }

        return;
      }

      if (Object.values(validationErrors).some((error) => error)) {
        return;
      }

      if (switchKey) {
        await handleSwitchCase(switchKey);
        setProduct();
      }
    } catch (error: any) {
      scrollToErrorField(error.errorFields);

      notify(formateErrorObject(error), 'error');
    } finally {
      setState((prevState) => ({ ...prevState, updateLoading: false }));
      setOrderLoading(false);
    }
  };

  const handleCheckEmailExist = async () => {
    try {
      setState((prevErrors) => ({
        ...prevErrors,
        checkEmailLoading: true,
      }));
      await handleApiCall('email', formData?.email);
      await checkEmailExist(formData?.email);

      setState((prevErrors) => ({
        ...prevErrors,
        checkEmailError: `Your Email already exist`,
      }));
    } catch (error: any) {
      setState((prevErrors) => ({
        ...prevErrors,
        checkEmailError: ``,
      }));
    } finally {
      setState((prevErrors) => ({
        ...prevErrors,
        checkEmailLoading: false,
      }));
    }
  };

  useEffect(() => {
    if (address?.length) {
      const isAddressChanged =
        address?.[0] &&
        Object.entries(address?.[0]).some(
          ([key, value]) => key !== 'email' && value !== (formData as any)[key],
        );
      setState((prevState) => ({
        ...prevState,
        isChanged: isAddressChanged as boolean,
      }));
    }
  }, [address, formData]);

  useEffect(() => {
    if (userData && address) {
      if (address.length > 0) {
        setFormData((prevData) => ({
          ...prevData,
          ...address[0],
          password: address[0]?.password || '',
        }));

        Object.entries(address[0] as object).forEach(([key, value]) => {
          form.setFieldsValue({ [key]: value });
        });
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        email: userData.email,
      }));
      form.setFieldsValue({ email: userData.email });
    }
  }, [selectedAddress, userData, address, form]);

  const formatCartData = (cartData: any) => {
    const mapping = {
      fullname: cartData.name,
      email: cartData.email,
      postalcode: cartData.postal_code,
      phone: cartData.phone,
      country: cartData.country_id,
      city: cartData.city_id,
      state: cartData.state_id,
      address: cartData.address,
      password: '',
      ...(cartData.options !== null && {
        parentEmail: cartData.others.parentEmail,
        parentName: cartData.others.parentName,
        parentPhone: cartData.others.parentPhone,
      }),
    };

    return Object.fromEntries(
      Object.entries(mapping).filter(([_, value]) => value),
    );
  };

  const fetchCartPrefillData = async () => {
    try {
      if (!cartData.length) return;

      if (cartData.length && cartData[0]?.cartId) {
        const cartPrefillData = await getCartPrefilledData(
          cartData[0]?.cartId as number,
        );
        const data = formatCartData(cartPrefillData);
        return data;
      }
      return;
    } catch (error) {
      console.error('Error fetching cart prefill data:', error);
      return;
    }
  };

  useEffect(() => {
    const finalizeFormSetup = async () => {
      if (userData) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          email: userData.email,
        }));
        form.setFieldValue('email', userData.email);
        getAllAddresses();
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        country: 'India',
      }));
      form.setFieldValue('country', 'India');

      if (!hasFetchedCartData && cartData.length > 0) {
        const fetchedCartData = await fetchCartPrefillData();
        if (fetchedCartData) {
          setCartDataResponse(fetchedCartData);
        }

        setHasFetchedCartData(true);
      }
    };

    finalizeFormSetup();
  }, [userData, hasFetchedCartData, cartData]);

  useEffect(() => {
    if (cartDataResponse && Object.keys(cartDataResponse).length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...cartDataResponse,
      }));
      form.setFieldsValue(cartDataResponse);
    }
  }, [cartDataResponse, userData, cartData]);

  const handleSelectChange = (value: string | number, name: string) => {
    if (name === 'country') {
      setFormData({
        ...formData,
        [name as string]: value,
        state: '',
        city: '',
      });
    } else if (name === 'state') {
      setFormData({ ...formData, [name as string]: value, city: '' });
    } else {
      setFormData({ ...formData, [name as string]: value });
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleSelectChangeSelect = (selected: Address | null) => {
    setState((prevState) => ({ ...prevState, selectedAddress: selected }));
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string | number },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleUpdateCartAddress = async (id: number, setData?: boolean) => {
    try {
      const resp = await updateCartAddress(id as number, Number(cartId));
      if (setData) {
        const getLabel = (
          data: { label: string; value: string | number }[] | undefined,
          targetValue: string | number,
        ) => {
          const selectedLabel = data?.find(
            (item) => item.value === targetValue,
          )?.label;

          return selectedLabel || '';
        };

        const selectedState = getLabel(states, resp?.data?.state_id);
        const selectedCity = getLabel(cities, resp?.data?.city_id);
        const selectedCountry = getLabel(countries, resp?.data?.country_id);

        const formatData = {
          id: resp?.data?.id,
          fullname: resp?.data?.name,
          email: userData?.email,
          postalcode: resp?.data?.postal_code,
          country: selectedCountry,
          set_default: resp?.data?.set_default,
          phone: resp?.data?.phone,
          city: selectedCity,
          state: selectedState,
          address: resp?.data?.address,
          makeAsDefault: false,
          edit: { state: false, type: '' },
        };

        await setDataAndUpdate(formatData as Address);
      }
    } catch (error: any) {
      const message = formateErrorObject(error);
      form.setFieldsValue(formData as Address);
      notify(message, 'error');
      throw error;
    }
  };

  const setDataAndUpdate = async (data: Address) => {
    handleSelectChangeSelect(data);
    form.setFieldsValue(data);
  };

  const handleCartCount = async () => {
    try {
      const data = await getCartCount({ cartId: Number(cartId) });
      setLocalCartCount(data.count);
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isProcessingPayment) {
        event.preventDefault();
        event.returnValue =
          'You have a pending payment process. Please refrain from refreshing the page while your payment process is underway.';
      }
    };

    if (isProcessingPayment) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isProcessingPayment]);

  const handlePlaceOrder = async ({
    paymentOption,
    name,
    email,
    cartId,
  }: {
    paymentOption: string;
    name?: string;
    email?: string;
    cartId?: number;
  }) => {
    try {
      setIsProcessingPayment(true);
      const resp = await placeOrder(paymentOption, cartId);
      updateAddressOnBlur({
        cart_id: cartData[0]?.cartId as number,
        fieldName: 'order_id',
        fieldValue: resp.data.id,
        zl_uid: userData?.zl_uid,
      });
      setOrderDetail({
        orderId: resp.data.id,
        transactionId: resp?.data?.payment_details?.rzp_order_id,
      });

      const productIds = (resp?.data?.order_details || [])
        .map((detail: any) => detail?.product?.id)
        .join(',');

      await handleCartCount();
      if (resp?.data?.payment_status === 'paid') {
        router.push({
          pathname: '/order-successful',
          query: {
            orderId: resp.data.id,
            product_ids: productIds,
            callPurchased: 'purchased',
          },
        });
        return;
      } else if (paymentOption === 'cash_on_delivery') {
        router.push({
          pathname: '/order-successful',
          query: {
            orderId: resp.data.id,
            product_ids: productIds,
            callPurchased: 'purchased',
          },
        });
        return;
      } else if (paymentOption === 'wallet') {
        router.push({
          pathname: '/order-successful',
          query: {
            orderId: resp.data.id,
            product_ids: productIds,
            callPurchased: 'purchased',
          },
        });
        return;
      } else {
        await initializeRazorpay({
          amount: amount,
          productIds: productIds,
          totalAmount: amount.total,
          razorpayOrderId: resp?.data?.payment_details?.rzp_order_id || '',
          orderId: resp.data.id || '',
          userData: {
            ...userData,
            name: name ?? userData?.name ?? '',
            email: email ?? userData?.email ?? '',
          } as User,
          setVisible: (value) => {
            setVisible(value);
            if (typeof window !== 'undefined') {
              localStorage.setItem('visible', value.toString());
            }
          },
          paymentType: state?.paymentOption as string,
        });
        setOrderData({
          amount: amount,
          orderId: resp.data.id,
          razorPayId: resp?.data?.payment_details?.rzp_order_id,
        });
        const params = new URLSearchParams();

        Object.entries(router.query).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((val) => params.append(key, val));
          } else if (value !== undefined) {
            params.set(key, value);
          }
        });

        params.set('od', resp.data.id);

        router.push(
          { query: Object.fromEntries(params.entries()) },
          undefined,
          { shallow: true },
        );
      }
      setCartData([]);
    } catch (error: any) {
      setOrderLoading(false);
      const message = formateErrorObject(error);
      notify(message, 'error');
      throw error;
    } finally {
      setIsProcessingPayment(false);
    }
  };

  useEffect(() => {
    if (placeOrderTrigger) {
      if (token && address?.length && isChanged) {
        onFinish('updateAddress');
      } else if (token && address?.length && !isChanged) {
        onFinish('assignAddressAndPlaceOrder');
      } else if (token && !address?.length) {
        onFinish('createAddressAndPlaceOrder');
      } else {
        onFinish('signUpAndCreateAddressAndPlaceOrder');
      }
      setPlaceOrderTrigger(false);
    }
  }, [placeOrderTrigger]);

  const isDisabled =
    !form.getFieldsError().some((field) => field.errors.length > 0) &&
    !!paymentOption;

  const handleValuesChange = (changedValues: any) => {
    setDisabled(isDisabled);
    setFormData((prevData) => ({
      ...prevData,
      ...changedValues,
    }));
  };

  const scrollToErrorField = (errorFields: { name: string[] }[]) => {
    if (errorFields?.length > 0) {
      const firstErrorName = errorFields[0]?.name;
      if (Array.isArray(firstErrorName)) {
        const firstError = document.querySelector(
          `#${firstErrorName.join('-')}`,
        );
        if (firstError) {
          firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  };

  useEffect(() => {
    setDisabled(isDisabled);
  }, [paymentOption, formData, form, isDisabled]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const savedVisible = localStorage.getItem('visible');
      if (savedVisible === 'true') {
        setVisible(true);
      }
    }

    const handleRouteChange = () => {
      setVisible(false);
      if (typeof window !== 'undefined') {
        localStorage.removeItem('visible');
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);
  // const fetchCurrentLocation = async () => {
  //   if (!navigator.geolocation) {
  //     notify('Geolocation is not supported by your browser.', 'error');
  //     return;
  //   }

  //   try {
  //     setCurrentLocationLoading(true);

  //     const getPosition = () =>
  //       new Promise<GeolocationPosition>((resolve, reject) => {
  //         navigator.geolocation.getCurrentPosition(resolve, reject);
  //       });

  //     const position = await getPosition();
  //     const { latitude, longitude } = position.coords;

  //     const response = await fetch(
  //       `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`,
  //       {
  //         headers: {
  //           'User-Agent': 'YourAppName/1.0 (rishabh.pandey@zebralearn.com)',
  //         },
  //       },
  //     );
  //     const data = await response.json();

  //     if (!data.address) {
  //       message.warning('Unable to retrieve location details.');
  //       return;
  //     }

  //     const fetchedLocation = {
  //       city: (data.address.state_district || '').toLowerCase(),
  //       country: (data.address.country || '').toLowerCase(),
  //       postalCode: data.address.postcode || '',
  //       state: (data.address.state || '').toLowerCase(),
  //     };

  //     setFormData((prevData) => ({
  //       ...prevData,
  //       postalcode: fetchedLocation.postalCode,
  //     }));
  //     form.setFieldValue('postalcode', fetchedLocation.postalCode);
  //     handleApiCall('postal_code', fetchedLocation.postalCode);

  //     const allCountries = await getAllCountries();
  //     const countryMatch = allCountries?.find(
  //       (c: { value: string; label: string }) =>
  //         c.label.toLowerCase() === fetchedLocation.country,
  //     );

  //     if (!countryMatch) {
  //       return;
  //     }
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       country: countryMatch.value,
  //     }));
  //     form.setFieldValue('country', countryMatch.value);

  //     handleApiCall('country_id', countryMatch.value);

  //     const allStates = await getStates(countryMatch.value.toString());
  //     const stateMatch = allStates?.find(
  //       (s: { value: string; label: string }) =>
  //         s.label.toLowerCase() === fetchedLocation.state,
  //     );

  //     if (!stateMatch) {
  //       return;
  //     }
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       state: stateMatch.value,
  //     }));
  //     form.setFieldValue('state', stateMatch.value);

  //     handleApiCall('state_id', stateMatch.value);

  //     const allCities = await getCities(stateMatch.value);
  //     const cityMatch = allCities?.find(
  //       (city: { value: string; label: string }) =>
  //         city.label.toLowerCase() === fetchedLocation.city,
  //     );

  //     if (!cityMatch) {
  //       return;
  //     }
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       city: cityMatch.value,
  //     }));
  //     form.setFieldValue('city', cityMatch.value);
  //     setCurrentLocationLoading(false);
  //     handleApiCall('city_id', cityMatch.value);
  //   } catch (error: any) {
  //     if (error instanceof GeolocationPositionError) {
  //       switch (error.code) {
  //         case GeolocationPositionError.PERMISSION_DENIED:
  //           notify('Location access denied. Enable it in settings.', 'error');
  //           break;
  //         case GeolocationPositionError.POSITION_UNAVAILABLE:
  //           notify('Location unavailable. Try again later.', 'error');
  //           break;
  //         case GeolocationPositionError.TIMEOUT:
  //           notify('Location request timed out. Try again.', 'error');
  //           break;
  //         default:
  //           notify('Unable to fetch location. Unknown error.', 'error');
  //           break;
  //       }
  //     } else {
  //       notify('Unexpected error. Please try later.', 'error');
  //     }
  //   } finally {
  //     setCurrentLocationLoading(false);
  //   }
  // };

  // const fetchStateAndCityByPostalCode = async ({
  //   postalCode,
  //   country = 'India',
  // }: {
  //   postalCode: string;
  //   country: string;
  // }) => {
  //   try {
  //     const response = await fetch(
  //       `https://nominatim.openstreetmap.org/search?postalcode=${postalCode}&country=${country}&format=json&addressdetails=1`,
  //       {
  //         headers: {
  //           'User-Agent': 'ZebraLearnApp/1.0 (rishabh.pandey@zebralearn.com)',
  //         },
  //       },
  //     );

  //     const data = await response.json();

  //     if (data.length > 0 && data[0].address) {
  //       const state = data[0].address.state || '';
  //       const city =
  //         data[0].address.city ||
  //         data[0].address.town ||
  //         data[0].address.state_district ||
  //         '';

  //       return {
  //         state,
  //         city,
  //       };
  //     } else {
  //       return { state: '', city: '' };
  //     }
  //   } catch (error) {
  //     return { state: '', city: '' };
  //   }
  // };

  const isZeroStockItemExists = useMemo(() => {
    return cartData.some(
      (data) =>
        data.current_stock === 0 &&
        data?.itemType?.toUpperCase() === 'BOOK' &&
        data?.type === 'hardcover',
    );
  }, [cartData]);

  return (
    <>
      <div
        className={clsx(
          !isCheckoutPage && token ? 'pt-5 ' : '',
          'flex flex-col gap-y-4   lg:pb-10',
        )}
      >
        <Form
          form={form}
          onFinish={onFinish}
          disabled={orderLoading}
          layout='vertical'
          initialValues={formData}
          onValuesChange={handleValuesChange}
        >
          <div className='flex md:px-8 flex-col gap-y-5 text-fadedWhite'>
            {/* Your Info Section */}
            <div className='bg-cartModalFormBg pt-3 md:pt-6 pb-5  md:py-6 px-4 md:px-5 rounded-lg'>
              <span className='flex gap-x-5 items-center pb-4 md:pb-5'>
                <p className='text-base font-semibold no-select'>Your Info</p>

                <ShouldRender check={loading}>
                  <span>
                    <Spin size={screens.md ? 'default' : 'small'} />
                  </span>
                </ShouldRender>
              </span>
              <ShouldRender check={!token}>
                <Form.Item
                  name='email'
                  validateStatus={
                    state.checkEmailError || form.getFieldError('email')?.length
                      ? 'error'
                      : ''
                  }
                  help={
                    state.checkEmailError ? (
                      <span className='text-xs md:text-sm inline-flex flex-col md:flex-row gap-y-2 pb-1 items-center gap-x-1 pt-1 text-yellow-500'>
                        <span className='flex gap-x-1 items-center'>
                          <ExclamationCircleIcon className='w-4 h-4' />
                          {state.checkEmailError}.{' '}
                        </span>
                        <p
                          className='underline hover:text-primary cursor-pointer'
                          onClick={() => {
                            if (isCheckoutPage) {
                              setLoginModalCheckout(true);
                            } else {
                              if (typeof window !== 'undefined') {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                setLoginCollapseAtom(true);
                              }
                            }
                          }}
                        >
                          Please click here for login
                        </p>
                      </span>
                    ) : form.getFieldError('email')?.length ? (
                      <span className='text-red-500'>
                        {form.getFieldError('email')[0]}
                      </span>
                    ) : null
                  }
                >
                  <Input
                    size='large'
                    type='email'
                    name='email'
                    autoComplete='current-email'
                    placeholder='Email address'
                    style={{ borderRadius: '2px' }}
                    value={formData.email}
                    className='border text-base z-20 focus-within:bg-bgColor placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray text-white bg-bgColor customInputBlack makeBgBlack'
                    onChange={handleChange}
                    onBlur={handleCheckEmailExist}
                  />
                </Form.Item>
              </ShouldRender>
              <Form.Item
                name='fullname'
                valuePropName='fullname'
                rules={[
                  {
                    validator: (_: RuleObject, value: any) => {
                      const fullNameValue =
                        value && typeof value === 'object'
                          ? value.target.value
                          : value;

                      if (fullNameValue && fullNameValue.trim() !== '') {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error('Please enter your full name!'),
                        );
                      }
                    },
                  },
                ]}
              >
                <Input
                  size='large'
                  placeholder='Full Name'
                  name='fullname'
                  value={formData.fullname}
                  className='border focus-within:bg-bgColor z-20 placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray text-base  text-white bg-bgColor customInputBlack makeBgBlack'
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => handleApiCall('name', e.target.value)}
                />
              </Form.Item>

              <Form.Item
                name='phone'
                valuePropName='phone'
                rules={[
                  {
                    validator: (_: RuleObject, value: any) => {
                      const phoneNumber =
                        value && typeof value === 'object'
                          ? value.target.value
                          : value;

                      const cleanedPhoneNumber = phoneNumber.replace(/\s/g, '');

                      const isValidPhoneNumber = /^\d{10}$/.test(
                        cleanedPhoneNumber,
                      );

                      const countryIsIndia =
                        formData.country === 'India' ||
                        Number(formData.country) === 101;

                      if (countryIsIndia && isValidPhoneNumber) {
                        return Promise.resolve();
                      } else if (
                        !countryIsIndia &&
                        cleanedPhoneNumber &&
                        cleanedPhoneNumber.trim() !== ''
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error('Please enter a valid phone number!'),
                        );
                      }
                    },
                  },
                ]}
              >
                <Input
                  size='large'
                  name='phone'
                  placeholder='Phone Number'
                  inputMode='numeric'
                  type='tel'
                  autoComplete='tel'
                  value={formData.phone}
                  className='border text-base focus-within:bg-bgColor z-20 placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray   text-white bg-bgColor customInputBlack makeBgBlack'
                  onChange={handleInput('phone', true)}
                  onBlur={(e) => handleApiCall('phone', e.target.value)}
                />
              </Form.Item>
              <ShouldRender check={!token}>
                <div
                  className={clsx(
                    formData.password ? 'pb-10 md:pb-6' : 'pb-0 ',
                    'relative transition-all duration-300 ease-in-out ',
                  )}
                >
                  <Form.Item
                    name='password'
                    initialValue={formData.password}
                    valuePropName='password'
                    style={{
                      paddingTop: '0px',
                    }}
                    rules={[
                      {
                        validator: (_: RuleObject, value: any) => {
                          const passwordValue =
                            value && typeof value === 'object'
                              ? value.target.value
                              : value;

                          if (!passwordValue) {
                            return Promise.reject(
                              new Error('Please enter your password!'),
                            );
                          } else if (passwordValue.length <= 8) {
                            return Promise.reject(
                              new Error(
                                'Password must be more than 8 characters!',
                              ),
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <Input.Password
                      name='password'
                      type='password'
                      autoComplete='current-password'
                      placeholder='Create account password'
                      autoFocus={false}
                      onChange={handleChange}
                      value={formData?.password}
                      size='large'
                      style={{
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                      className='border text-base pl-[7px] z-20 focus-within:bg-bgColor customPassword placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray py-0.5 text-white bg-bgColor customInputBlack makeBgBlack'
                    />
                  </Form.Item>
                  <ShouldRender check={formData.password}>
                    {passwordStrength !== null && (
                      <div
                        className={clsx(
                          form.getFieldError('password')?.length
                            ? 'top-16'
                            : 'top-14',
                          'absolute w-full transition-all duration-300 ease-in-out',
                        )}
                      >
                        <span className='relative'>
                          <Progress
                            className='customProcessBarHeight'
                            percent={(passwordStrength / 5) * 100}
                            showInfo={false}
                            status={
                              passwordStrength >= 3 ? 'success' : 'exception'
                            }
                          />
                          <p
                            className={clsx(
                              getStrengthMessage().length > 50
                                ? ' -bottom-8'
                                : '-bottom-5',
                              `mt-2 text-${
                                passwordStrength >= 3 ? 'green' : 'red'
                              }-500 absolute  text-xs  sm:-bottom-5`,
                            )}
                          >
                            {getStrengthMessage()}
                          </p>
                        </span>
                      </div>
                    )}
                  </ShouldRender>
                </div>
              </ShouldRender>
            </div>

            {/* Parent Info Section */}
            <ShouldRender check={hasMathsProduct}>
              <div className='bg-cartModalFormBg pt-3 md:pt-6 pb-3 md:py-6 px-4 md:px-5 rounded-lg'>
                <span className='flex gap-x-5 items-center pb-4 md:pb-5'>
                  <p className='text-base font-semibold no-select'>
                    Parent Info
                  </p>

                  <ShouldRender check={loading}>
                    <span>
                      <Spin size={screens.md ? 'default' : 'small'} />
                    </span>
                  </ShouldRender>
                </span>

                <Form.Item
                  name='parentEmail'
                  validateStatus={
                    form.getFieldError('parentEmail')?.length ? 'error' : ''
                  }
                  help={
                    form.getFieldError('parentEmail')?.length ? (
                      <span className='text-red-500'>
                        {form.getFieldError('parentEmail')[0]}
                      </span>
                    ) : null
                  }
                >
                  <Input
                    size='large'
                    type='email'
                    name='parentEmail'
                    autoComplete='current-email'
                    placeholder='Parent Email address'
                    style={{ borderRadius: '2px' }}
                    value={formData.parentEmail}
                    className='border text-base focus-within:bg-bgColor z-20 placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray text-white bg-bgColor customInputBlack makeBgBlack'
                    onChange={handleChange}
                    onBlur={(e) =>
                      handleParentApiCall('parentEmail', e.target.value)
                    }
                  />
                </Form.Item>

                <Form.Item
                  name='parentName'
                  valuePropName='parentName'
                  rules={[
                    {
                      validator: (_: RuleObject, value: any) => {
                        const parentNameValue =
                          value && typeof value === 'object'
                            ? value.target.value
                            : value;

                        if (parentNameValue && parentNameValue.trim() !== '') {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error('Please enter parent full name!'),
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    size='large'
                    placeholder='Parent Full Name'
                    name='parentName'
                    value={formData.parentName}
                    className='border focus-within:bg-bgColor z-20 placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray text-base text-white bg-bgColor customInputBlack makeBgBlack'
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) =>
                      handleParentApiCall('parentName', e.target.value)
                    }
                  />
                </Form.Item>

                <Form.Item
                  name='parentPhone'
                  valuePropName='parentPhone'
                  rules={[
                    {
                      validator: (_: RuleObject, value: any) => {
                        const phoneNumber =
                          value && typeof value === 'object'
                            ? value.target.value
                            : value;
                        const cleanedPhoneNumber = phoneNumber.replace(
                          /\s/g,
                          '',
                        );
                        const isValidPhoneNumber = /^\d{10}$/.test(
                          cleanedPhoneNumber,
                        );

                        if (isValidPhoneNumber) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error('Please enter a valid phone number!'),
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    size='large'
                    name='parentPhone'
                    placeholder='Parent Phone Number'
                    inputMode='numeric'
                    type='tel'
                    autoComplete='tel'
                    value={formData.parentPhone}
                    className='border text-base focus-within:bg-bgColor z-20 placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray text-white bg-bgColor customInputBlack makeBgBlack'
                    onChange={handleInput('parentPhone', true)}
                    onBlur={(e) =>
                      handleParentApiCall('parentPhone', e.target.value)
                    }
                  />
                </Form.Item>
              </div>
            </ShouldRender>

            {/* Billing Address Section */}
            <div className='bg-cartModalFormBg py-4 px-4 md:px-5  rounded-lg'>
              <span className='flex justify-between gap-x-2 md:gap-x-5 items-center pb-4 md:pb-5'>
                <div className='flex gap-x-4'>
                  <p className='text-base font-semibold whitespace-nowrap'>
                    Billing Address
                  </p>
                  <ShouldRender check={loading}>
                    <span>
                      <Spin size={screens.md ? 'default' : 'small'} />
                    </span>
                  </ShouldRender>
                </div>

                {/* <ShouldRender check={!loading}>
                  <Button
                    size={screens.md ? 'middle' : 'small'}
                    icon={<MapIcon className='h-5 md:w-5 w-4 ' />}
                    loading={currentLocationLoading}
                    onClick={fetchCurrentLocation}
                    className='text-xs md:text-sm'
                  >
                    Locate Me
                  </Button>
                </ShouldRender> */}
              </span>
              <div className=''>
                <Form.Item
                  name='address'
                  valuePropName='address'
                  rules={[
                    {
                      validator: (_: RuleObject, value: any) => {
                        const addressValue =
                          value && typeof value === 'object'
                            ? value.target.value
                            : value;

                        if (addressValue) {
                          if (addressValue.length <= 190) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error(
                                'Address exceeds maximum length of 190 characters!',
                              ),
                            );
                          }
                        } else {
                          return Promise.reject(
                            new Error('Please enter your address!'),
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input.TextArea
                    size='large'
                    name='address'
                    value={formData.address}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => handleApiCall('address', e.target.value)}
                    minLength={6}
                    placeholder='Type your complete Address'
                    className='border text-base focus-within:bg-bgColor z-20  placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray    text-white bg-bgColor customInputBlack makeBgBlack'
                  />
                </Form.Item>
              </div>
              <Form.Item
                name='country'
                valuePropName='country'
                rules={[
                  {
                    validator: (_: RuleObject, value: any) => {
                      const countryValue =
                        value && typeof value === 'object'
                          ? value.target.value
                          : value;
                      if (countryValue) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error('Please select your country!'),
                        );
                      }
                    },
                  },
                ]}
              >
                <CustomSelect
                  value={formData.country}
                  onChange={(value) => {
                    handleSelectChange(value, 'country');
                    handleApiCall('country_id', value);
                  }}
                  options={countries}
                  placeholder='Select Country'
                  loading={fieldLoading.country}
                  filterOption={filterOption}
                />
              </Form.Item>
              <Form.Item
                name='postalcode'
                valuePropName='postalcode'
                rules={[
                  {
                    validator: (_, value) => {
                      const enteredPostalCode =
                        value && typeof value === 'object'
                          ? value.target.value
                          : value;

                      const cleanedPostalCode = enteredPostalCode.replace(
                        /\s/g,
                        '',
                      );

                      const countryIsIndia =
                        formData.country === 'India' ||
                        Number(formData.country) === 101;
                      const isValidPostalCodeIndia = /^[0-9]{6}$/.test(
                        cleanedPostalCode,
                      );

                      if (countryIsIndia && isValidPostalCodeIndia) {
                        return Promise.resolve();
                      } else if (
                        !countryIsIndia &&
                        cleanedPostalCode &&
                        cleanedPostalCode.trim() !== ''
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error('Please enter a valid postal code!'),
                        );
                      }
                    },
                  },
                ]}
              >
                <Input
                  size='large'
                  name='postalcode'
                  inputMode='numeric'
                  placeholder='Postal code'
                  value={formData.postalcode}
                  onBlur={(e) => handleApiCall('postal_code', e.target.value)}
                  className='border text-base focus-within:bg-bgColor z-20 placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray   text-white bg-bgColor customInputBlack makeBgBlack'
                  onChange={handleInput(
                    'postalcode',
                    formData.country === 'India' ||
                      Number(formData.country) === 101,
                  )}
                />
              </Form.Item>
              <div className='grid sm:grid-cols-2 sm:gap-4'>
                <Form.Item
                  name='state'
                  valuePropName='state'
                  rules={[
                    {
                      validator: (_: RuleObject, value: any) => {
                        const stateValue =
                          value && typeof value === 'object'
                            ? value.target.value
                            : value;

                        if (stateValue) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error('Please select your state!'),
                          );
                        }
                      },
                    },
                  ]}
                >
                  <CustomSelect
                    value={formData.state === '' ? undefined : formData.state}
                    onChange={(value) => {
                      handleSelectChange(value, 'state');
                      handleApiCall('state_id', value);
                    }}
                    options={states}
                    placeholder='Select State'
                    disabled={!formData?.country}
                    loading={fieldLoading.state}
                    filterOption={filterOption}
                  />
                </Form.Item>

                <Form.Item
                  name='city'
                  valuePropName='city'
                  rules={[
                    {
                      validator: (_: RuleObject, value: any) => {
                        const cityValue =
                          value && typeof value === 'object'
                            ? value.target.value
                            : value;

                        if (cityValue) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error('Please select your city!'),
                          );
                        }
                      },
                    },
                  ]}
                >
                  <CustomSelect
                    value={formData.city === '' ? undefined : formData.city}
                    onChange={(value) => {
                      handleSelectChange(value, 'city');
                      handleApiCall('city_id', value);
                    }}
                    options={cities}
                    placeholder='Select City'
                    loading={fieldLoading.city}
                    disabled={!formData?.state}
                    filterOption={filterOption}
                  />
                </Form.Item>
              </div>
            </div>
            <ShouldRender check={isZeroStockItemExists}>
              <div className='flex gap-x-2 md:items-center  px-3 md:px-5 py-2.5 rounded-lg bg-[#2C2302] items-start'>
                <ExclamationCircleOutlined className='text-sm text-[#EAB308] translate-y-0.5 md:translate-y-0 ' />
                <div className='text-[#EAB308] text-xs font-semibold  '>
                  Due to high demand, delivery may take little longer than
                  expected.
                </div>
              </div>
            </ShouldRender>
          </div>
        </Form>

        {/* <ShouldRender check={false}> */}

        {/* Payment Section */}
        <span className='pt-4 flex flex-col py-5 md:pb-0 md:px-8 rounded-lg'>
          <span className='text-base font-semibold text-fadedWhite'>
            Payment information
          </span>

          <span className='pt-4 md:py-4 relative    flex flex-col gap-y-2  rounded-lg cardShadow '>
            <PaymentInfo
              selectedOption={paymentOption as string}
              isCOD_Disabled={cartData.some((item) => item.type === 'digital')}
              onSelectOption={handlePaymentOptionChange}
              handlePaymentOptionClick={handlePaymentOptionClick}
              totalAmount={amount.total}
              notRenderWallet={true}
              orderLoading={orderLoading}
              walletBalance={userData?.balance}
              walletDisable={!cartData.every((item) => item.type === 'digital')}
              isIndia={
                formData?.country !== 'India' &&
                Number(formData?.country) !== 101
              }
            />
          </span>
        </span>
        {/* <div className='mt-4 flex  w-full'>
          <ButtonDefault
            size={4}
            variant={ButtonVariants.PRIMARY}
            className='w-full'
            loading={orderLoading}
            disabled={
              form.getFieldsError().some((field) => field.errors.length > 0) ||
              !paymentOption ||
              orderLoading ||
              !!(
                emailExistAndPassword?.emailExist &&
                token &&
                address?.length
              ) ||
              !!(token && address?.length && isChanged) ||
              !!emailExistAndPassword?.emailExist
            }
            onClick={() => {
              if (token && !selectedAddress) {
                onFinish('createAddressAndPlaceOrder');
              } else if (token && selectedAddress) {
                onFinish('assignAddressAndPlaceOrder');
              } else {
                onFinish('signUpAndCreateAddressAndPlaceOrder');
              }
            }}
          >
            <span className='flex text-xs gap-x-2 px-4 py-1 text-center justify-center whitespace-nowrap'>
              ORDER NOW
            </span>
          </ButtonDefault>
        </div> */}
      </div>

      <LoginModal onCancel={onCancelLogin} visible={visibleLogin} />
      <ForgotPasswordModal
        onCancel={onCancelForgotPassword}
        visible={visibleForgotPassword}
      />

      <CloseModal
        data={{
          amount: order?.amount as IAmount,
          razorpayOrderId: order?.razorPayId as string,
          orderId: order?.orderId as string,
          userData: userData as User,
          setVisible: (value) => {
            setVisible(value);
            if (typeof window !== 'undefined') {
              localStorage.setItem('visible', value.toString());
            }
          },
          paymentType: state?.paymentOption as string,
        }}
        closeModal={onCancelCloseModal}
        visible={visible}
      />

      <LoginModalCheckout
        visible={openLoginModalCheckout}
        onCancel={onCancelLoginCheckout}
        email={formData?.email}
        isCheckoutFlow={isCheckoutPage}
      />
    </>
  );
};

export default CartContactForm;
